import React from "react";
import { useTheme } from "@mui/material/styles";
import TitlebarButton from "./TitlebarButton";
import { Box } from "@mui/material";
// import { getPlatform } from "../../electron/electronUtils";
import { ReactComponent as MacMinimizeIcon } from "../../res/icons/mac-minimize.svg";
import { ReactComponent as MacMaximizeIcon } from "../../res/icons/mac-maximize.svg";
import { ReactComponent as MacCloseIcon } from "../../res/icons/mac-close.svg";

import { ReactComponent as WindowsMinimizeIcon } from "../../res/icons/windows-minimize.svg";
import { ReactComponent as WindowsMaximizeIcon } from "../../res/icons/windows-maximize.svg";
import { ReactComponent as WindowsCloseIcon } from "../../res/icons/windows-close.svg";
const { getPlatform } = require("../../electron/electronUtils");
// const isMacOS = window.electron.os.platform() === "darwin";
const isMacOS = getPlatform() === "darwin";

const Titlebar = () => {
  const theme = useTheme();
  const iconStyle = {
    marginRight: isMacOS ? "auto" : "0",
    marginLeft: isMacOS ? "0" : "auto",
    WebkitAppRegion: "no-drag",
  };
  const windowsIconStyle =
    theme.palette.mode === "light" ? { fill: "black" } : { fill: "white" };

  return (
    <Box sx={{ display: "flex", ...iconStyle }}>
      <div className="titlebar">
        {isMacOS ? (
          <>
            <TitlebarButton message="closeApp">
              {isMacOS ? (
                <MacCloseIcon />
              ) : (
                <WindowsCloseIcon style={windowsIconStyle} />
              )}
            </TitlebarButton>
            <TitlebarButton message="minimizeApp">
              {isMacOS ? (
                <MacMinimizeIcon />
              ) : (
                <WindowsMinimizeIcon style={windowsIconStyle} />
              )}
            </TitlebarButton>
            <TitlebarButton message="maximizeApp">
              {isMacOS ? (
                <MacMaximizeIcon />
              ) : (
                <WindowsMaximizeIcon style={windowsIconStyle} />
              )}
            </TitlebarButton>
          </>
        ) : (
          <>
            <TitlebarButton message="minimizeApp">
              {isMacOS ? (
                <MacMinimizeIcon />
              ) : (
                <WindowsMinimizeIcon style={windowsIconStyle} />
              )}
            </TitlebarButton>
            <TitlebarButton message="maximizeApp">
              {isMacOS ? (
                <MacMaximizeIcon />
              ) : (
                <WindowsMaximizeIcon style={windowsIconStyle} />
              )}
            </TitlebarButton>
            <TitlebarButton message="closeApp">
              {isMacOS ? (
                <MacCloseIcon />
              ) : (
                <WindowsCloseIcon style={windowsIconStyle} />
              )}
            </TitlebarButton>
          </>
        )}
      </div>
    </Box>
  );
};

export default Titlebar;
