import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
// import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import getInitials from "../../utils/getInitials";
import { MoreVert, Message, Visibility, Healing } from "@mui/icons-material";
import ViewProfileWidget from "../utility/ViewProfileWidget";
import CustomModal from "../modal";

const PatientList = ({ patients, ...rest }, props) => {
  const [SelectedPatientIds, setSelectedPatientIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const actionMenuOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);

  const handleSelectAll = (event) => {
    let newSelectedPatientIds;

    if (event.target.checked) {
      newSelectedPatientIds = patients.map((patient) => patient.id);
    } else {
      newSelectedPatientIds = [];
    }

    setSelectedPatientIds(newSelectedPatientIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = SelectedPatientIds.indexOf(id);
    let newSelectedPatientIds = [];

    if (selectedIndex === -1) {
      newSelectedPatientIds = newSelectedPatientIds.concat(
        SelectedPatientIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedPatientIds = newSelectedPatientIds.concat(
        SelectedPatientIds.slice(1)
      );
    } else if (selectedIndex === SelectedPatientIds.length - 1) {
      newSelectedPatientIds = newSelectedPatientIds.concat(
        SelectedPatientIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedPatientIds = newSelectedPatientIds.concat(
        SelectedPatientIds.slice(0, selectedIndex),
        SelectedPatientIds.slice(selectedIndex + 1)
      );
    }

    setSelectedPatientIds(newSelectedPatientIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const viewPatient = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box {...props}>
      <Card {...rest}>
        {/* <PerfectScrollbar> */}
        {/* <Box sx={{ minWidth: 1050 }}> */}
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={SelectedPatientIds.length === patients.length}
                    color="primary"
                    indeterminate={
                      SelectedPatientIds.length > 0 &&
                      SelectedPatientIds.length < patients.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Registration date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.slice(0, limit).map((patient) => (
                <TableRow
                  hover
                  key={patient.id}
                  selected={SelectedPatientIds.indexOf(patient.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={SelectedPatientIds.indexOf(patient.id) !== -1}
                      onChange={(event) => handleSelectOne(event, patient.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Avatar src={patient.avatarUrl} sx={{ mr: 2 }}>
                        {/* {getInitials(patient.name)} */}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {patient.name}
                        {/* {`${patient.firstName}, ${patient.lastName}`} */}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{patient.email}</TableCell>
                  <TableCell>{`${patient.address}`}</TableCell>
                  <TableCell>{patient.phone}</TableCell>
                  <TableCell>
                    {moment(patient.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={handleActionClick}
                      size="small"
                      aria-label="Actions"
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="action-menu-2"
                      open={actionMenuOpen}
                      onClose={handleActionClose}
                      PaperProps={{
                        elevation: 0,
                        style: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          //   boxShadow: "none",
                        },
                      }}
                    >
                      <MenuItem onClick={viewPatient}>
                        <IconButton
                          color="primary"
                          size="small"
                          style={{ marginRight: 8 }}
                        >
                          <Visibility fontSize="small" />
                        </IconButton>
                        View More
                      </MenuItem>
                      <MenuItem>
                        <IconButton
                          color="primary"
                          size="small"
                          style={{ marginRight: 8 }}
                        >
                          <Message fontSize="small" />
                        </IconButton>
                        Message
                      </MenuItem>
                      <MenuItem>
                        <IconButton
                          color="warning"
                          size="small"
                          style={{ marginRight: 8 }}
                        >
                          <Healing fontSize="small" />
                        </IconButton>
                        Admit Patient
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {/* </PerfectScrollbar> */}
        <TablePagination
          component="div"
          count={patients.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <CustomModal open={open} handleClose={handleClose}>
        <ViewProfileWidget />
      </CustomModal>
    </Box>
  );
};

PatientList.propTypes = {
  patients: PropTypes.array.isRequired,
};

export default PatientList;
