// BillingComponent.tsx
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BusinessIcon from "@mui/icons-material/Business";
// import {
//   billingInteractions,
//   creditCardDetails,
//   addressBook,
// } from "./mockData";
// mockData.ts
export const billingInteractions = [
  {
    id: 1,
    description: "Invoice Payment",
    date: "2023-09-15",
  },
  {
    id: 2,
    description: "Purchase: Medical Supplies",
    date: "2023-09-10",
  },
  {
    id: 3,
    description: "Service Fee",
    date: "2023-09-05",
  },
];

export const creditCardDetails = {
  cardType: "Visa",
  last4Digits: "1234",
  expiryDate: "12/25",
  billingName: "John Doe",
  billingAddress: "123 Main St, Cityville",
  billingPhone: "+1 234-567-8901",
  paymentMethod: "Credit Card",
};

export const addressBook = [
  {
    id: 1,
    name: "City Bank",
    type: "Bank",
    address: "456 Bank St, Finance City",
  },
  {
    id: 2,
    name: "Health Supplies Inc.",
    type: "Office",
    address: "789 Pharma St, Medical Town",
  },
  {
    id: 3,
    name: "Global Pharma Solutions",
    type: "Office",
    address: "101 Med St, Health City",
  },
];

const BillingSettings = () => {
  return (
    <Grid container spacing={3}>
      {/* First Row */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Last Interactions</Typography>
            <List>
              {billingInteractions.map((interaction) => (
                <div key={interaction.id}>
                  <ListItem>
                    <ListItemText
                      primary={interaction.description}
                      secondary={interaction.date}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<ReceiptIcon />}
                      size="small"
                    >
                      View Receipt
                    </Button>
                    <IconButton>
                      <PrintIcon />
                    </IconButton>
                    <IconButton>
                      <ShareIcon />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>

      {/* Second Row */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Credit Card Details</Typography>
            <Box display="flex" alignItems="center" mt={2}>
              <CreditCardIcon fontSize="large" />
              <Box ml={2}>
                <Typography variant="subtitle1">
                  {creditCardDetails.cardType} ending in{" "}
                  {creditCardDetails.last4Digits}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Expires {creditCardDetails.expiryDate}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
            <Box>
              <Typography variant="subtitle1">Billing Information</Typography>
              <Typography variant="body2">
                Name: {creditCardDetails.billingName}
              </Typography>
              <Typography variant="body2">
                Address: {creditCardDetails.billingAddress}
              </Typography>
              <Typography variant="body2">
                Phone: {creditCardDetails.billingPhone}
              </Typography>
              <Typography variant="body2">
                Payment Method: {creditCardDetails.paymentMethod}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {/* Third Row */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Address Book</Typography>
            <List>
              {addressBook.map((address) => (
                <div key={address.id}>
                  <ListItem>
                    <ListItemText
                      primary={address.name}
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {address.type} - {address.address}
                        </Typography>
                      }
                    />
                    {address.type === "Bank" ? (
                      <IconButton>
                        <CreditCardIcon />
                      </IconButton>
                    ) : (
                      <IconButton>
                        <BusinessIcon />
                      </IconButton>
                    )}
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BillingSettings;
