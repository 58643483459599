import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Paper,
  Tab,
  Tabs,
  Box,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  ArrowForward,
  FilterList,
  MoreVert,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RevenueStats from "../stats/revenueStats";
import PatientStats from "../stats/patientStats";
import SocialStats from "../stats/socialStats";
import AgeDemographyChart from "../stats/charts/ageDemography";
import CustomStats from "../stats/charts/customStats";
import { chartOptions, chartSeries } from "../stats/charts/chartData";

// Sample data for invoices, staff, and suppliers
const invoices = [
  { id: "INV001", date: "2023-09-01", amount: 1200, status: "Paid" },
  { id: "INV002", date: "2023-09-05", amount: 800, status: "Pending" },
  { id: "INV003", date: "2023-09-10", amount: 1500, status: "Paid" },
  { id: "INV004", date: "2023-09-10", amount: 3500, status: "Unpaid" },
  { id: "INV005", date: "2023-09-10", amount: 2500, status: "Pending" },
  // Add more invoice entries as needed
]; // Sample data for invoices
const staff = [
  {
    id: "EMP001",
    name: "John Doe",
    salary: 5000,
    employmentType: "Full Time",
    lastPaymentDate: "2023-08-25",
    nextPaymentDate: "2023-09-25",
  },
  {
    id: "EMP002",
    name: "Jane Smith",
    salary: 4000,
    employmentType: "Part Time",
    lastPaymentDate: "2023-08-28",
    nextPaymentDate: "2023-09-28",
  },
  {
    id: "EMP003",
    name: "John Smith",
    salary: 6000,
    employmentType: "Part Time",
    lastPaymentDate: "2023-08-28",
    nextPaymentDate: "2023-09-28",
  },
  {
    id: "EMP004",
    name: "Jany Zint",
    salary: 8000,
    employmentType: "Part Time",
    lastPaymentDate: "2023-08-28",
    nextPaymentDate: "2023-09-28",
  },
  // Add more staff entries as needed
];
// Sample data for staff
const suppliers = [
  {
    id: "SUP001",
    name: "ABC Pharmaceuticals",
    contact: { name: "John", email: "john@example.com", phone: "123-456-7890" },
    lastInvoiceDate: "2023-08-20",
    lastPaymentDate: "2023-08-25",
    lastTransactionAmount: 2000,
  },
  {
    id: "SUP002",
    name: "XYZ Medical Supplies",
    contact: {
      name: "Alice",
      email: "alice@example.com",
      phone: "987-654-3210",
    },
    lastInvoiceDate: "2023-08-15",
    lastPaymentDate: "2023-08-20",
    lastTransactionAmount: 1500,
  },
  {
    id: "SUP003",
    name: "ZANY Medical Supplies",
    contact: {
      name: "Jank",
      email: "jank@example.com",
      phone: "987-654-3210",
    },
    lastInvoiceDate: "2023-09-15",
    lastPaymentDate: "2023-10-20",
    lastTransactionAmount: 3500,
  },
  {
    id: "SUP004",
    name: "PONY Medical Supplies",
    contact: {
      name: "Eve",
      email: "eve@example.com",
      phone: "987-654-3210",
    },
    lastInvoiceDate: "2023-03-15",
    lastPaymentDate: "2023-05-20",
    lastTransactionAmount: 8500,
  },
  // Add more supplier entries as needed
];
// Sample data for suppliers

// Styled scrollable container
const ScrollableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none", // For Internet Explorer and Edge
  "scrollbar-width": "none", // For Firefox
  // Additional styles here
  position: "relative", // Ensure this is relative to position children absolutely within
  marginLeft: "40px", // Adjust as necessary for the left arrow
  marginRight: "40px", // Adjust as necessary for the right arrow
  paddingTop: "15px",
  paddingBottom: "15px",
}));
// Styled Grid item for flex display
const StyledGridItem = styled(Grid)(({ theme }) => ({
  flex: "0 0 auto", // This ensures the item does not grow or shrink
  maxWidth: "278px",
  // maxHeight: "160px",
  // Additional styles here
}));

const AccountsPage: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const [scrolling, setScrolling] = useState(false);

  // Function to handle the scroll
  const scroll = (scrollOffset: number) => {
    if (gridRef.current && !scrolling) {
      setScrolling(true);
      gridRef.current.scrollBy({ left: scrollOffset, behavior: "smooth" });
      setTimeout(() => setScrolling(false), 400); // timeout should be longer than the scroll duration
    }
  };

  // Function to jump to the clone
  const handleScroll = () => {
    if (gridRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = gridRef.current;
      // Check if we've reached the end of the content
      if (scrollLeft <= 0) {
        gridRef.current.scrollLeft = scrollWidth / 3;
      } else if (scrollLeft >= scrollWidth - clientWidth) {
        gridRef.current.scrollLeft = scrollWidth / 3 - clientWidth;
      }
    }
  };

  // Set up the loop effect
  useEffect(() => {
    const element = gridRef.current;
    if (element) {
      const { scrollWidth, clientWidth } = element;

      element.scrollLeft = scrollWidth / 3; // Start in the middle block of components
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>{invoice.id}</TableCell>
                    <TableCell>{invoice.date}</TableCell>
                    <TableCell>{invoice.amount}</TableCell>
                    <TableCell>{invoice.status}</TableCell>
                    <TableCell>
                      <IconButton>
                        <ArrowForward />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      case 1:
        return (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Salary</TableCell>
                  <TableCell>Employment Type</TableCell>
                  <TableCell>Last Payment Date</TableCell>
                  <TableCell>Next Payment Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staff.map((employee) => (
                  <TableRow key={employee.id}>
                    {/* Render staff details */}
                    <TableCell>{employee.id}</TableCell>
                    <TableCell>{employee.name}</TableCell>
                    <TableCell>{employee.salary}</TableCell>
                    <TableCell>{employee.employmentType}</TableCell>
                    <TableCell>{employee.lastPaymentDate}</TableCell>
                    <TableCell>{employee.nextPaymentDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      case 2:
        return (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Last Invoice Date</TableCell>
                  <TableCell>Last Payment Date</TableCell>
                  <TableCell>Last Transaction Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers.map((supplier) => (
                  <TableRow key={supplier.id}>
                    {/* Render supplier details */}
                    <TableCell>{supplier.name}</TableCell>
                    <TableCell>{supplier.contact.email}</TableCell>
                    <TableCell>{supplier.contact.phone}</TableCell>
                    <TableCell>{supplier.lastInvoiceDate}</TableCell>
                    <TableCell>{supplier.lastPaymentDate}</TableCell>
                    <TableCell>{supplier.lastTransactionAmount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      default:
        return null;
    }
  };

  // Your array of components, plus cloned elements for the looping effect
  const components = [
    <RevenueStats key="clone1-start" />, // Clone of the first item
    <PatientStats key="1" />,
    // <Box key="2" sx={{ height: "100%" }}>
    //   <SocialStats sx={{ height: "192px" }} />
    // </Box>,
    <CustomStats
      key="3"
      title="YEARLY VISITS"
      improvement={12} // You can customize this value based on your requirement
      percentageIncrease={8.5} // You can customize this value based on your requirement
      currentFigure="35,177" // You can customize this value based on your requirement
      chartOptions={chartOptions}
      chartSeries={chartSeries}
    />,
    // <AgeDemographyChart key="4" />,
    <CustomStats
      key="4"
      title="MONTHLY VISITS"
      improvement={10} // You can customize this value based on your requirement
      percentageIncrease={10.5} // You can customize this value based on your requirement
      currentFigure="15,837" // You can customize this value based on your requirement
      chartOptions={chartOptions}
      chartSeries={chartSeries}
    />,
    <RevenueStats key="clone1-end" />, // Clone of the last item
  ];

  // FILTER FUNCTIONS
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleOpenAccordion = () => {
    setAccordionOpen(true);
  };

  const handleCloseAccordion = () => {
    setAccordionOpen(false);
  };

  const handleFilterSelection = (filter: string) => {
    setSelectedFilter(filter);
    handleCloseAccordion();
  };

  const filterOptions = ["Recent", "Asc", "Desc"];

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={3}
        style={{
          marginBottom: "20px",
          marginTop: "30px",
          position: "relative",
        }}
      >
        {/* Scroll Buttons */}
        <IconButton
          style={{
            position: "absolute",
            left: "4px", // Adjust as necessary for space from the left edge
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
          }}
          onClick={() => scroll(-300)} // Adjust scroll amount based on your component size
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton
          style={{
            position: "absolute",
            right: "4px", // Adjust as necessary for space from the right edge
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
          }}
          onClick={() => scroll(300)} // Adjust scroll amount based on your component size
        >
          <ArrowForwardIosIcon />
        </IconButton>

        <ScrollableContainer ref={gridRef}>
          <Grid container spacing={3} wrap="nowrap">
            {/* Clone the components for the looping effect */}
            {components
              .concat(components, components)
              .map((component, index) => (
                <StyledGridItem key={index} item>
                  {component}
                </StyledGridItem>
              ))}
          </Grid>
        </ScrollableContainer>
      </Paper>
      <Paper>
        {/* Second Row - Tabs for Invoices, Staff, and Suppliers */}
        <AppBar position="static" color="default" className="tabs">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Invoices" />
            <Tab label="Staff" />
            <Tab label="Suppliers" />
          </Tabs>
          <IconButton color="primary" onClick={toggleAccordion}>
            <FilterList />
          </IconButton>

          {accordionOpen && (
            <AccordionDetails
              style={{ display: "flex", justifyContent: "center" }}
            >
              {filterOptions.map((filterOption) => (
                <Chip
                  key={filterOption}
                  label={filterOption}
                  color={
                    selectedFilter === filterOption ? "primary" : "default"
                  }
                  onClick={() => handleFilterSelection(filterOption)}
                  style={{ margin: "4px" }}
                />
              ))}
            </AccordionDetails>
          )}
        </AppBar>

        {/* Third Row - Display Content Based on Selected Tab */}
        <Box p={1}>{renderContent()}</Box>
      </Paper>
    </Container>
  );
};

export default AccountsPage;
