// src/utils.js or src/helpers.js
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import Toast from "../components/utility/Toast";
// import { isElectron, openToastNotification } from "../electron/electronUtils";
const {
  isElectron,
  openToastNotification,
} = require("../electron/electronUtils");

export const showToast = (message, options, actionHandler) => {
  // let history = useHistory();
  if (isElectron()) {
    // const windowOptions = {
    //   width: 200,
    //   height: 300,
    //   webPreferences: {
    //     nodeIntegration: false,
    //     contextIsolation: true,
    //   },
    //   // other options you want to set
    // };

    // openNewWindow(windowOptions);
    // openToastNotification(message, (options = { windowOptions }));
    // openToastNotification(message, options=windowOptions );
    // openToastNotification("Do you want to logout?");
    openToastNotification("Do you want to logout?", {
      width: 290,
      height: 80,
      // maxwidth: 300,
      // maxheight: 50,
      // display: `flex`,
      // padding: 0,
      // margin: 0,
    });
  } else {
    const div = document.createElement("div");
    document.body.appendChild(div);

    const handleClose = () => {
      ReactDOM.unmountComponentAtNode(div);
      div.remove();
    };

    const handleAction = () => {
      actionHandler(); // This will execute the logout logic
      handleClose(); // This will close the Toast immediately after
    };

    ReactDOM.render(
      <Toast
        open={true}
        message={message}
        handleAction={handleAction}
        handleClose={handleClose}
      />,
      div
    );
  }
};
