// CardGalaxy.tsx
import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CSSObject } from "@mui/system";
import mockUpImage from "../../res/images/MockUp2.png";

interface InfoCardProps {
  height?: string | number;
  width?: string | number;
}

const useStyles = (): CSSObject => {
  return {
    eyebrow: {
      color: "rgba(255, 255, 255, 0.95)",
      fontFamily: '"Spartan", san-serif',
      lineHeight: 1.4,
      fontSize: "1.0625rem",
      fontWeight: "bold",
      letterSpacing: "1px",
      textTransform: "initial",
      marginBottom: 0,
      backgroundFilter: "blur(15px)",
    },
    title: {
      color: "#fff",
      fontSize: "1.25rem",
      fontWeight: "bold",
      lineHeight: 1.2,
    },
    subtitle: {
      color: "rgba(255, 255, 255, 0.72)",
      lineHeight: 1.5,
      marginTop: "1rem",
    },
  };
};

const StyledCard = styled(Card)({
  borderRadius: "1rem",
  boxShadow: "none",
  position: "relative",
  minWidth: 200,
  minHeight: 140,
  "&:after": {
    content: '""',
    display: "block",
    position: "absolute",
    width: "100%",
    height: "64%",
    bottom: 0,
    zIndex: 1,
    background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
  },
});

const StyledCardMedia = styled(CardMedia)({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 0,
  backgroundPosition: "top",
});

const Content = styled("div")(({ theme }) => ({
  padding: theme.spacing(3, 2),
  position: "absolute",
  zIndex: 2,
  bottom: 0,
  width: "100%",
}));

export const InfoCard: React.FC<InfoCardProps> = ({
  height = "360px",
  width = "200px",
}) => {
  const styles = useStyles();

  return (
    <StyledCard style={{ height, width }}>
      <StyledCardMedia image={mockUpImage} />
      <Content>
        <Typography style={styles.eyebrow}>MAC | PC | LINUX | WEB</Typography>
        <Typography style={styles.title}>Cross Platform Template</Typography>
        <Typography style={styles.subtitle}>
          Perfect for every Use Case
        </Typography>
      </Content>
    </StyledCard>
  );
};

export default InfoCard;
