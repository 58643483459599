import React, { useState, useEffect } from "react";
import { Tooltip, IconButton, Avatar, Typography } from "@mui/material";
import { styled, useTheme, Theme } from "@mui/material/styles";
import { css, keyframes } from "@mui/system";
// import {
//   isElectron,
//   getPlatform as getPlatformFromElectron,
//   getCpuUsage,
// } from "../../electron/electronUtils";
import AvatarDefault from "../../res/images/avatar_default.jpg";
const {
  isElectron,
  getPlatform: getPlatformFromElectron,
  getCpuUsage,
} = require("../../electron/electronUtils");

interface InfoAvatarProps {
  //   handleClick?: () => void;
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

interface StatusIndicatorProps {
  isConnectingToDatabase?: boolean;
  hasError?: boolean;
  isConnectedToDatabase?: boolean;
}

const statusIndicator = (
  {
    isConnectingToDatabase,
    hasError,
    isConnectedToDatabase,
  }: StatusIndicatorProps,
  theme: any
) => {
  if (isConnectingToDatabase) {
    return "yellow";
  } else if (hasError) {
    return "red";
  } else if (isConnectedToDatabase) {
    return "green";
  }
  // Default (theme palette primary color)
  return theme.palette.primary.main;
};

// const pulsate = css`
//   @keyframes pulsate {
//     0% {
//       transform: scale(1);
//     }
//     50% {
//       transform: scale(1.5);
//     }
//     100% {
//       transform: scale(1);
//     }
//   }
// `;
// const pulsate = keyframes`
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(1.1);
//   }
//   100% {
//     transform: scale(1);
//   }
// `;

const pulsate = css`
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid;
    border-color: inherit;
    border-radius: inherit;
    pointer-events: none;
    animation: pulsate 1.5s infinite;
  }
`;

const StyledAvatar = styled(Avatar)(
  {
    // Additional styling for the Avatar if needed
    position: "relative",
    "&::before": {
      // Styling for the border overlay
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: "2px solid transparent",
      borderRadius: "inherit",
      pointerEvents: "none",
    },
  },
  pulsate // Apply the pulsate keyframes
);

const InfoAvatar: React.FC<InfoAvatarProps> = ({ handleClick }) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const isRunningInElectron = isElectron();
  const platform = getPlatformFromElectron();

  const theme = useTheme();

  const getBorderColor = () => {
    return isHovered || isClicked ? statusIndicator({}, theme) : "transparent";
  };

  const handleHover = () => {
    setHovered(true);
    setShowBorder(true);
  };

  const handleUnhover = () => {
    setHovered(false);
    if (!isClicked) {
      setShowBorder(false);
    }
  };

  const handleAvatarClick = (e: React.MouseEvent<HTMLElement>) => {
    setClicked(true);

    // Reset the clicked state after 3 minutes
    setTimeout(() => {
      setClicked(false);
      setShowBorder(false);
    }, 30000);
    // }, 3 * 60 * 1000); // 3 minutes in milliseconds

    // Trigger the provided handleClick function, if available
    if (handleClick) {
      handleClick(e);
    }
  };

  const getUsername = () => `john`;
  const getCurrentDateTime = () => {
    const currentDate = new Date();

    // Get date components
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();

    // Get time components
    let hours = currentDate.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");

    // Combine date and time components
    const formattedDateTime = `${day}/${month}/${year} | ${hours}:${minutes}${ampm}`;

    return formattedDateTime;
  };
  //   const getCpu = await getCpuUsage();
  // const [cpuu, setCpuu] = useState("");
  // const getCpu = async () => {
  //   const cpuUsage = await getCpuUsage();
  //   setCpuu(cpuUsage);
  //   console.log("CPU Usage:", cpuUsage);
  //   console.log("CPUU:", cpuu);
  //   return cpuUsage;
  // };

  const getPlatform = () => {
    if (isRunningInElectron) {
      //   const platform = getPlatformFromElectron();
      console.log(platform);
      if (platform === "darwin") {
        return "mac";
      } else if (platform === "win32") {
        return "Windows";
      } else if (platform === "linux") {
        return "Linux";
      }
    }
    return "Web";
  };

  const [tooltipContent, setTooltipContent] = useState<React.ReactNode | null>(
    null
  );

  function getTooltipContentWeb() {
    const rows = [
      { key: "User", value: "Gabe Oni" },
      { key: "Role", value: "Admin | Doctor" },
      { key: "AI Model", value: "GPT 3.5" },
      { key: "Database", value: "Disconnected" },
      { key: "Date/Time", value: getCurrentDateTime() },
    ];

    return (
      <React.Fragment>
        {rows.map((row, index) => (
          <Typography key={index} sx={{ fontSize: "10px", display: "block" }}>
            {`${row.key}: ${row.value}`}
          </Typography>
        ))}
      </React.Fragment>
    );
  }

  async function getTooltipContent() {
    // const cpuValue = await getCpu();
    // const getCpu = async () => {
    const cpuUsage = await getCpuUsage();
    // console.log("Info Avatar CPU Usage:", cpuUsage);
    //   return cpuUsage;
    // };
    const rows = [
      { key: "User", value: "Gabe Oni" },
      { key: "Role", value: "Admin | Doctor" },
      ...(isRunningInElectron
        ? [
            { key: "CPU", value: cpuUsage },
            { key: "Platform", value: `On '${getPlatform()}'` },
          ]
        : []),
      { key: "AI Model", value: "GPT 3.5" },
      { key: "Database", value: "Disconnected" },
      { key: "Date/Time", value: getCurrentDateTime() },
    ];

    return (
      <React.Fragment>
        {rows.map((row, index) => (
          <Typography key={index} sx={{ fontSize: "10px", display: "block" }}>
            {`${row.key}: ${row.value}`}
          </Typography>
        ))}
      </React.Fragment>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      const content = await getTooltipContent();
      setTooltipContent(content);
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once, like componentDidMount

  // useEffect(() => {
  //   if (isClicked) {
  //     // Handle the click effect, e.g., show a tooltip, perform an action, etc.
  //     console.log("Avatar Clicked!");
  //   }
  // }, [isClicked]);

  return (
    <Tooltip
      title={isRunningInElectron ? tooltipContent : getTooltipContentWeb()}
      enterDelay={800}
      arrow
    >
      <div style={{ position: "relative", display: "inline-block" }}>
        <IconButton
          size="small"
          onMouseEnter={handleHover}
          onMouseLeave={handleUnhover}
          onClick={handleAvatarClick}
        >
          <StyledAvatar
            src={AvatarDefault}
            alt="User Avatar"
            className={isHovered || isClicked ? "pulsating" : ""} // Conditionally apply pulsate class
            sx={{
              borderColor: getBorderColor(),
              borderWidth: "2px",
              borderStyle: "solid",
              transition: "border-color 0.3s ease-in-out",
            }}
          >
            GO
          </StyledAvatar>
          {/* <Avatar
            // src={{}}
            alt="photoURL"
            sx={{
              borderColor: getBorderColor(),
              borderWidth: "2px",
              borderStyle: "solid",
              transition: "border-color 0.3s ease-in-out",
              //   width: 32, height: 32
              //   animation: `${
              //     isHovered || isClicked ? `${pulsate} 1.5s infinite` : "none"
              //   }`,
            ...*/}
        </IconButton>
        {showBorder && (
          <div>{/* Additional border or countdown UI if needed */}</div>
        )}
      </div>
    </Tooltip>
  );
};

export default InfoAvatar;
