import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ThemeMode = 'light' | 'dark' | 'system';
export type Direction = 'rtl' | 'ltr';
export type Language = 'English' | 'French' | 'Arabic';

interface ThemeState {
  mode: ThemeMode;
  direction: Direction;
  language?: Language;
}

const getSystemTheme = (): ThemeMode => {
  // This checks the system preference
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return prefersDarkMode ? 'dark' : 'light';
};

const getTimeBasedTheme = (): ThemeMode => {
  const hour = new Date().getHours();
  return hour > 6 && hour < 18 ? 'light' : 'dark'; // Light mode from 6 AM to 6 PM
};


const initialState: ThemeState = {
  mode: getSystemTheme(), // Initially set based on system preference
  direction: 'ltr',
  language: 'English',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    // toggleTheme: (state) => {
    //   state.mode = state.mode === 'light' ? 'dark' : 'light';
    // //  state.mode = 'dark';
    // },
    // toggleDarkMode: (state) => {
    //     state.mode = !state.mode;
    //   },
    // toggleTheme: (state, action: PayloadAction<ThemeMode>) => {
    //     state.mode = action.payload;
    //   },
    toggleTheme: (state) => {
        state.mode = state.mode === 'light' ? 'dark' : 'light';
      },
    setTheme: (state, action: PayloadAction<ThemeMode>) => {
        if (action.payload === 'system') {
            // Choose between system preference or time-based theme here
            state.mode = getSystemTheme();
            // Or if you want to use time-based
            // state.mode = getTimeBasedTheme();
          } else {
            state.mode = action.payload;
          }
      },
    toggleDirection: (state) => {
        state.direction = state.direction === 'ltr' ? 'rtl' : 'ltr';
      },  
    setDirection: (state, action: PayloadAction<'ltr' | 'rtl'>) => {
        state.direction = action.payload;
      },
    setLanguage: (state, action: PayloadAction<Language>) => {
        state.language = action.payload;
      },
  },
});

export const { toggleTheme, setTheme, toggleDirection, setDirection, setLanguage } = themeSlice.actions;

export default themeSlice.reducer;
