// SlideBar.tsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import settingsIcon from "@iconify/icons-ic/baseline-settings";
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Scrollbar from "../../src/components/Scrollbar";
import SliderNotification from "../components/utility/SliderNotification";
import SliderSettings from "../components/utility/SliderSettings";
import { SliderChat } from "../components/utility/SliderChat";
import SliderMenu from "../components/utility/SliderMenu";
import { NOTIFICATIONS } from "../components/utility/SliderNotification";
// import { isElectron } from "../electron/electronUtils";
const { isElectron } = require("../electron/electronUtils");

// SlideBar.propTypes = {
//   isOpen: PropTypes.bool,
//   onClose: PropTypes.func,
//   component: PropTypes.node,
// };
interface SlideBarProps {
  isOpen: boolean;
  onClose: () => void;
  component: string;
  anchorDirection?: "left" | "right";
  showHeaderStack?: boolean;
  showFooterStack?: boolean;
  headerStackStyling?: React.CSSProperties;
  titlebarStyling?: React.CSSProperties;
  sliderSettingsStyles?: React.CSSProperties;
}
type NotificationSettings = {
  darkMode: boolean;
  lightMode: boolean;
  highContrast: boolean;
  lowContrast: boolean;
  // Add an index signature to allow dynamic keys
  [key: string]: boolean;
};
const isRunningInElectron = isElectron();

export default function SlideBar({
  isOpen,
  onClose,
  component,
  anchorDirection = "right",
  showHeaderStack = true,
  showFooterStack = true,
  headerStackStyling,
  titlebarStyling,
  sliderSettingsStyles,
}: SlideBarProps) {
  const theme = useTheme();
  const isRunningInElectron = isElectron();
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      darkMode: false,
      lightMode: true,
      highContrast: false,
      lowContrast: true,
      // Add other settings...
    });

  const getTitle = () => {
    switch (component) {
      case "settings":
        return "Settings";
      case "notifications":
        return "Notifications";
      case "chat":
        return "Chat";
      default:
        return "";
    }
  };

  const handleToggle = (setting: string) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [setting]: !prevSettings[setting],
    }));
  };

  return (
    <Drawer
      anchor={theme.direction === "rtl" ? "left" : anchorDirection}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 280,
          border: "none",
          overflow: "hidden",
          ...headerStackStyling,
        },
      }}
    >
      {showHeaderStack && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              px: 1,
              py: 2,
              mt: isRunningInElectron ? "84px" : "64px",
              ...titlebarStyling,
            }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              {/* Dynamic Title based on the selected component */}
              {getTitle()}
            </Typography>
            <IconButton onClick={onClose}>
              <Icon icon={closeFill} width={20} height={20} />
            </IconButton>
          </Stack>

          <Divider />
        </>
      )}
      {/* <Scrollbar> */}
      <div style={{ height: "100%" }}>
        {/* Dynamic Component based on the selection */}
        {component === "settings" ? (
          <SliderSettings
            settings={notificationSettings}
            onToggle={handleToggle}
            {...sliderSettingsStyles}
          />
        ) : component === "notifications" ? (
          <SliderNotification notifications={NOTIFICATIONS} />
        ) : component === "chat" ? (
          <SliderChat isOpen={isOpen} onClose={onClose} />
        ) : component === "menu" ? (
          <SliderMenu onClose={onClose} />
        ) : null}
      </div>
      {/* </Scrollbar> */}

      {showFooterStack && (
        <>
          <Box
            sx={{ pt: 1, pb: 1 }}
            style={{
              position: "absolute",
              bottom: 0,
              alignSelf: "center",
            }}
          >
            {component === "settings" && (
              <Button
                fullWidth
                size="large"
                onClick={() => {
                  window.location.href = "#Settings";
                }}
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon={settingsIcon} />}
              >
                Settings Page
              </Button>
            )}
            {component === "notifications" && (
              <Button
                fullWidth
                size="large"
                onClick={onClose}
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon={roundClearAll} />}
              >
                Clear All
              </Button>
            )}
          </Box>
        </>
      )}
    </Drawer>
  );
}
