import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { Provider } from "react-redux"; // import Redux
// import { store } from "./store"; //for redux from store.js
import store from "./store";
//import { Router, HashRouter } from 'react-router-dom';

import App from "./App";
import reportWebVitals from "./reportWebVitals";

//imports below are directly used for darkMode but id have to still configure
//color and the toggle mode button
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { getTheme } from "./utils/themeUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { selectThemeMode } from "./features/theme/themeSelectors";
import { setTheme } from "./features/theme/themeSlice";
import "./i18n/i18n"; // This will initialize i18n when the app starts
import Toast from "./components/utility/Toast";
import Layout from "./bars/titlebar/Layout";

//added this to fix override- styleOverrides due to errors from typecheck after post
//migration - see link: https://github.com/gregnb/mui-datatables/issues/1810
// declare module "@mui/material/styles" {
//   interface Components {
//     [key: string]: any;
//   }
// }
//the below uses MuiTheme to choose the color pallet to dark/light mode or otherwise
// const theme = createTheme({
//   // components: {
//   palette: {
//     mode: "dark",
//   },
// components: {
//   // styleOverrides: {
//   MuiButton: {
//     styleOverrides: {
//       contained: {
//         root: {
//           color: "#c0b88b", // change text color
//           backgroundColor: "#c0b88b", // change button background color
//         },
//       },
//     },
//   },
//   // MuiTypography: {
//   //   styleOverrides: {
//   // contained: {
//   // root: {
//   //   color: "brown", // change text color
//   // },
//   // },
//   //   },
//   // },
//   MuiIconButton: {
//     styleOverrides: {
//       // contained: {
//       root: {
//         color: "#b3a287", // change text color
//         // backgroundColor: "blue", // change button background color
//       },
//       // },
//     },
//   },
//   MuiSvgIcon: {
//     styleOverrides: {
//       // contained: {
//       root: {
//         color: "#b3a287", // change text color
//         // backgroundColor: "blue", // change button background color
//       },
//       // },
//     },
//   },
//   MuiIcon: {
//     styleOverrides: {
//       // contained: {
//       root: {
//         color: "#b3a287", // change text color
//         // backgroundColor: "blue", // change button background color
//       },
//       // },
//     },
//   },
//   // MuiToolbar: {
//   //   styleOverrides: {
//   //     // contained: {
//   //     root: {
//   //       color: "#7c6f61", // change text color
//   //       // backgroundColor: "blue", // change button background color
//   //     },
//   //     // },
//   //   },
//   // },
//   // MuiPaper: {
//   //   styleOverrides: {
//   //     // contained: {
//   //     root: {
//   //       color: "#b3a287", // change text color
//   //       // backgroundColor: "blue", // change button background color
//   //     },
//   //     // },
//   //   },
//   // },
//   // },
// },
// });
// ThemeProvider and CssBaseline are plugged in around before App to switch mode

// See renderToast use attempt in secondaryWindow.html
//(window as any).renderToast = (message: string) => {
//   const handleClose = () => {
//     const toastContainer = document.getElementById("toast-container");
//     if (toastContainer) {
//       ReactDOM.unmountComponentAtNode(toastContainer);
//     }
//   };

//   console.log("RenderToast called from index.tsx");
//   ReactDOM.render(
//     <Toast open={true} message={message} handleClose={handleClose} />,
//     document.getElementById("toast-container")
//   );
// };

// Defunct Attempt to use renderToast in secondaryWindow-
// window.renderToast = (message) => {
//   const div = document.createElement("div");
//   document.body.appendChild(div);

//   ReactDOM.render(
//     <Toast
//       open={true}
//       message={message}
//       handleClose={() => ReactDOM.unmountComponentAtNode(div)}
//     />,
//     div
//   );
// };

// CONSIDER - Use a Single Entry Point with Conditional Rendering of Toast in secondaryWindow: An alternative approach that avoids multiple entry points
//  by using coniditonal within the single entry point (the main React app here); can be based on a query parameter or some global variable set by your Electron code
// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import Toast from './components/Toast';

// const renderAppOrToast = () => {
//   const params = new URLSearchParams(window.location.search);
//   const isToastWindow = params.get('window') === 'toast';

//   if (isToastWindow) {
//     return <Toast open={true} message="Your toast message" handleClose={() => window.close()} />;
//   } else {
//     return <AppRoot />;
//   }
// };

// ReactDOM.render(
//   <React.StrictMode>
//     {renderAppOrToast()}
//   </React.StrictMode>,
//   document.getElementById('root')
// );
// Then Adjust Adjust the URL in ElectronMain.js:
// Load the main React app URL with a query parameter to indicate that it should render the Toast component.

// In ElectronMain.js:
// const secondaryURL = isDev
//   ? `http://localhost:3009/?window=toast`
//   : `file://${path.join(__dirname, '../../build/index.html')}?window=toast`;
// secondaryWindow.loadURL(toastURL);
// Notes:
// This approach uses the existing single entry point and leverages conditional rendering to display different components based on the context.
// Ensure that the logic for detecting whether to render the Toast component or the main App component is robust and only activates in the specific scenario of the secondary window.
// This approach eliminates the need to handle multiple entry points and simplifies the overall configuration.

const AppRoot = () => {
  const themeMode = useSelector((state: RootState) => state.theme.mode);
  const direction = useSelector((state: RootState) => state.theme.direction);
  const theme = getTheme(themeMode, direction);
  const dispatch = useDispatch();
  document.body.dir = direction; // This sets the overall document direction

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = (e: MediaQueryListEvent) => {
      const newColorScheme = e.matches ? "dark" : "light";
      if (themeMode === "system") {
        dispatch(setTheme(newColorScheme));
      }
    };

    mediaQuery.addEventListener("change", handleChange);

    // Set initial theme based on system preference
    dispatch(setTheme(mediaQuery.matches ? "dark" : "light"));

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, [dispatch, direction]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Layout /> */}
      <App />
      {/* </Layout> */}
    </ThemeProvider>
  );
};

ReactDOM.render<any, any>(
  //{{/* <Provider store={configureStore()} >   */}} //incase of reducer errors, raise provider to this top-level
  <React.StrictMode>
    <Provider store={store}>
      {/* <Layout> */}
      <AppRoot />
      {/* </Layout> */}
    </Provider>
  </React.StrictMode>,
  //</Provider>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
