import React, { ReactNode } from "react";
// import { IpcRenderer, ipcRenderer } from "electron";
// const { ipcRenderer } = require("electron");
// import { getPlatform } from "../../electron/electronUtils";
const { getPlatform } = require("../../electron/electronUtils");

type TitlebarButtonProps = {
  message: "minimizeApp" | "maximizeApp" | "closeApp";
  children: ReactNode;
};

const TitlebarButton = ({ message, children }: TitlebarButtonProps) => {
  const isMac = getPlatform();

  return (
    <button
      onClick={() => {
        window.electron.ipcRenderer.sendMessage(message, [message]);
        //   window.electron.sendMessage(message, [message]);
        //   handleAction={() => window.electron.handleLogout()}
      }}
      style={{ border: "none", background: "transparent", outline: "none" }}
    >
      {children}
    </button>
  );
};

export default TitlebarButton;
