// SettingsComponent.tsx
// import React, { useState } from "react";
// import { Tabs, Tab, AppBar, Container } from "@mui/material";
// import UserComponent from "./UserSettings";
// import GeneralComponent from "./GeneralSettings";
// import NotificationComponent from "./NotificationSettings";
// import BillingComponent from "./BillingSettings";
// import SecurityComponent from "./SecuritySettings";

// const SettingsComponent = () => {
//   const [tabValue, setTabValue] = useState(0);

//   const handleChange = (event: any, newValue: any) => {
//     setTabValue(newValue);
//   };

//   return (
//     <Container maxWidth="lg">
//       <AppBar position="static" color="default">
//         <Tabs
//           value={tabValue}
//           onChange={handleChange}
//           indicatorColor="primary"
//           textColor="primary"
//           variant="scrollable"
//           scrollButtons="auto"
//         >
//           <Tab label="User" />
//           <Tab label="General" />
//           <Tab label="Notifications" />
//           <Tab label="Billing" />
//           <Tab label="Security" />
//         </Tabs>
//       </AppBar>
//       {/* Displaying the corresponding component based on the selected tab */}
//       {tabValue === 0 && <UserComponent />}
//       {tabValue === 1 && <GeneralComponent />}
//       {tabValue === 2 && <NotificationComponent />}
//       {tabValue === 3 && <BillingComponent />}
//       {tabValue === 4 && <SecurityComponent />}
//     </Container>
//   );
// };

// export default SettingsComponent;

// SettingsComponent.tsx;
import React, { useState } from "react";
import { Box, Tab, Tabs, Typography, Container, Paper } from "@mui/material";
import UserComponent from "./UserSettings";
import GeneralComponent from "./GeneralSettings";
import NotificationComponent from "./NotificationSettings";
import BillingComponent from "./BillingSettings";
import SecurityComponent from "./SecuritySettings";

const SettingsComponent = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Paper sx={{ mt: 2, p: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleChange} centered>
            <Tab label="User" />
            <Tab label="General" />
            <Tab label="Notifications" />
            <Tab label="Billing" />
            <Tab label="Security" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <UserComponent />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <GeneralComponent />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <NotificationComponent />
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <BillingComponent />
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          <SecurityComponent />
        </TabPanel>
      </Paper>
    </Container>
  );
};

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default SettingsComponent;
