import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Box, Card, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { motion } from "framer-motion";

const StatsChart = () => {
  const [seriesData, setSeriesData] = useState([
    {
      x: new Date().getTime(),
      y: Math.floor(Math.random() * (80 - 15 + 1)) + 15,
    },
  ]);

  const XAXISRANGE = 60 * 1000; // 60 seconds

  const chartOptions: any = {
    chart: {
      id: "realtime",
      height: 350,
      type: "line",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Real-time Chart",
      align: "left",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value: any) => {
          // Format the date here
          let date = new Date(value);
          return date.toLocaleTimeString();
        },
      },
      range: XAXISRANGE,
    },
    yaxis: {
      max: 100,
      min: 0,
    },
    legend: {
      show: false,
    },
  };

  useEffect(() => {
    const updateDataInterval = setInterval(() => {
      setSeriesData((prevData: any) => {
        const now = new Date().getTime();
        const newY = Math.floor(Math.random() * (80 - 15 + 1)) + 15;
        const newDataPoint = { x: now, y: newY };

        let updatedData = [...prevData, newDataPoint];
        if (updatedData.length > 500) {
          // Maintain only the last 10 data points
          updatedData = updatedData.slice(updatedData.length - 10);
        }

        return updatedData;
      });
    }, 2000); // Update every 2 seconds for smoother transitions

    return () => clearInterval(updateDataInterval);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ borderRadius: "8px" }}
    >
      <Card sx={{ borderRadius: "8px" }}>
        <Box margin={2}>
          <Typography color="textSecondary" variant="h6" gutterBottom>
            STATS CHART
          </Typography>
        </Box>
        <Box minWidth={700}>
          <ReactApexChart
            options={chartOptions}
            series={[{ data: seriesData }]}
            type="line"
            height={350}
          />
        </Box>
      </Card>
    </motion.div>
  );
};

export default StatsChart;
