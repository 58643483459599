import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Tab,
  Tabs,
  TextField,
  Button,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
  Card,
  CardHeader,
  Box,
  Divider,
  Paper,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import NewsUpdate from "./NewsUpdate";

interface Todo {
  id: number;
  task: string;
  isCompleted: boolean;
}

const PREFIX = "TodoList";

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {},
  [`& .${classes.actions}`]: {
    justifyContent: "flex-end",
  },
}));

const TodoListComponent: React.FC = () => {
  const [todos, setTodos] = useState<Todo[]>([
    {
      id: 1,
      task: "Patient rounds in Ward 1 & 2",
      isCompleted: false,
    },
    {
      id: 2,
      task: "Prepare Drs presentation",
      isCompleted: true,
    },
    {
      id: 3,
      task: "Print Payment Receipts",
      isCompleted: false,
    },
    {
      id: 4,
      task: "Submit Supplier Voucher",
      isCompleted: false,
    },
  ]);

  const [inputValue, setInputValue] = useState<string>("");

  const statusChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const updatedTodos = todos.map((todo, index) =>
      index === id ? { ...todo, isCompleted: event.target.checked } : todo
    );
    setTodos(updatedTodos);
  };

  const addTodo = (event: React.FormEvent) => {
    event.preventDefault();

    const newTodo: Todo = {
      id: todos.length ? todos[todos.length - 1].id + 1 : 1,
      task: inputValue,
      isCompleted: false,
    };

    setTodos([newTodo, ...todos]);
    setInputValue("");
  };

  const removeTodo = (index: number) => {
    const updatedTodos = todos.filter((_, i) => i !== index);
    setTodos(updatedTodos);
  };

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12}>
          <Paper elevation={3} className="p-3">
            <form onSubmit={addTodo} style={{ padding: "16px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item lg={8}>
                  <TextField
                    fullWidth
                    type="text"
                    label="What to do today?"
                    variant="outlined"
                    value={inputValue}
                    onChange={inputChangeHandler}
                    required
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginLeft: "auto !important" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
            <List className="mt-3">
              {todos.map((todo, index) => (
                <ListItem key={todo.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={todo.isCompleted}
                        onChange={(event) => statusChangedHandler(event, index)}
                      />
                    }
                    label={
                      <Typography
                        // variant={todo.isCompleted ? "body2" : "body1"}
                        variant={"body2"}
                        style={{
                          textDecoration: todo.isCompleted
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {todo.task}
                      </Typography>
                    }
                  />

                  <div style={{ marginLeft: "auto" }}>
                    <IconButton
                      color={todo.isCompleted ? "secondary" : "default"}
                      onClick={() => removeTodo(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const TodoTabs: React.FC<{
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}> = ({ value, handleChange }) => {
  return (
    <Tabs value={value} onChange={handleChange} variant="fullWidth">
      <Tab label="ToDo" />
      <Tab label="Events" />
    </Tabs>
  );
};

const NoticeBoard: React.FC = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Root>
      <Card className={clsx(classes.root)}>
        <CardHeader title="Notice Board" />
        <Divider />
        <TodoTabs value={value} handleChange={handleChange} />

        <Box>
          {value === 0 && <TodoListComponent />}
          {value === 1 && <NewsUpdate />}
        </Box>
      </Card>
    </Root>
  );
};

export default NoticeBoard;
