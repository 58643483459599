import React from "react";
import { faker } from "@faker-js/faker";
import { Icon } from "@iconify/react";
import { formatDistance } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
} from "@mui/material";
// import Scrollbar from '../../Scrollbar';
import Scrollbar from "../Scrollbar";

// Utils
import { mockImgCover } from "../../utils/mockImages";

// ----------------------------------------------------------------------

interface NewsItem {
  title: string;
  description: string;
  image: string;
  postedAt: Date;
}

const NEWS: NewsItem[] = [...Array(5)].map((_, index) => {
  const setIndex = index + 1;
  return {
    title: faker.company.catchPhrase(),
    description: faker.lorem.sentences(3),
    image: mockImgCover(setIndex),
    postedAt: faker.date.soon(),
  };
});

// ----------------------------------------------------------------------

interface NewsItemProps {
  news: NewsItem;
}

const NewsItem: React.FC<NewsItemProps> = ({ news }) => {
  const { image, title, description, postedAt } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="img"
        alt={title}
        src={image}
        sx={{ width: 48, height: 48, borderRadius: 1.5 }}
      />
      {/* <Box sx={{ minWidth: 240 }}> */}
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          {/* <Typography variant="subtitle2" noWrap> */}
          <Typography
            variant="subtitle2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Link>
        {/* <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap> */}
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "text.secondary",
          }}
        >
          {description}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}
      >
        {formatDistance(postedAt, new Date())}
      </Typography>
    </Stack>
  );
};

const NewsUpdate: React.FC = () => {
  return (
    <Card>
      <CardHeader title="Updates" />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {NEWS.map((news) => (
            <NewsItem key={news.title} news={news} />
          ))}
        </Stack>
      </Scrollbar>
      <Divider />
      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

export default NewsUpdate;
