import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTheme, Theme } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import { AppointmentListHead } from "../../components/appointment";
import { AppointmentCalender } from "../../components/appointment";
import UpcomingAppointments from "../../components/stats/upcomingAppointments";
import createStyledComponent from "../../components/styled/styledcomponents";

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Appointments");

// const PREFIX = "Appointments";

// const classes = {
//   content: `${PREFIX}-content`,
// };

// const Root = styled("div")(({ theme }) => ({
//   [`&.${classes.content}`]: {
//     // display: "flex",
//     flexGrow: 1,
//     padding: theme.spacing(8),
//     background:
//       "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
//     // height: "100vh",
//     minHeight: "100vh",
//     height: "100%",
//   },
// }));

function Appointments() {
  const theme = useTheme();
  const [showCalendar, setShowCalendar] = useState(false);
  const toggleCalenderDisplay = () => {
    setShowCalendar((prev) => !prev);
  };

  return (
    <Root
      className={classes.content}
      // sx={{ padding: theme.spacing(8), height: "100vh" }}
    >
      {/* <p>Your Appointments</p> */}
      <Box
        sx={{
          // backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AppointmentListHead
            toggleDisplay={toggleCalenderDisplay}
            title={showCalendar ? "Next Appointments" : "See Calendar"}
          />
          <Box>
            {showCalendar ? <AppointmentCalender /> : <UpcomingAppointments />}
          </Box>
        </Container>
      </Box>
    </Root>
  );
}

export default Appointments;
