import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import Beds from "./Beds";
import AdmittedPatientsTable from "./AdmittedPatients";

const rooms = [
  {
    id: 1,
    roomNumber: "101",
    ward: "Cardiology",
    available: true,
    occupant: null,
    doctor: "Dr. Smith",
    admissionDate: "2023-10-01",
  },
  {
    id: 2,
    roomNumber: "102",
    ward: "Orthopedics",
    available: false,
    occupant: "Alice Johnson",
    doctor: "Dr. Brown",
    admissionDate: "2023-09-28",
  },
  {
    id: 3,
    roomNumber: "103",
    ward: "Pediatrics",
    available: true,
    occupant: null,
    doctor: "Dr. White",
    admissionDate: "2023-10-03",
  },
  {
    id: 4,
    roomNumber: "104",
    ward: "Neurology",
    available: true,
    occupant: null,
    doctor: "Dr. Turner",
    admissionDate: "2023-10-02",
  },
  {
    id: 5,
    roomNumber: "105",
    ward: "Ophthalmology",
    available: false,
    occupant: "Bob Anderson",
    doctor: "Dr. Miller",
    admissionDate: "2023-09-30",
  },
  {
    id: 6,
    roomNumber: "201",
    ward: "Gynecology",
    available: true,
    occupant: null,
    doctor: "Dr. Johnson",
    admissionDate: "2023-10-05",
  },
  {
    id: 7,
    roomNumber: "202",
    ward: "Dermatology",
    available: true,
    occupant: null,
    doctor: "Dr. Davis",
    admissionDate: "2023-10-01",
  },
  {
    id: 8,
    roomNumber: "203",
    ward: "Urology",
    available: false,
    occupant: "Charlie Brown",
    doctor: "Dr. Green",
    admissionDate: "2023-09-29",
  },
  {
    id: 9,
    roomNumber: "204",
    ward: "Psychiatry",
    available: true,
    occupant: null,
    doctor: "Dr. Black",
    admissionDate: "2023-10-04",
  },
  {
    id: 10,
    roomNumber: "205",
    ward: "ENT",
    available: true,
    occupant: null,
    doctor: "Dr. Adams",
    admissionDate: "2023-10-06",
  },
];

const admittedPatients = [
  {
    id: 1,
    roomNumber: "101",
    roomId: 1,
    roomName: "Cardiology",
    patientInfo: "John Doe",
    admissionDate: "2023-10-01",
    ailment: "Heart Disease",
    doctorInfo: "Dr. Smith",
    roomAvailability: true,
    assignedPersonnel: "Nurse Jane",
  },
  {
    id: 2,
    roomNumber: "102",
    roomId: 2,
    roomName: "Orthopedics",
    patientInfo: "Alice Johnson",
    admissionDate: "2023-09-28",
    ailment: "Fractured Leg",
    doctorInfo: "Dr. Brown",
    roomAvailability: false,
    assignedPersonnel: "Nurse Mike",
  },
  {
    id: 3,
    roomNumber: "103",
    roomId: 3,
    roomName: "Pediatrics",
    patientInfo: "Bob Smith",
    admissionDate: "2023-10-03",
    ailment: "Fever",
    doctorInfo: "Dr. White",
    roomAvailability: true,
    assignedPersonnel: "Nurse Emily",
  },
  {
    id: 4,
    roomNumber: "104",
    roomId: 4,
    roomName: "Neurology",
    patientInfo: "Charlie Turner",
    admissionDate: "2023-10-02",
    ailment: "Headache",
    doctorInfo: "Dr. Turner",
    roomAvailability: true,
    assignedPersonnel: "Nurse Sarah",
  },
  {
    id: 5,
    roomNumber: "105",
    roomId: 5,
    roomName: "Ophthalmology",
    patientInfo: "Alice Anderson",
    admissionDate: "2023-09-30",
    ailment: "Eye Infection",
    doctorInfo: "Dr. Miller",
    roomAvailability: false,
    assignedPersonnel: "Nurse James",
  },
  {
    id: 6,
    roomNumber: "201",
    roomId: 6,
    roomName: "Gynecology",
    patientInfo: "Eve Johnson",
    admissionDate: "2023-10-05",
    ailment: "Pregnancy Checkup",
    doctorInfo: "Dr. Johnson",
    roomAvailability: true,
    assignedPersonnel: "Nurse Olivia",
  },
  {
    id: 7,
    roomNumber: "202",
    roomId: 7,
    roomName: "Dermatology",
    patientInfo: "David Davis",
    admissionDate: "2023-10-01",
    ailment: "Skin Rash",
    doctorInfo: "Dr. Davis",
    roomAvailability: true,
    assignedPersonnel: "Nurse Ethan",
  },
  {
    id: 8,
    roomNumber: "203",
    roomId: 8,
    roomName: "Urology",
    patientInfo: "Frank Green",
    admissionDate: "2023-09-29",
    ailment: "Urinary Tract Infection",
    doctorInfo: "Dr. Green",
    roomAvailability: false,
    assignedPersonnel: "Nurse Lily",
  },
];

const generateBedspaces = () => {
  const bedspaces = [];
  let bedCount = 1;

  for (let i = 0; i < rooms.length; i++) {
    for (let j = 0; j < 2; j++) {
      // Two beds per room
      bedspaces.push({
        id: bedCount++,
        room: rooms[i],
        bedName: `Bed ${bedCount}`,
      });
    }
  }

  return bedspaces;
};

const OnAdmission = () => {
  const [bedspaces, setBedspaces] = useState([
    generateBedspaces(),
    // Add more bedspaces with various details
  ]);

  const handleAddBed = (roomId, bedName) => {
    // Check if the room exists
    const room = rooms.find((r) => r.id === roomId);
    if (!room) return;

    // Check if the bed already exists in the bedspaces array
    const bedExists = bedspaces.some(
      (bed) => bed.room.id === roomId && bed.bedName === bedName
    );
    if (bedExists) return;

    setBedspaces([...bedspaces, { id: bedspaces.length + 1, room, bedName }]);
  };

  const handleRemoveBed = (roomId) => {
    // Check if the room exists
    const room = rooms.find((r) => r.id === roomId);
    if (!room) return;

    // Find the index of the bed to remove
    const bedIndex = bedspaces.findIndex((bed) => bed.room.id === roomId);

    if (bedIndex !== -1) {
      // Remove the bed if found
      const newBedspaces = [...bedspaces];
      newBedspaces.splice(bedIndex, 1);
      setBedspaces(newBedspaces);
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      {/* <Typography variant="h4" gutterBottom>
        Admission
      </Typography> */}
      <Typography variant="h5" style={{ marginTop: "10px" }} gutterBottom>
        Bed Spaces
      </Typography>

      {/* Grid for displaying bed icons */}
      <Grid container spacing={3} justifyContent="center">
        {rooms.map((room) => (
          <Grid key={room.id} item>
            <Paper style={{ padding: "20px", textAlign: "center" }}>
              <Beds
                room={room}
                onAdd={handleAddBed}
                onRemove={handleRemoveBed}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* List of admitted patients */}
      <Typography variant="h5" style={{ marginTop: "30px" }} gutterBottom>
        Admitted Patients
      </Typography>
      <Box>
        <AdmittedPatientsTable />
      </Box>
      {/* <List>
        {admittedPatients.map((patient) => (
          <ListItem key={patient.id}>
            <ListItemText
              primary={`Room ${patient.roomNumber} - ${patient.roomName}`}
              secondary={`Patient: ${patient.patientInfo}, Admission Date: ${patient.admissionDate}`}
            />
          </ListItem>
        ))}
      </List> */}
    </div>
  );
};

export default OnAdmission;
