import React, { useState } from "react";
import { ToolbarProps } from "react-big-calendar";
import moment from "moment";
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { View } from "react-big-calendar";
import AppointmentForm from "./AppointmentForm";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TodayIcon from "@mui/icons-material/Today";
import AddIcon from "@mui/icons-material/Add";
import ViewModuleIcon from "@mui/icons-material/ViewModule"; // Assuming this is for a month view
import ViewComfyIcon from "@mui/icons-material/ViewComfy"; // Assuming this is for a month view
import ViewWeekIcon from "@mui/icons-material/ViewWeek"; // Assuming this is for a week view
import ViewDayIcon from "@mui/icons-material/ViewDay"; // Assuming this is for a day view
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda"; // Assuming this is for an agenda view
import patients from "../../_mockData_/patients";
import users from "../../_mockData_/users";

interface CustomToolbarProps extends ToolbarProps {}

// type View = "month" | "week" | "day" | "agenda";

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  date,
  onNavigate,
  onView,
  view,
  label,
}) => {
  const navigate = (action: "PREV" | "NEXT" | "TODAY") => {
    onNavigate(action);
  };
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();

  const goToToday = () => navigate("TODAY");
  const goToBack = () => navigate("PREV");
  const goToNext = () => navigate("NEXT");

  const handleAddEvent = () => {
    // Logic to handle the addition of new event
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //   const handleViewChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //     const nextView = event.target.value as View; // Cast to the View type
  //     onView(nextView);
  //   };
  const handleViewChange = (event: SelectChangeEvent<View>) => {
    onView(event.target.value as View);
  };

  const viewLabel = moment(date).format("DD MMMM YYYY");

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={2}
      sx={{ backgroundColor: "background.paper", borderRadius: "8px" }}
    >
      <Box display="flex" alignItems="center">
        <IconButton onClick={goToBack} aria-label="Previous month">
          <NavigateBeforeIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ marginX: 2 }}>
          {viewLabel}
        </Typography>
        <IconButton onClick={goToNext} aria-label="Next month">
          <NavigateNextIcon />
        </IconButton>
        <Tooltip title="Today">
          <IconButton onClick={goToToday} aria-label="Today">
            <TodayIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box display="flex" alignItems="center">
        <Tooltip title="Add new event">
          <IconButton onClick={handleAddEvent} aria-label="Add event">
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Select
          value={view}
          onChange={handleViewChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            backgroundColor: "primary.main",
            borderRadius: "8px",
            "& .MuiSelect-select": {
              py: 1, // padding top and bottom
              px: 2, // padding left and right
              display: "flex",
              alignItems: "center",
              color: "primary.contrastText", // text color for the primary palette
            },
            "& .MuiSvgIcon-root": {
              color: "primary.contrastText", // icon color for the primary palette
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // removes the default border
            },
            "&:hover": {
              backgroundColor: "primary.dark", // darker background color on hover
            },
            "&:focus": {
              backgroundColor: "primary.main", // maintain background color on focus
            },
          }}
          //   input={<OutlinedInput notched labelWidth={0} />}
          renderValue={(selected) => (
            <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
              {selected === "month" && <ViewComfyIcon />}
              {selected === "week" && <ViewWeekIcon />}
              {selected === "day" && <ViewDayIcon />}
              {selected === "agenda" && <ViewAgendaIcon />}
              <Typography variant="body2">
                {selected.charAt(0).toUpperCase() + selected.slice(1)}
              </Typography>
            </Box>
          )}
        >
          <MenuItem value="month">
            <ViewComfyIcon sx={{ marginRight: 1 }} />
            {/* <ViewModuleIcon /> */}
            Month
          </MenuItem>
          <MenuItem value="week">
            <ViewWeekIcon sx={{ marginRight: 1 }} />
            Week
          </MenuItem>
          <MenuItem value="day">
            <ViewDayIcon sx={{ marginRight: 1 }} />
            Day
          </MenuItem>
          <MenuItem value="agenda">
            <ViewAgendaIcon sx={{ marginRight: 1 }} />
            Agenda
          </MenuItem>
        </Select>
      </Box>
      {showModal && (
        <AppointmentForm
          patients={patients}
          doctors={users}
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default CustomToolbar;
//   return (
//     <Box
//       display="flex"
//       alignItems="center"
//       justifyContent="space-between"
//       padding={2}
//       sx={{ backgroundColor: "background.paper", borderRadius: 1 }}
//     >
//       <IconButton onClick={goToBack} aria-label="Previous month">
//         <NavigateBeforeIcon />
//       </IconButton>

//       <Typography variant="h6" component="div">
//         {viewLabel}
//       </Typography>

//       <IconButton onClick={goToNext} aria-label="Next month">
//         <NavigateNextIcon />
//       </IconButton>

//       <Box>
//         <Tooltip title="Today">
//           <IconButton onClick={goToToday} aria-label="Today">
//             <TodayIcon />
//           </IconButton>
//         </Tooltip>
//         <Tooltip title="Add new event">
//           <IconButton onClick={handleAddEvent} aria-label="Add event">
//             <AddIcon />
//           </IconButton>
//         </Tooltip>
//         <Tooltip title="Month view">
//           <IconButton
//             onClick={() => onView("month")}
//             aria-label="Month view"
//             color={view === "month" ? "primary" : "default"}
//           >
//             <ViewModuleIcon />
//           </IconButton>
//         </Tooltip>
//         {/* Add more view options or buttons as required */}
//       </Box>
//     </Box>
//   );
// };

// export default CustomToolbar;
