import React from "react";
import { styled } from "@mui/material/styles";
//import App from './App.tsx';
import SideBar from "../bars/sideBar";
// import MiniDrawer from '../bars/sideBar'; //Test later as a standalone part of the sidebar component
//import Footer from '../bars/footer.js';
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
// import Routes from '../routes';
import PatientStats from "../components/stats/patientStats";
import RevenueStats from "../components/stats/revenueStats";
import SocialStats from "../components/stats/socialStats";
import UpcomingAppointments from "../components/stats/upcomingAppointments";
import NoticeBoard from "../components/apps/NoticeBoard";
import AgeDemographyStats from "../components/stats/charts/ageDemography";
import CustomStats from "../components/stats/charts/customStats";
import StatsChart from "../components/stats/charts/realTime";
import {
  chartOptions,
  chartSeries,
} from "../components/stats/charts/chartData";
import DynamicDataGrid from "../components/stats/dataGrid";
import NoteTaker from "../components/utility/NoteTaker";
import InfoCard from "../components/utility/InfoCard";
import { OfferCard } from "../components/utility/OfferCard";
// import Appointments from '././appointments/index';
// import Patients from '././patient/index';
// import Pharmacy from '././pharmacy/index';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Box, Container, Grid, Theme } from "@mui/material";
import createStyledComponent from "../components/styled/styledcomponents";
import {
  Chip,
  IconButton,
  Typography,
  Paper,
  Button,
  Divider,
} from "@mui/material";
import { ArrowCircleRight, InfoIcon, CloseIcon } from "@mui/icons-material";
// import { openNewWindow } from "../electron/electronUtils";
const { openNewWindow } = require("../electron/electronUtils");

// const PREFIX = "Home";

// const classes = {
//   content: `${PREFIX}-content`,
// };

// const Root = styled("div")(({ theme }) => ({
//   [`& .${classes.content}`]: {
//     flexGrow: 1,
//     flexDirection: "column",
//     padding: theme.spacing(8),
//     // height: 800,
//     minHeight: "100vh",
//     background: "rgba(241, 229, 229, 0.4)",
//     borderRadius: "15px",
//     backdropFilter: "blur(3px)",
//   },
// }));

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Home");

const CustomBanner = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  display: "inline-flex",
  alignItems: "center",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const LearnMoreButton = styled(Button)({
  textTransform: "none",
  fontWeight: "bold",
  marginLeft: 1,
  marginRight: 1,
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: 25,
  margin: theme.spacing(1, 1),
  display: "inline-flex",
}));

const CustomName = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  textTransform: "none",
  fontWeight: "bold",
  display: "inline-flex",
  // padding: theme.spacing(1, 1),
}));

const Home = () => {
  const handleNewWindowClick = () => {
    const windowOptions = {
      width: 400,
      height: 400,
      webPreferences: {
        nodeIntegration: false,
        contextIsolation: true,
      },
      // other options you want to set
    };

    openNewWindow(windowOptions);
    console.log(`new window triggered`);
  };

  return (
    <Root
      sx={{
        flexGrow: 1,
        minHeight: "100vh",
        padding: "16px",
      }}
      className={classes.content}
    >
      {/* <Box
      sx={{
        flexGrow: 1,
        p: 3,
        background:
          "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
        minHeight: "100vh",
      }}
    > */}
      {/* <Root
        style={{
          background:
            "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
        }}
      > */}
      <div className={classes.content}>
        <div>
          {" "}
          <Box
            display="flex"
            justifyContent="left"
            width="100%"
            sx={{ padding: "23px" }}
          >
            <CustomBanner
              sx={{
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                padding: "3px",
              }}
            >
              <Typography variant="body2">
                <IconButton size="small">
                  <Chip
                    label="New"
                    color="primary"
                    size="small"
                    sx={{ marginRight: 1 }}
                  />
                </IconButton>
                Welcome to Your Dashboard <CustomName> Gabe Oni</CustomName>
              </Typography>
              <StyledDivider orientation="vertical" flexItem />
              <LearnMoreButton
                size="small"
                endIcon={<ArrowCircleRight fontSize="inherit" />}
                onClick={handleNewWindowClick}
              >
                Learn More
              </LearnMoreButton>
            </CustomBanner>
          </Box>
        </div>

        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <PatientStats />
            </Grid>

            <Grid item lg={3} sm={6} xl={3} xs={12}>
              {/* <RevenueStats /> */}
              <CustomStats
                title="MONTHLY VISITS"
                improvement={10} // You can customize this value based on your requirement
                percentageIncrease={10.5} // You can customize this value based on your requirement
                currentFigure="15,837" // You can customize this value based on your requirement
                chartOptions={chartOptions}
                chartSeries={chartSeries}
              />
            </Grid>

            <Grid item lg={3} sm={6} xl={3} xs={12}>
              {/* <SocialStats /> */}
              <RevenueStats />
            </Grid>

            {/* <Grid item lg={4} sm={6} xl={3} xs={12}>
              <AgeDemographyStats />
              <SocialStats />
            </Grid> */}
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CustomStats
                title="PORTFOLIO"
                improvement={-10} // You can customize this value based on your requirement
                percentageIncrease={-10.5} // You can customize this value based on your requirement
                currentFigure="5,837" // You can customize this value based on your requirement
                chartOptions={chartOptions}
                chartSeries={chartSeries}
                chartType="bar"
              />
            </Grid>

            <Grid item lg={8} sm={12} xl={9} xs={12}>
              <UpcomingAppointments />
            </Grid>

            {/* <Grid
              item
              xs={12}
              lg={4}
              xl={4}
              sx={{ gridMargin: "stretch-card" }}
            >
              <TodoList />
            </Grid> */}
            <Grid item xs={12} lg={4} xl={3}>
              <NoticeBoard />
            </Grid>

            <Grid item xs={12} lg={4} xl={3}>
              <Box>
                <AgeDemographyStats />
              </Box>
              <Box sx={{ marginTop: "8px", marginBottom: "5px" }}>
                <InfoCard height="180px" width="100%" />
                {/* <OfferCard height="2000px" width="100%" /> */}
              </Box>
            </Grid>

            <Grid item lg={8} sm={12} xl={9} xs={12}>
              <StatsChart />
            </Grid>

            <Grid item lg={8} sm={12} xl={9} xs={12}>
              <DynamicDataGrid />
            </Grid>

            <Grid item xs={12} lg={4} xl={3}>
              <NoteTaker />
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* </Root> */}
      {/* </Box> */}
    </Root>
  );
};

//export{};
export default Home;
