import React from "react";
import { styled } from "@mui/material/styles";
import { useTheme, Theme } from "@mui/material/styles";
import { AccountsPage } from "../../components/accounts";
import { Box, Button } from "@mui/material";
import createStyledComponent from "../../components/styled/styledcomponents";

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Accounts");

// const PREFIX = "Index";

// const classes = {
//   content: `${PREFIX}-content`,
// };

function Index() {
  const theme = useTheme();

  // const Root = styled("div")(() => ({
  //   [`&.${classes.content}`]: {
  //     flexGrow: 1,
  //     padding: theme.spacing(1),
  //     background:
  //       "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
  //     minHeight: "100vh",
  //   },
  // }));

  return (
    <Root
      className={classes.content}
      sx={{ padding: theme.spacing(2), height: "100vh" }}
    >
      <div>
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
              padding: "4px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Button color="primary" variant="contained">
              Create Invoice
            </Button>
            <Button color="primary" variant="contained">
              Generate Receipt
            </Button>
          </Box>
        </div>
        <AccountsPage />
      </div>
    </Root>
  );
}

export default Index;
