import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import EditProfileCardWidget from "./EditProfileCardWidget";
import HistoryTimeline from "./HistoryTimeline";

interface ViewProfileWidgetProps {
  selectedPatient?: {
    fullName?: string;
    dob?: string;
    email?: string;
    address?: string;
    contactNumber?: string;
    nextOfKin?: string;
    bloodGroup?: string;
    bloodType?: string;
    weight?: string;
    bmi?: string;
    nhsNumber?: string;
    nhisNumber?: string;
    insuranceNumber?: string;
    companyGroups?: string;
    familyGroups?: string;
    // Add more patient details as needed
  };
  handleClose?: () => void;
}

const ViewProfileWidget: React.FC<ViewProfileWidgetProps> = ({
  selectedPatient = {},
  handleClose,
}) => {
  const [formData, setFormData] = useState({
    fullName: selectedPatient.fullName || "Gabe Oni",
    dob: selectedPatient.dob || "19-09-22",
    email: selectedPatient.email || "zds@fff.com",
    address: selectedPatient.address || "No 123 Fulton",
    contactNumber: selectedPatient.contactNumber || "+234089734334",
    nextOfKin: selectedPatient.nextOfKin || "Oni",
    bloodGroup: selectedPatient.bloodGroup || "B+",
    bloodType: selectedPatient.bloodType || "A+",
    weight: selectedPatient.weight || "69kg",
    bmi: selectedPatient.bmi || "111",
    nhsNumber: selectedPatient.nhsNumber || "23434",
    nhisNumber: selectedPatient.nhisNumber || "43434",
    insuranceNumber: selectedPatient.insuranceNumber || "343434",
    companyGroups: selectedPatient.companyGroups || "ABC",
    familyGroups: selectedPatient.familyGroups || "XYZ",
  });
  const [isPersonalDetailsVisible, setPersonalDetailsVisibility] =
    useState(false);
  const [isMedicalDetailsVisible, setMedicalDetailsVisibility] =
    useState(false);
  const [isRegistrationsVisible, setRegistrationsVisibility] = useState(false);
  const [isGroupAffiliationsVisible, setGroupAffiliationsVisibility] =
    useState(false);
  const [isHistoryVisible, setHistoryVisibility] = useState(false);

  const togglePersonalDetails = () => {
    setPersonalDetailsVisibility(!isPersonalDetailsVisible);
  };
  const toggleMedicalDetails = () => {
    setMedicalDetailsVisibility(!isMedicalDetailsVisible);
  };
  const toggleRegistrations = () => {
    setRegistrationsVisibility(!isRegistrationsVisible);
  };
  const toggleGroupAffiliations = () => {
    setGroupAffiliationsVisibility(!isGroupAffiliationsVisible);
  };
  const toggleHistory = () => {
    setHistoryVisibility(!isHistoryVisible);
  };

  return (
    <Box sx={{ width: 500 }}>
      {/* <Dialog open={true} maxWidth="sm" fullWidth> */}
      <DialogTitle>View Profile</DialogTitle>
      <DialogContent dividers>
        <Box marginBottom={4}>
          <EditProfileCardWidget />
          <Divider style={{ marginTop: "5px", marginBottom: "30px" }} />
        </Box>

        {/* Patient Details */}
        <Box marginBottom={2}>
          <Card>
            <CardContent style={{ padding: "10px" }}>
              <Typography variant="h6" onClick={togglePersonalDetails}>
                Personal Details
              </Typography>
            </CardContent>

            {isPersonalDetailsVisible && (
              <Grid item xs={12}>
                <Box margin={2}>
                  <Typography>
                    <strong>Full Name:</strong> {formData.fullName}
                  </Typography>
                  <Typography>
                    <strong>Date of Birth:</strong> {formData.dob}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {formData.email}
                  </Typography>
                  <Typography>
                    <strong>Address:</strong> {formData.address}
                  </Typography>
                  <Typography>
                    <strong>Contact Number:</strong> {formData.contactNumber}
                  </Typography>
                  <Typography>
                    <strong>Next of Kin:</strong> {formData.nextOfKin}
                  </Typography>
                  {/* Add more personal details as needed */}
                </Box>
              </Grid>
            )}
          </Card>
        </Box>

        {/* History  */}
        <Box marginBottom={2}>
          <Card>
            <CardContent style={{ padding: "10px" }}>
              <Typography variant="h6" onClick={toggleHistory}>
                History
              </Typography>
            </CardContent>

            {isHistoryVisible && (
              <Grid item xs={12}>
                <Box margin={2}>
                  <HistoryTimeline />
                  {/* Add more medical details as needed */}
                </Box>
              </Grid>
            )}
          </Card>
        </Box>

        {/* Medical Details */}
        <Box marginBottom={2}>
          <Card>
            <CardContent style={{ padding: "10px" }}>
              <Typography variant="h6" onClick={toggleMedicalDetails}>
                Medical Details
              </Typography>
            </CardContent>

            {isMedicalDetailsVisible && (
              <Grid item xs={12}>
                <Box margin={2}>
                  <Typography>
                    <strong>Blood Group:</strong> {formData.bloodGroup}
                  </Typography>
                  <Typography>
                    <strong>Blood Type:</strong> {formData.bloodType}
                  </Typography>
                  <Typography>
                    <strong>Weight:</strong> {formData.weight}
                  </Typography>
                  <Typography>
                    <strong>BMI:</strong> {formData.bmi}
                  </Typography>
                  {/* Add more medical details as needed */}
                </Box>
              </Grid>
            )}
          </Card>
        </Box>

        {/* Registrations Section */}
        <Box marginBottom={2}>
          <Card>
            <CardContent style={{ padding: "10px" }}>
              <Typography variant="h6" onClick={toggleRegistrations}>
                Registrations
              </Typography>
            </CardContent>

            {isRegistrationsVisible && (
              <Grid item xs={12}>
                <Box margin={2}>
                  <Typography>
                    <strong>NHS Number:</strong> {formData.nhsNumber}
                  </Typography>
                  <Typography>
                    <strong>NHIS Number:</strong> {formData.nhisNumber}
                  </Typography>
                  <Typography>
                    <strong>Insurance Number:</strong>{" "}
                    {formData.insuranceNumber}
                  </Typography>
                  {/* Add more registration details as needed */}
                </Box>
              </Grid>
            )}
          </Card>
        </Box>

        {/* Group Affiliations Section */}
        <Box marginBottom={2}>
          <Card>
            <CardContent style={{ padding: "10px" }}>
              <Typography variant="h6" onClick={toggleGroupAffiliations}>
                Group Affiliations
              </Typography>
            </CardContent>

            {isGroupAffiliationsVisible && (
              <Grid item xs={12}>
                <Box margin={2}>
                  <Typography>
                    <strong>Company Groups:</strong> {formData.companyGroups}
                  </Typography>
                  <Typography>
                    <strong>Family Groups:</strong> {formData.familyGroups}
                  </Typography>
                  {/* Add more group affiliations as needed */}
                </Box>
              </Grid>
            )}
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          CLOSE
        </Button>
      </DialogActions>
      {/* </Dialog> */}
    </Box>
  );
};

export default ViewProfileWidget;
