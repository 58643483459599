// Scrollbar.tsx
import PropTypes from "prop-types";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { alpha, styled } from "@mui/system";
import { Box } from "@mui/material";

const PREFIX = "Index";

const classes = {
  content: `${PREFIX}-content`,
};

const RootStyle = styled("div")(() => ({
  [`&.${classes.content}`]: {
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
  },
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: "100%",
  "& .simplebar-scrollbar": {
    "&:before": {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    "&.simplebar-visible:before": {
      opacity: 1,
    },
  },
  "& .simplebar-track.simplebar-vertical": {
    width: 10,
  },
  "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
    height: 6,
  },
  "& .simplebar-mask": {
    zIndex: "inherit",
  },
}));

// Make `sx` optional

interface ScrollbarProps {
  children: React.ReactNode;
  sx?: any; // Marking it as optional with ?
  [x: string]: any;
}

export default function Scrollbar({ children, sx, ...other }: ScrollbarProps) {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: "auto", ...(sx || {}) }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <div className={classes.content}>
        <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
          {children}
        </SimpleBarStyle>
      </div>
    </RootStyle>
  );
}
