// NotificationComponent.tsx
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Switch,
  Grid,
  FormControlLabel,
  FormGroup,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import UpdateIcon from "@mui/icons-material/Update";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BlogIcon from "@mui/icons-material/Description";

const NotificationSettings = () => {
  const [accountNotifications, setAccountNotifications] = useState({
    accountActivity: true,
    productUpdatesEmail: false,
    securityUpdatesEmail: true,
    securityUpdatesPhone: false,
  });

  const [appNotifications, setAppNotifications] = useState({
    newsAnnouncements: true,
    weeklyProductUpdates: false,
    weeklyBlogDigest: true,
  });

  const handleAccountNotificationChange = (event: any) => {
    setAccountNotifications({
      ...accountNotifications,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAppNotificationChange = (event: any) => {
    setAppNotifications({
      ...appNotifications,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container spacing={3}>
      {/* First Row */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Account Activity
            </Typography>
            <Divider />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={accountNotifications.accountActivity}
                    onChange={handleAccountNotificationChange}
                    name="accountActivity"
                  />
                }
                label="Get important notifications about your activity"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={accountNotifications.productUpdatesEmail}
                    onChange={handleAccountNotificationChange}
                    name="productUpdatesEmail"
                  />
                }
                label="Product updates for Email"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={accountNotifications.securityUpdatesEmail}
                    onChange={handleAccountNotificationChange}
                    name="securityUpdatesEmail"
                  />
                }
                label="Security updates for Email"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={accountNotifications.securityUpdatesPhone}
                    onChange={handleAccountNotificationChange}
                    name="securityUpdatesPhone"
                  />
                }
                label="Security updates for Phone"
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Grid>

      {/* Second Row */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Application Activity
            </Typography>
            <Divider />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={appNotifications.newsAnnouncements}
                    onChange={handleAppNotificationChange}
                    name="newsAnnouncements"
                  />
                }
                label="News and announcements"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={appNotifications.weeklyProductUpdates}
                    onChange={handleAppNotificationChange}
                    name="weeklyProductUpdates"
                  />
                }
                label="Weekly product updates"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={appNotifications.weeklyBlogDigest}
                    onChange={handleAppNotificationChange}
                    name="weeklyBlogDigest"
                  />
                }
                label="Weekly blog digest"
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Grid>

      {/* Additional Rows */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Custom Notifications
            </Typography>
            <Divider />
            <List>
              <ListItem>
                <ListItemIcon>
                  <NotificationsActiveIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Custom Notification 1"
                  secondary="Description for Custom Notification 1"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Custom Notification 2"
                  secondary="Description for Custom Notification 2"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Custom Notification 3"
                  secondary="Description for Custom Notification 3"
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotificationSettings;
