import React, { useState, useEffect } from "react";
import "./login.css";
import { Form, Jumbotron } from "react-bootstrap";

import Home from "../pages/Home";
//import Patients from '../pages/patient/index';

import {
  HashRouter,
  // Link,
  Route,
  Switch,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { withTheme } from "@mui/styles";
import Routes from "../routes"; // this mport sometimes causes errors, be weary

import {
  Box,
  TextField,
  Button,
  Alert,
  InputAdornment,
  Link,
  IconButton,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";

import { render } from "@testing-library/react";
//import Jumbotron from 'react-bootstrap/Jumbotron';

import logo from "../res/icons/logo-light.gif";
import logoDark from "../res/icons/logo-dark.gif";
//image/resources import below
import allhealth from "../res/images/360-health.jpeg";
import tele from "../res/images/telehealth.png";
import digi from "../res/images/digihealth.png";
import waves2 from "../res/images/waves2.gif";

//nterface LoginState {
//    user: any
// }

// im adding this to import users state from App.tsx
// const user: string[] = this.props.user ;

// class Login extends React.Component<any, any> {
/* constructor(props: any) {
    super(props);
    this.state = {
      user: null,
      password: null,
    };
 
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }
*/
// constructor(props: any) {
//   super(props);
//   this.state = {
//     user: "Gabe Oni",
//     navigate: false,
//   };
// }

/* // The HandleSubmit fucntion will be used for authetication later 
   // TO DO !
  handleSubmit(e: any) {
    this.setState({ user: e.target.value });

    return (
      <Home/>
    );
  }
  */

const GlassyTextField = styled(TextField)(({ theme }) => ({
  size: "small",
  background: "rgba(255, 255, 255, 0.15)",
  backdropFilter: "blur(15px)",
  borderRadius: "8px",
  border: "none",
  "& .MuiInputBase-input": {
    color: theme.palette.mode === "dark" ? "black" : "black", // Adjusts text color
    borderRadius: "8px",
    fontSize: "0.875rem",
  },
  "& .FormControl-root": {
    borderRadius: "8px !important",
  },
}));

// Dummy login credentials, replace with actual login logic
const dummyEmail = "guest@terakota.live";
const dummyPassword = "guest1234";

const Login = () => {
  const [navigate, setNavigate] = useState(false);
  const [email, setEmail] = useState<string>(dummyEmail);
  const [password, setPassword] = useState<string>(dummyPassword);
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const history = useHistory();

  // const handleLogin = () => {
  //   // Simple credential check
  //   // setEmail(dummyEmail);
  //   // setPassword(dummyPassword);
  //   if (email === dummyEmail && password === dummyPassword) {
  //     // Redirect user to the next page
  //     // For example, using useHistory hook from react-router-dom or window.location.href
  //     // history.push('/next-page');
  //     console.log("Logged in successfully");
  //     goToHome();
  //   } else {
  //     // Show error message
  //     console.log(`Email or Password mismatch: ${email} and ${password}`);
  //     setError(true);
  //   }
  // };
  const handleLogin = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (email === dummyEmail && password === dummyPassword) {
      console.log("Logged in successfully");
      goToHome();
      setIsLoading(false);
    } else {
      console.log(`Email or Password mismatch: ${email} and ${password}`);
      setError(true);
      setIsLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const goToHome = () => {
    console.log("on your way home");
    setNavigate(true);
  };

  // const goToHome = () => {
  //   console.log("on your way home");
  //   setNavigate(true);
  // };

  // // Redirect if needed
  // if (navigate) {
  //   return <Redirect to="/Home" />;
  // }

  useEffect(() => {
    if (navigate) {
      // This is where you would do the redirect.
      // If you're using react-router v5:
      // history.push('/home');
      // If you're using react-router v6:
      // useNavigate('/home');
      console.log("Redirect to home");
    }
  }, [navigate]); // Only re-run the effect if navigate changes

  if (navigate) {
    // The redirect can also be handled here depending on your routing setup
    return <Redirect to="/Home" />;
  }

  return (
    <div className="App column">
      {/* <header className="App-header" style={{ maxHeight:"100% !important"}}> */}
      <div>
        <Box
          className="left"
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <Box
            style={{
              backgroundColor: isDarkMode ? "#423829" : " rgb(160, 147, 129)",
              height: "100vh",
            }}
          >
            <div>
              <div className="jumbotron content">
                <h1>HEALTHCARE MANAGER</h1>
              </div>
              {/* <div className='Entry-background'>
                    <img src="https://www.ngonike.dev/portrait_gabriel.jpg" width="65%" height="400px"    />
                   </div> */}
              <Box
                sx={{
                  maxHeight: "520px",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${waves2})`,
                    backgroundSize: "cover",
                    opacity: 0.3,
                    zIndex: -1,
                  },
                  // Your existing styles here
                  backgroundColor: isDarkMode
                    ? "rgba(18, 18, 18, 0.85)"
                    : "rgba(255, 235, 205, 0.6)",
                  margin: "0px",
                  backdropFilter: "blur(15px)",
                  opacity: "0.95",
                  transition: "background-color 0.3s",
                  justifyContent: "center",
                }}
              >
                <ul
                  className="services-bar"
                  style={{
                    backgroundColor: isDarkMode
                      ? "rgb(18, 18, 18, 0.85)"
                      : "rgb(255, 235, 205, 0.6)",
                    margin: "0px",
                    backdropFilter: "blur(15px)",
                    opacity: "0.95",
                    transition: "background-color 0.3s",
                  }}
                >
                  <li id="services-bar">
                    <img src={allhealth} />
                    <h4>360 Healthcare Management</h4>
                  </li>
                  <li id="services-bar">
                    <img src={tele} />
                    <h4>TeleHealth and modern healthcare</h4>
                  </li>
                  <li id="services-bar">
                    <img src={digi} />
                    <h4> Omnipresent data </h4>
                  </li>
                </ul>
              </Box>
              <div
                className="services-footer"
                style={{
                  backgroundColor: isDarkMode
                    ? "#423829"
                    : " rgb(160, 147, 129)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <h6>
                  Copyright 2024. Admin & HealthCare Manager - Terakota.live{" "}
                  <br /> v1.1. Waves Edition{" "}
                </h6>
              </div>
            </div>
          </Box>
        </Box>

        <Box
          className="right"
          sx={{
            float: "none",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            opacity: "0.8",
            backdropFilter: "blur(15px)",
          }}
        >
          <Box
            className="app-logo"
            sx={{ background: "darkgrey", transform: "scaleX(-1)" }}
          >
            {/* Your logo here */}
            <img
              src={logoDark}
              alt="logo"
              height="230"
              width="200"
              style={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            />
          </Box>

          <Box>
            <h3 style={{ margin: "5px" }}>LOGIN</h3>
          </Box>

          <Box sx={{ maxWidth: "280px" }}>
            {error ? (
              <Alert severity="error">
                Incorrect username or password. Please check your details.
              </Alert>
            ) : (
              <Alert severity="info" sx={{ borderRadius: "8px" }}>
                Use Email: {dummyEmail} / Password: {dummyPassword}
              </Alert>
            )}
            <GlassyTextField
              defaultValue={dummyEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              type="email"
              fullWidth
              margin="normal"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Email address"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem", // Here you can set the font size
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px !important",
                },
                "& .OutlinedInput-root": {
                  borderRadius: "8px !important",
                },
                borderRadius: "8px !important",
              }}
            />
            <GlassyTextField
              type={showPassword ? "text" : "password"}
              size="small"
              defaultValue={dummyPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {showPassword ? (
                      <VisibilityOff
                        onClick={handleClickShowPassword}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <Visibility
                        onClick={handleClickShowPassword}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              placeholder="Password"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem", // Here you can set the font size
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px !important",
                },
                "& .OutlinedInput-root": {
                  borderRadius: "8px !important",
                },
                borderRadius: "8px !important",
              }}
            />
            <Link href="#" sx={{ display: "block", my: 2, fontSize: "12px" }}>
              Forgot password?
            </Link>

            <Box>
              <Button
                fullWidth
                variant="contained"
                sx={{ mb: 2, borderRadius: "8px" }}
                onClick={() => handleLogin()}
                disabled={isLoading}
              >
                {isLoading === true ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  <span> Login </span>
                )}
              </Button>
            </Box>

            <Link href="#/Register" sx={{ display: "block", fontSize: "12px" }}>
              New user? Create an account
            </Link>
          </Box>
        </Box>
      </div>

      {/* </header> */}
    </div>
  );
};

// export with withRouter to get the component to work e.g export default withRouter(yourComponent);
export default withTheme(withRouter(Login));
