import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTheme, Theme } from "@mui/material/styles";
import SearchBar from "../../bars/searchBar";
import { UsersList } from "../../components/users";
import users from "../../_mockData_/users";
import createStyledComponent from "../../components/styled/styledcomponents";

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Users");
// const PREFIX = "Index";

// const classes = {
//   content: `${PREFIX}-content`,
// };

// const Root = styled("div")(({ theme }) => ({
//   [`&.${classes.content}`]: {
//     flexGrow: 1,
//     padding: theme.spacing(1),
//     background:
//       "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
//     minHeight: "100vh",
//   },
// }));

function Index() {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
    // do other search related actions
  };

  return (
    <Root className={classes.content} sx={{ padding: theme.spacing(2) }}>
      <SearchBar
        placeholder="Search something"
        value={searchValue}
        onInputChange={handleSearchInputChange}
        fullWidth={true}
        // style={{ marginTop: "20px" }}
      />
      <UsersList users={users} />
    </Root>
  );
}

export default Index;
