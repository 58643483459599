import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import SearchBar from "../../bars/searchBar";
import { Search as SearchIcon } from "@mui/icons-material";
import AddPatient from "./AddPatient";
import EditPatient from "./EditPatient";
import CustomModal from "../../components/modal.tsx";

const PatientListHead = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    // do other search related actions
  };
  return (
    <Box {...props}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
        }}
      >
        {/* <Button>Import</Button>
      <Button sx={{ mx: 1 }}>Export</Button> */}
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Patient
        </Button>

        <Button color="primary" variant="contained" onClick={handleEdit}>
          Edit Patient
        </Button>
      </Box>
      {/* SearchBox */}
      <SearchBar
        placeholder="Search Patients"
        value={searchValue}
        onInputChange={handleSearchInputChange}
        fullWidth={true}
        // style={{ marginTop: "20px" }}
      />
      <CustomModal open={open} handleClose={handleClose}>
        <AddPatient />
      </CustomModal>
      <CustomModal open={edit} handleClose={handleEditClose}>
        <EditPatient />
      </CustomModal>
    </Box>
  );
};

export default PatientListHead;
