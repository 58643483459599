import React, { useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../../components/modal";
import patients from "../../_mockData_/patients";
import users from "../../_mockData_/users";

interface Doctor {
  id: string;
  name: string;
}

interface Patient {
  id: string;
  name: string;
}

interface Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
  desc: string;
  doctorId?: string;
  patientId?: string;
  type: "Medical" | "Non-Medical";
  patientNames: string[];
}

interface UpdateAppointmentFormProps {
  isOpen: boolean;
  onClose: () => void;
  event: Event;
  onUpdate: (updatedData: any) => void;
  onDelete: (eventId: string) => void;
  doctors?: Doctor[];
  patients?: Patient[];
}

const UpdateAppointmentForm = ({
  isOpen,
  onClose,
  event,
  onUpdate,
  onDelete,
  doctors = [],
  patients = [],
}: UpdateAppointmentFormProps) => {
  const [date, setDate] = useState(event.start);
  const [time, setTime] = useState(event.start.toISOString().slice(11, 16));
  const [appointmentType, setAppointmentType] = useState(event.desc);
  const [title, setTitle] = useState(event.title);
  const [desc, setDesc] = useState(event.desc);
  const [doctor, setDoctor] = useState(event.doctorId || "");
  const [patient, setPatient] = useState(event.patientId || "");
  // const [patientNames, setPatientNames] = useState(event.patientNames.join(', '));

  const handleUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    const updatedAppointment = {
      ...event,
      date,
      time,
      appointmentType,
      doctor,
      patient,
    };
    onUpdate(updatedAppointment);
  };

  return (
    <CustomModal open={isOpen} handleClose={onClose}>
      <Box style={{ padding: "20px" }}>
        <h2
          style={{
            marginTop: "0",
            marginBottom: "20px",
            fontSize: "34px",
            padding: "5px 40px 20px",
          }}
        >
          Edit Appointment
        </h2>
        <FormGroup onSubmit={handleUpdate}>
          <TextField
            label="Date"
            type="date"
            value={date.toISOString().slice(0, 10)}
            onChange={(e) => setDate(new Date(e.target.value))}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Time"
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }} // 5 min intervals
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <TextField
              select
              id="appointment-type-input"
              label="Appointment Type"
              value={appointmentType}
              onChange={(e) => setAppointmentType(e.target.value as string)}
            >
              <MenuItem value="Non-Medical">Non-Medical</MenuItem>
              <MenuItem value="Medical">Medical</MenuItem>
              <MenuItem value="checkup">Scheduled Checkup</MenuItem>
              <MenuItem value="checkup">Unscheduled Checkup</MenuItem>
              <MenuItem value="surgery">Surgery</MenuItem>
              <MenuItem value="consultation">General Consultation</MenuItem>
            </TextField>
          </FormControl>
          <TextField
            label="Title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            type="text"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          <FormControl fullWidth margin="normal">
            <TextField
              select
              id="doctor-input"
              label="Doctor"
              onChange={(e) => setDoctor(e.target.value as string)}
            >
              {doctors.slice(0, 5).map((doctor) => (
                <MenuItem key={doctor.id} value={doctor.id}>
                  {doctor.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              select
              id="patient-input"
              label="Patient"
              onChange={(e) => setPatient(e.target.value as string)}
            >
              {patients.map((pat) => (
                <MenuItem key={pat.id} value={pat.id}>
                  {pat.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <IconButton onClick={() => onDelete(event.id)}>
              {/* <IconButton> */}
              <DeleteIcon />
            </IconButton>
            <Box>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{ marginLeft: "8px" }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </FormGroup>
      </Box>
    </CustomModal>
  );
};

export default UpdateAppointmentForm;
