// users.ts
import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import { mockImgAvatar } from '../utils/mockImages';

// Define a TypeScript type for user data
export type UserData = {
  id: string;
  userRankId: string;
  userRole: string;
  registeredDate: Date;
  avatarUrl: string;
  name: string;
  firstName: string;
  lastName: string;
  contact: string;
  email: string;
  dateOfBirth: Date;
  lastInteraction: Date;
  action: string;
};

const users: UserData[] = [...Array(15)].map((_, index) => ({
  id: faker.datatype.uuid(),
  userRankId: sample([
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10'
  ]) as string,
  userRole: sample([
    'Doctor',
    'Nurse',
    'Cleaner',
    'Clerk',
    'Pharmacist',
    'Manager',
    'Receptionist',
    'Accountant',
    'IT Specialist',
    'Admin'
  ]) as string,
  registeredDate: faker.datatype.datetime() as Date,
  avatarUrl: mockImgAvatar(index + 1),
  name: faker.name.fullName(),
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  contact: faker.phone.number(),
  email: faker.internet.email(), 
  dateOfBirth: faker.date.between('1960-01-01', '2003-12-31') as Date,
  lastInteraction: faker.datatype.datetime() as Date,
  action: sample(['message', 'view more', 'suspend']) as string,
}));

export default users;
