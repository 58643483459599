/**
 * Formats a text string based on the provided options.
 * @param {string} text - The text string to format.
 * @param {number} [length=0] - The length to which the text should be truncated. Default is 0, which means no truncation.
 * @param {Object} [options={}] - Additional options for formatting.
 * @param {boolean} [options.capitalize=false] - Whether to capitalize the first letter of the text. Default is false.
 * @param {boolean} [options.uppercase=false] - Whether to convert the entire text to uppercase. Default is false.
 * @param {boolean} [options.lowercase=false] - Whether to convert the entire text to lowercase. Default is false.
 * @returns {string} The formatted text.
 */
export function fText(text, length = 0, options = {}) {
  let formattedText = text;

  // Truncate the text to the desired length
  if (length > 0 && text.length > length) {
    formattedText = text.substring(0, length);
  }

  // Apply formatting options
  if (options.capitalize) {
    formattedText =
      formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
  }

  if (options.uppercase) {
    formattedText = formattedText.toUpperCase();
  }

  if (options.lowercase) {
    formattedText = formattedText.toLowerCase();
  }

  return formattedText;
}

// Example Usage:
// console.log(fText("lonny", 2, { capitalize: true })); // Outputs: "Lo"
