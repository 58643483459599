import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

const LiveButton = () => {
  const [mode, setMode] = useState("offline");
  const [color, setColor] = useState("#FF0000");
  const [opacity, setOpacity] = useState(0.5);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const checkInternetConnection = () => {
    const isConnected = navigator.onLine;

    if (isConnected) {
      setMode("online");
      setColor("#00FF00");
    } else {
      setMode("offline");
      setColor("#FF0000");
    }
  };

  useEffect(() => {
    checkInternetConnection();

    const updateOnlineStatus = () => {
      checkInternetConnection();
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const handleMouseEnter = () => {
    setTooltipOpen(true);
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
    setOpacity(0.5);
  };

  const handleClick = () => {
    setOpacity(0.5);
    setTimeout(() => {
      setOpacity(1);
    }, 15000);
  };

  const tooltipText =
    mode === "online" ? "Online | Terakota v1.1" : "Offline | Terakota v1.1";

  return (
    <Tooltip title={tooltipText} arrow open={tooltipOpen} placement="top">
      <div
        style={{
          width: "6px",
          height: "6px",
          // marginTop: "22px",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "50%",
          backgroundColor: color,
          opacity: opacity,
          cursor: "pointer",
          transition: "opacity 1s ease-in-out",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      ></div>
    </Tooltip>
  );
};

export default LiveButton;

// import React, { useEffect, useState } from "react";

// const LiveButton = () => {
//   const [mode, setMode] = useState("offline");
//   const [color, setColor] = useState("#FF0000");

//   useEffect(() => {
//     const checkInternetConnection = () => {
//       // Simulate checking for internet connection
//       const isConnected = Math.random() > 0.5;
//       const isDbConnected = Math.random() > 0.5;

//       if (isConnected && isDbConnected) {
//         setMode("online");
//         setColor("#00FF00");
//       } else if (isConnected && !isDbConnected) {
//         setMode("offline");
//         setColor("#FFFF00");
//       } else {
//         setMode("disconnected");
//         setColor("#FF0000");
//       }
//     };

//     // Check for internet connection every 2 seconds
//     const interval = setInterval(checkInternetConnection, 20000);

//     // Clear interval on unmount
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div
//       style={{
//         width: "6px",
//         height: "6px",
//         marginTop: "22px",
//         marginLeft: "5px",
//         marginRight: "5px",
//         borderRadius: "50%",
//         backgroundColor: color,
//         transition: "opacity 1s ease-in-out",
//       }}
//     >
//       <span
//         style={{
//           opacity: 0,
//           position: "absolute",
//           fontSize: "12px",
//           color: "#FFFFFF",
//           backgroundColor: "#000000",
//           padding: "5px",
//           borderRadius: "5px",
//           transition: "opacity 0.5s ease-in-out",
//         }}
//       >
//         {mode}
//       </span>
//     </div>
//   );
// };

// export default LiveButton;
