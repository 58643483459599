import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Edit,
  Delete,
  Visibility,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";

const InventoryList = () => {
  // Replace 'transactions' with your actual data.

  const transactions = [
    {
      invoiceNumber: 300500,
      status: "Available",
      subscription: "Amoxicillin",
      price: "799,00",
      issueDate: moment().subtract(1, "days").format("DD MMM YYYY"),
      dueDate: moment()
        .subtract(1, "days")
        .add(1, "month")
        .format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300499,
      status: "Available",
      subscription: "Levothyroxine",
      price: "799,00",
      issueDate: moment().subtract(2, "days").format("DD MMM YYYY"),
      dueDate: moment()
        .subtract(2, "days")
        .add(1, "month")
        .format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300498,
      status: "Available",
      subscription: "Lisinopril",
      price: "799,00",
      issueDate: moment().subtract(2, "days").format("DD MMM YYYY"),
      dueDate: moment()
        .subtract(2, "days")
        .add(1, "month")
        .format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300497,
      status: "Available",
      subscription: "AlbuterolHFA",
      price: "233,42",
      issueDate: moment().subtract(3, "days").format("DD MMM YYYY"),
      dueDate: moment()
        .subtract(3, "days")
        .add(1, "month")
        .format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300496,
      status: "Low",
      subscription: "Gabapentin",
      price: "533,42",
      issueDate: moment()
        .subtract(1, "day")
        .subtract(1, "month")
        .format("DD MMM YYYY"),
      dueDate: moment().subtract(1, "day").format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300495,
      status: "Low",
      subscription: "Cyclobenzaprine",
      price: "533,42",
      issueDate: moment()
        .subtract(3, "days")
        .subtract(1, "month")
        .format("DD MMM YYYY"),
      dueDate: moment().subtract(3, "days").format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300494,
      status: "Low",
      subscription: "Atorvastatin",
      price: "233,42",
      issueDate: moment()
        .subtract(4, "days")
        .subtract(1, "month")
        .format("DD MMM YYYY"),
      dueDate: moment().subtract(4, "days").format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300493,
      status: "Finished",
      subscription: "Cyclobenzaprine",
      price: "533,42",
      issueDate: moment()
        .subtract(20, "days")
        .subtract(1, "month")
        .format("DD MMM YYYY"),
      dueDate: moment().subtract(20, "days").format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300492,
      status: "Finished",
      subscription: "Benzonatate",
      price: "799,00",
      issueDate: moment().subtract(2, "months").format("DD MMM YYYY"),
      dueDate: moment().subtract(3, "months").format("DD MMM YYYY"),
    },
    {
      invoiceNumber: 300491,
      status: "Available",
      subscription: "Levothyroxine",
      price: "799,00",
      issueDate: moment().subtract(6, "days").format("DD MMM YYYY"),
      dueDate: moment()
        .subtract(6, "days")
        .add(1, "month")
        .format("DD MMM YYYY"),
    },
  ];

  return (
    <Card elevation={3} sx={{ border: "light", marginBottom: 4 }}>
      {/* <CardHeader
        title="Transactions"
        sx={{ background: (theme) => theme.palette.primary.main }}
      /> */}
      <CardContent sx={{ paddingTop: 0 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Drug Name</TableCell>
                <TableCell>Last Purchase</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Purchase Price</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow key={`transaction-${transaction.invoiceNumber}`}>
                  <TableCell>
                    <Link
                      //   to={Routes.Invoice.path}
                      to={"/Home"}
                      className="fw-normal"
                      style={{ textDecoration: "none" }}
                    >
                      {transaction.invoiceNumber}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" className="fw-normal">
                      {transaction.subscription}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" className="fw-normal">
                      {transaction.issueDate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" className="fw-normal">
                      {transaction.dueDate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" className="fw-normal">
                      ${parseFloat(transaction.price).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      className={`fw-normal text-${transaction.status}`}
                    >
                      {transaction.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      aria-label="View Details"
                      color="primary"
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton size="small" aria-label="Edit" color="primary">
                      <Edit />
                    </IconButton>
                    <IconButton size="small" aria-label="Delete" color="error">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box>
            <ButtonGroup>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ChevronLeft />}
                size="small"
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<ChevronRight />}
                size="small"
              >
                Next
              </Button>
            </ButtonGroup>
          </Box>
          <Typography variant="subtitle2" fontWeight="bold">
            Showing <b>{transactions.length}</b> out of <b>25</b> entries
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InventoryList;
