import { faker } from "@faker-js/faker";
import { randomInt } from "crypto";
import { random } from "lodash";
// utils
import { mockImgTest } from '../utils/mockImages';

// ----------------------------------------------------------------------
export type LabTest = {
    id: string;
    cover: string;
    title: string;
    testType: string;
    patientName: string;
    testResult: string;
    createdAt: Date;
    description: string;
    view: number;
    patientID: string;
    labTechnician: {
      name: any;
      avatarUrl: string;
    };
    comment: number;
    share: number;
    favorite: number;
  };

const TEST_TITLES = [
  'Complete Blood Count (CBC)',
  'Chemistry Panel',
  'Lipid Panel',
  'Thyroid Stimulating Hormone (TSH) Test',
  'Hemoglobin A1c Test',
  'Urinalysis',
  'Electrocardiogram (ECG or EKG)',
  'MRI Scan',
  'XRay Imaging',
  'Pregnancy Test',
  'Blood Type Test',
  'Liver Function Test',
  'Cancer Antigen 125 (CA-125) Test',
  'HIV Test',
  'Stool Culture',
  'C-Reactive Protein (CRP) Test',
  'Blood Glucose Test',
  'Coagulation Panel',
  'Thyroid Function',
  'Urine Culture',
  'Allergy Tests',
  'Genetic Testing',
  'COVID-19 PCR Test'
];

// Helper function to format test titles into file-friendly names
const formatTestNameForFile = (testName: string) => {
  // Define a mapping for special cases
  const specialCases: any = {
    'C-Reactive Protein (CRP) Test': 'Creactive_Protein_CRP_Test',
    'Cancer Antigen 125 (CA-125) Test': 'Cancer_Antigen_125',
    'Thyroid Stimulating Hormone (TSH) Test': 'Thyroid_Stimulating_Hormone',
    'Electrocardiogram (ECG or EKG)': 'ECG',
    'Complete Blood Count (CBC)': 'Complete_Blood_Count',
    'MRI Scan': 'MRI',
    'COVID-19 PCR Test': 'Covid19_Pcr',
    // Add other special cases here
  };

  // Check if the testName is in the special cases and return the formatted name
  if (specialCases[testName]) {
    return specialCases[testName];
  }

  // For general cases, replace spaces with underscores and remove parentheses
  return testName.replace(/\s+/g, '_').replace(/[()]/g, '');
};

// Define a type for the color mapping
type ColorMap = { [colorName: string]: string };

const createColorAvatar = (color: string) => {
  const size = 100; // Size of the avatar image
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx:any = canvas.getContext('2d');
  
  if (ctx === null) {
    throw new Error('Could not create canvas context');
  }
 // Darker shades of colors
 const colors: ColorMap = {
  red: '#8B0000', // Dark red
  blue: '#00008B', // Dark blue
  yellow: '#CCCC00', // Darker yellow
  green: '#006400', // Dark green
  white: '#808080' // Grey (dark shade of white)
};

const darkColor = colors[color] || color; // Fallback to original color if not found


  ctx.fillStyle = darkColor;
  ctx.fillRect(0, 0, size, size);
  
  return canvas.toDataURL(); // Returns a base64 encoded image
};


const labTests: LabTest[] = [...Array(23)].map((_, index) => {
  const setIndex = index + 1;
  const testName = formatTestNameForFile(TEST_TITLES[index]);
  return {
// const labTests = (index: number): LabTest => ({
  id: faker.datatype.uuid(),
  // cover: faker.image.imageUrl(1234, 2345, 'Syringe', true),  // Clinical-Cytology-Pathology-microbes-histology-microscopic-syringe Generate a cover URL using Faker
  cover: mockImgTest(setIndex, testName),
  title: TEST_TITLES[index],
  testType: faker.helpers.arrayElement(['Blood Test', 'Imaging', 'Diagnostic', 'Genetic']),
  description: faker.lorem.sentence(),
  patientName: faker.name.fullName(),
  patientID: faker.datatype.uuid(),
  testResult: faker.helpers.arrayElement(['Normal', 'Abnormal']),
  createdAt: faker.date.past(),
  view: faker.datatype.number(),
  results: {
    value: faker.datatype.number(),
    // unit: faker.random.arrayElement(['mg/dL', 'mmol/L', 'µg/mL', 'IU/mL']),
    referenceRange: faker.lorem.sentence()
  },
  labTechnician: {
    name: faker.name.fullName(),
    // avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`
    avatarUrl: createColorAvatar(faker.helpers.arrayElement(['red', 'blue', 'yellow', 'green', 'white']))
  },
  comment: faker.datatype.number(),
  share: faker.datatype.number(),
  favorite: faker.datatype.number(),
};
});

export default labTests;


