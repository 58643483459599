// styledComponents.js
// import { styled } from "@mui/material/styles";

// const generateStyles = (prefix, classStyles) => {
//   const dynamicStyles = {};

//   classStyles.forEach(([className, styles]) => {
//     dynamicStyles[`${prefix ? `.${prefix}-` : "."}${className}`] = styles;
//   });

//   return dynamicStyles;
// };

// const createStyledComponent = (prefix) => {
//   const classes = {
//     content: "content",
//     tabs: "tabs",
//     flexContainer: "flexContainer",
//     // Add more classes as needed
//   };

//   const Root = styled("div")(({ theme }) => ({
//     ...generateStyles(prefix, [
//       [
//         classes.content,
//         {
//           flexGrow: 1,
//           padding: theme.spacing(1),
//           background:
//             theme.palette.mode === "dark"
//               ? "radial-gradient(circle, #1a1a1a 0%, #121212 100%)"
//               : "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
//           minHeight: "100vh",
//         },
//       ],
//       [
//         classes.tabs,
//         {
//           justifyContent: "flex-end",
//           borderRadius: "5px",
//           alignItems: "right",
//           background: "rgba(154, 109, 80, 0.1)",
//           boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
//           backdropFilter: "blur(5px)",
//           padding: "2px",
//           marginTop: "10px",
//           marginBottom: "10px",
//           border: "none",
//         },
//       ],
//       [
//         classes.flexContainer,
//         {
//           display: "flex",
//           justifyContent: "flex-end",
//         },
//       ],
//       // Add more classes and styles as needed
//     ]),
//   }));

//   return { Root, classes };
// };

// export default createStyledComponent;

// // styledComponents.js
import { styled } from "@mui/system";
// import { isElectron } from "../../electron/electronUtils";
const { isElectron } = require("../../electron/electronUtils");

const generateStyles = (prefix, classStyles) => {
  const dynamicStyles = {};

  classStyles.forEach(([className, styles]) => {
    dynamicStyles[`${prefix ? `.${prefix}-` : "."}${className}`] = styles;
  });

  return dynamicStyles;
};

const isRunningElectron = isElectron();

const createStyledComponent = (prefix) => {
  const classes = {
    content: "content",
    tabs: "tabs",
    flexContainer: "flexContainer",
    tabPanel: "tabPanel",
    // Add more classes as needed
  };

  const Root = styled("div")(({ theme }) => ({
    marginTop: isRunningElectron ? "24px" : "0px",
    // [`${prefix ? `.${prefix}-` : "."}${classes.content}`]: {
    //   flexGrow: 1,
    //   padding: theme.spacing(1),
    //   background:
    //     // "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
    //     theme.palette.mode === "dark"
    //       ? "radial-gradient(circle, #1a1a1a 0%, #121212 100%)"
    //       : "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
    //   minHeight: "100vh",
    // },
    [`&.${classes.content}`]: {
      // display: "flex",
      flexGrow: 1,
      padding: theme.spacing(2),
      background:
        theme.palette.mode === "dark"
          ? "radial-gradient(circle, #1a1a1a 0%, #121212 100%)"
          : " radial-gradient(circle, rgba(248, 245, 241, 1) 3%, rgba(250, 248, 246, 1) 53%, rgba(255, 255, 255, 1) 100%)",
      // : "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
      // height: "100vh",

      minHeight: "100vh",
      height: "100%",
    },
    [`& .${classes.tabs}`]: {
      // background:
      //   "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
      justifyContent: "flex-end",
      borderRadius: "5px",
      alignItems: "right",
      background: "rgba(0, 0, 0, 0.4)",
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
      backdropFilter: "blur(15px)",
      padding: "2px",
      marginTop: "10px",
      marginBottom: "10px",
      border: "none",
    },

    [`& .${classes.flexContainer}`]: {
      display: "flex",
      justifyContent: "flex-end",
    },

    [`& .${classes.tabPanel}`]: {
      // justifyContent: "flex-end !important",
      backdropFilter: "blur(5px)",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      borderRadius: "10px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      margin: "20px 0px",
    },
    // ...generateStyles(prefix, [
    //   [
    //     classes.tabs,
    //     {
    //       // background:
    //       //   "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
    //       justifyContent: "flex-end",
    //       borderRadius: "5px",
    //       alignItems: "right",
    //       background: "rgba(154, 109, 80, 0.1)",
    //       boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
    //       backdropFilter: "blur(5px)",
    //       padding: "2px",
    //       marginTop: "10px",
    //       marginBottom: "10px",
    //       border: "none",
    //     },
    //   ],
    //   [
    //     classes.flexContainer,
    //     {
    //       display: "flex",
    //       justifyContent: "flex-end",
    //     },
    //   ],
    //   [
    //     classes.tabPanel,
    //     {
    //       // justifyContent: "flex-end !important",
    //       backdropFilter: "blur(5px)",
    //       backgroundColor: "rgba(255, 255, 255, 0.5)",
    //       borderRadius: "10px",
    //       boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    //       margin: "20px 0px",
    //     },
    //   ],
    //   // [
    //   //   classes.content,
    //   //   {
    //   //     flexGrow: 1,
    //   //     padding: theme.spacing(1),
    //   //     background:
    //   //       "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
    //   //     // theme.palette.mode === "dark"
    //   //     //   ? "radial-gradient(circle, #1a1a1a 0%, #121212 100%)"
    //   //     //   : "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
    //   //     minHeight: "100vh",
    //   //   },
    //   // ],
    //   //   [classes.flexContainer, {
    //   //     display: "flex",
    //   //     justifyContent: "flex-end",
    //   //   }],
    //   // Add more classes and styles as needed
    // ]),
  }));

  return { Root, classes };
};

export default createStyledComponent;
