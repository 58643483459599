import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Avatar,
  TextField,
  Grid,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { HashRouter, Link } from "react-router-dom";
import TerakotaLogo from "../res/icons/logo512.png";
import LockImage from "../res/images/LockScreen.png";
import AvatarDefault from "../res/images/avatar_default.jpg";
import { color } from "@mui/system";

// Define styles for the floating image
const FloatingImage = styled("img")(({ theme }) => ({
  "@keyframes float": {
    "0%": {
      transform: "translateY(0px)",
    },
    "50%": {
      transform: "translateY(-20px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
  display: "flex",
  width: "130%",
  height: "auto",
  animation: "float 3s ease-in-out infinite",
  border: "none",
  // boxShadow: "0 7px 5px -5px rgba(0, 0, 0, 0.3)",
  paddingBottom: "130px",
  textShadow: "2px 2px 5px #fff, 0px 0px 30px white,0px 0px 30px white",
  filter:
    theme.palette.mode === "light"
      ? "drop-shadow(1px 1px 12px rgb(255, 235, 205, 1))"
      : "drop-shadow(5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1))",
  // Define your floating animation keyframes here
}));

const LockScreen = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      style={{
        textAlign: "center",
        backgroundColor: isDarkMode ? "#423829" : "rgb(160, 147, 129)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Logo */}
      <Box sx={{ paddingBottom: "16px" }}>
        {" "}
        <img
          src={TerakotaLogo}
          width={70}
          height={70}
          alt="Terakota Logo"
          style={{
            borderRadius: "50%",
            padding: "7px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 6px 30px",
            filter:
              theme.palette.mode === "light"
                ? "drop-shadow(1px 1px 12px rgb(255, 235, 205, 1))"
                : "drop-shadow(5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1))",
            textShadow:
              "2px 2px 5px #fff, 0px 0px 30px white,0px 0px 30px white",
            border: "none",
          }}
        />
      </Box>

      <Box
        style={{
          textAlign: "center",
          backgroundColor: isDarkMode ? "#423829" : "rgb(160, 147, 129)",
          // minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {/* Floating Image */}
        <FloatingImage src={LockImage} alt="Lock Screen Image" />

        {/* Paper Box */}
        <Paper
          sx={{
            position: "absolute",
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: isDarkMode
              ? "rgba(0, 0, 0, 0.5)"
              : "rgba(250, 249, 246, 0.5)",
            color: isDarkMode ? "white" : "black",
            marginTop: "-140px", // Adjust as needed
            borderRadius: "16px",
            backdropFilter: "blur(15px)", // Apply blur effect
            // border: '1px solid', // Optional: adds a border for better glass effect
            border: "none",
            boxShadow: "0 7px 5px -5px rgba(0, 0, 0, 0.3)",
            borderColor: isDarkMode
              ? "rgba(255, 255, 255, 0.2)"
              : "rgba(0, 0, 0, 0.2)",
            opacity: "0.95",
            transition: "background-color 0.3s",
          }}
        >
          <Avatar src={AvatarDefault} sx={{ width: 56, height: 56, mb: 2 }} />
          <Typography variant="h6" sx={{ mb: 4 }}>
            Gabe Oni
          </Typography>
          {/* <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Better to be safe than sorry.
          </Typography> */}

          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              {/* <Typography>Your Password</Typography> */}
              <TextField
                type="password"
                placeholder="Password"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Button variant="contained" color="primary">
                      Unlock
                    </Button>
                  ),
                }}
              />
            </Grid>
            <Typography
              variant="body2"
              sx={{
                textDecoration: "underline",
                textDecorationThickness: "0.5px",
                textUnderlineOffset: "3px",
                paddingTop: "6px",
                fontSize: "12px",
                opacity: "0.5",
              }}
            >
              <Link
                to="/"
                style={{
                  color: isDarkMode ? "white" : "black",
                }}
              >
                ← Return Home
              </Link>
            </Typography>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default LockScreen;
