import React, { useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Box,
  Typography,
  Avatar,
  TablePagination,
} from "@mui/material";

// Sample data with more variety
const admittedPatients = [
  {
    id: 1,
    roomNumber: "101",
    roomId: 1,
    roomName: "Cardiology",
    patientInfo: "John Doe",
    admissionDate: "2023-10-01",
    ailment: "Heart Disease",
    doctorInfo: "Dr. Smith",
    roomAvailability: true,
    assignedPersonnel: "Nurse Jane",
  },
  {
    id: 2,
    roomNumber: "102",
    roomId: 1,
    roomName: "Cardiology",
    patientInfo: "John Doe",
    admissionDate: "2023-10-01",
    ailment: "Heart Disease",
    doctorInfo: "Dr. Smith",
    roomAvailability: true,
    assignedPersonnel: "Nurse Jane",
  },
  {
    id: 3,
    roomNumber: "103",
    roomId: 1,
    roomName: "Cardiology",
    patientInfo: "John Doe",
    admissionDate: "2023-10-01",
    ailment: "Heart Disease",
    doctorInfo: "Dr. Smith",
    roomAvailability: true,
    assignedPersonnel: "Nurse Jane",
  },
  {
    id: 4,
    roomNumber: "104",
    roomId: 1,
    roomName: "Cardiology",
    patientInfo: "John Doe",
    admissionDate: "2023-10-01",
    ailment: "Heart Disease",
    doctorInfo: "Dr. Smith",
    roomAvailability: true,
    assignedPersonnel: "Nurse Jane",
  },
  // Add more admitted patients with various details
];

const AdmittedPatientsTable = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [selectedPatientIds, setSelectedPatientIds] = useState([]);

  const handleSelectAll = (event) => {
    const selected =
      event.target.checked && admittedPatients.length > 0
        ? admittedPatients.map((patient) => patient.id)
        : [];
    setSelectedPatientIds(selected);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedPatientIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPatientIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPatientIds.slice(1));
    } else if (selectedIndex === selectedPatientIds.length - 1) {
      newSelected = newSelected.concat(selectedPatientIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPatientIds.slice(0, selectedIndex),
        selectedPatientIds.slice(selectedIndex + 1)
      );
    }

    setSelectedPatientIds(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" style={{ width: 50 }}>
              <Checkbox
                checked={selectedPatientIds.length === admittedPatients.length}
                color="primary"
                indeterminate={
                  selectedPatientIds.length > 0 &&
                  selectedPatientIds.length < admittedPatients.length
                }
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>Room Number</TableCell>
            <TableCell>Room ID</TableCell>
            <TableCell>Room Name</TableCell>
            <TableCell>Patient Info</TableCell>
            <TableCell>Admission Date</TableCell>
            <TableCell>Ailment</TableCell>
            <TableCell>Doctor Info</TableCell>
            <TableCell>Room Availability</TableCell>
            <TableCell>Assigned Personnel</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admittedPatients
            .slice(page * limit, page * limit + limit)
            .map((patient) => (
              <TableRow
                hover
                key={patient.id}
                selected={selectedPatientIds.indexOf(patient.id) !== -1}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPatientIds.indexOf(patient.id) !== -1}
                    onChange={(event) => handleSelectOne(event, patient.id)}
                    value="true"
                  />
                </TableCell>
                <TableCell>{patient.roomNumber}</TableCell>
                <TableCell>{patient.roomId}</TableCell>
                <TableCell>{patient.roomName}</TableCell>
                <TableCell>{patient.patientInfo}</TableCell>
                <TableCell>{patient.admissionDate}</TableCell>
                <TableCell>{patient.ailment}</TableCell>
                <TableCell>{patient.doctorInfo}</TableCell>
                <TableCell>
                  {patient.roomAvailability ? "Available" : "Occupied"}
                </TableCell>
                <TableCell>{patient.assignedPersonnel}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={admittedPatients.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default AdmittedPatientsTable;
