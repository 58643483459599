import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  Button,
  Link as Mlink,
  // Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Avatar,
  MenuItem,
  Menu,
  MenuList,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  Popover,
  Popper,
  Paper,
  Fab,
  Fade,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// import Drawer from "@mui/material/Drawer";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { ListItemProps } from "@mui/material/ListItem"; //imported to get ListItem redirect to work - see ReadME for link tutorial
import {
  Link,
  useHistory,
  useLocation,
  HashRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
//import MailIcon from '@mui/icons-material/Mail';  -- this is a sample of how i imported one by one before seeing the light as below
import {
  Menu as MenuIcon,
  Mail as MailIcon,
  MoveToInbox as InboxIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  LocalPharmacy as LocalPharmacyIcon,
  LocalHospital as LocalHospitalIcon,
  Event as EventIcon,
  People as PeopleIcon,
  Settings as SettingIcon,
  Forum as ForumIcon,
  NoteAdd as NoteAddIcon,
  ExitToApp as ExitToAppIcon,
  Dashboard as DashboardIcon,
  Lock as LockIcon,
  Notifications as NotificationsIcon,
  Tune as TuneIcon,
  Biotech,
  ExpandLess,
  ExpandMore,
  ArrowDropDown,
  Segment,
} from "@mui/icons-material";
import { Icon } from "@iconify/react";
//import { red } from '@mui/material/colors';
//import { white } from 'material-ui/styles/colors';
import LiveButton from "../components/liveButton";
import SlideBar from "./slideBar";
// import Routes from "../routes";
// import Home from "../pages/Home";
// import Appointments from "../pages/appointments/index";
// import Patients ...
// import { goToHome } from "../components/login.tsx";
import { useTranslation } from "react-i18next";
import { ReactComponent as AIBotIcon } from "../res/icons/AI-icon.svg";
import { ReactComponent as SearchIcon } from "../res/icons/search.svg";
import { ReactComponent as SearchIconMac } from "../res/icons/search-mac.svg";
import { ReactComponent as SearchIconWindows } from "../res/icons/search-windows.svg";
import TerakotaLogo from "../res/icons/logo512.png";
import CustomModal from "../components/modal";
import SearchModal from "../components/utility/SearchModal";
import { showToast } from "../utils/showToast";
// import {
//   isElectron,
//   getPlatform,
//   openNewWindow,
//   openDocumentationWindow,
// } from "../electron/electronUtils";
import InfoAvatar from "../components/utility/InfoAvatar";
import ViewProfileWidget from "../components/utility/ViewProfileWidget";
// import { openToastNotification } from "../electron/electronUtils";
const {
  isElectron,
  getPlatform,
  openNewWindow,
  openDocumentationWindow,
} = require("../electron/electronUtils");

const PREFIX = "sideBar";
// const { BrowserWindow } = require("electron");
// const path = require("path");
const isDevelopment = process.env.NODE_ENV !== "production";
const isRunningInElectron = isElectron();
const myPlatform = getPlatform();

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  content: `${PREFIX}-content`,
  linkTitle: `${PREFIX}-linkTitle`,
  settingsButton: `${PREFIX}-settingsButton`,
};

const drawerWidth = 240;

const items = [
  {
    id: 0,
    // href: "/pages/patient/index",
    href: "/Patients",
    icon: <LocalHospitalIcon />,
    page: `#Patients`,
    title: "Patients",
    active: false,
  },
  {
    id: 1,
    // href: <Redirect to="/Appointments" />,
    href: "/Appointments",
    icon: <EventIcon />,
    page: `#Appointments`,
    title: "Appointments",
    active: false,
  },
  {
    id: 2,
    href: "/Pharmacy",
    icon: <LocalPharmacyIcon />,
    page: `#Pharmacy`,
    title: "Pharmacy",
    active: false,
  },
  {
    id: 3,
    // href: "/app/labs",
    href: "/Labs",
    icon: <Biotech />,
    page: `#Labs`,
    title: "Lab",
    active: false,
  },
  {
    id: 4,
    // href: "/app/account",
    href: "/Users",
    icon: <PeopleIcon />,
    page: `#Users`,
    title: "User",
    active: false,
  },
  {
    id: 5,
    // href: "/login",
    href: "/Chat",
    icon: <ForumIcon />,
    page: `#Chat`,
    title: "Consult",
    active: false,
  },
  {
    id: 6,
    href: "/Accounts",
    icon: <NoteAddIcon />,
    page: `#Accounts`,
    title: "Register",
    active: false,
  },
  {
    id: 7,
    href: "/others",
    icon: <Segment />,
    page: "#Others",
    title: "Infomanual",
    active: false,
    children: [
      {
        id: "7-1",
        href: "#lock",
        title: "Lock Screen",
        active: false,
      },
      {
        id: "7-2",
        href: "#register",
        title: "Register Page",
        active: false,
      },
      {
        id: "7-3",
        href: "#404",
        title: "404 Page",
        active: false,
      },
      {
        id: "7-4",
        href: "#500",
        title: "500 Page",
        active: false,
      },
      {
        id: "7-5",
        href: "#",
        title: "Landing Page",
        active: false,
      },
      {
        id: "7-6",
        // href: "/documentation",
        title: "Documentation",
        active: false,
        children: [
          {
            id: "7-6-1",
            href: "/docs/category/overview",
            title: "Overview",
            active: false,
          },
          {
            id: "7-6-2",
            href: "/docs/category/download",
            title: "Download",
            active: false,
          },
          {
            id: "7-6-3",
            href: "/docs/intro",
            title: "Quickstart",
            active: false,
          },
          {
            id: "7-6-4",
            href: "/docs/category/licenses",
            title: "License",
            active: false,
          },
          {
            id: "7-6-5",
            href: "/docs/category/overview",
            title: "Folder Structure",
            active: false,
          },
          {
            id: "7-6-4",
            href: "/docs/overview/deployment",
            title: "Buildtools",
            active: false,
          },
          {
            id: "7-6-4",
            href: "/docs/support/changelog",
            title: "Changelogs",
            active: false,
          },
        ],
      },
    ],
  },
  {
    id: 8,
    // href: "/app/settings",
    href: "/Settings",
    icon: <SettingIcon />,
    page: `#Settings`,
    title: "Settings",
    active: false,
  },
];

//the fn below assist in link redirect for listItems... see link tutorial
function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const Root = styled("div")(({ theme }) => {
  const appBarShift =
    theme.direction === "rtl"
      ? {
          marginRight: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          // Rest of the RTL styles
        }
      : {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          // Rest of the LTR styles
        };

  return {
    [`&.${classes.root}`]: {
      display: "flex",
    },

    [`& .${classes.appBar}`]: {
      marginTop: isRunningInElectron ? "24px" : "0px",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // Initial: backgroundColor: theme.palette.mode === "dark" ? "#423829" : "#a09381",
      // others considered for the appbar dark: #e4b865 , #e4d2c6 , #e8d3c5, #D3B8A6, #cab296, #e0c5a5
      backgroundColor: theme.palette.mode === "dark" ? "#423829" : "#b39d84",
      [theme.breakpoints.up("sm")]: {
        width: `100%`,
        marginLeft: drawerWidth,
        ...(theme.direction === "rtl"
          ? {
              marginRight: "0",
              marginLeft: "auto",
            }
          : {}),
      },
    },

    [`& .${classes.appBarShift}`]: {
      marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`, //cannot have two width
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...(theme.direction === "rtl"
        ? appBarShift
        : {
            // LTR styles
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            // width: `100%`,
          }),
    },

    [`& .${classes.menuButton}`]: {
      marginRight: 36,
    },

    [`& .${classes.settingsButton}`]: {
      right: theme.direction === "rtl" ? "95%" : 24,
      position: "fixed",
      bottom: 16,
      // margin: "32px",
      borderRadius: "50%",
      boxShadow: "rgba(0, 0, 0, 0.08) 0px 6px 30px",
    },

    [`& .${classes.hide}`]: {
      display: "none",
    },

    // [`& .${classes.drawer}`]: {
    //   width: drawerWidth,
    //   flexShrink: 0,
    //   whiteSpace: "nowrap",
    // },
    // [`& .${classes.drawerOpen}`]: {
    //   width: drawerWidth,
    //   transition: theme.transitions.create("width", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   backgroundColor: "#ffebcd",
    // },

    // [`& .${classes.drawerClose}`]: {
    //   transition: theme.transitions.create("width", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    //   overflowX: "hidden",
    //   width: theme.spacing(7) + 1,
    //   [theme.breakpoints.up("sm")]: {
    //     width: theme.spacing(9) + 1,
    //   },
    //   backgroundColor: "#ffebcd",
    // },

    [`& .${classes.toolbar}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },

    [`& .${classes.toolbarIcon}`]: {
      display: "inline-flex",
      flex: "auto",
      alignItems: "center",
      justifyContent: "flex-start !important",
      paddingLeft: theme.spacing(3),
      borderRadius: "1px",
      // fontSize: "10px",
    },

    [`& .${classes.content}`]: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },

    [`& .${classes.linkTitle}`]: {
      textDecoration: "none",
      color: "inherit",
      display: "inline-flex",
      alignItems: "center",
      "&:hover": {
        textDecoration: "none",
        color: "inherit",
      },
      "&:focus": {
        textDecoration: "none",
      },
      "&:visited": {
        textDecoration: "none",
      },
      "&:active": {
        textDecoration: "none",
      },
      "&:hover, &:focus, &:active": {
        outline: "none",
      },
    },
    // nbelow controls the styling for active href of ListItem in the Drawer
    "& .selectedItem": {
      backgroundColor: theme.palette.action.selected,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.main,
      },
    },
  };
});

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.mode === "dark" ? "#63553e" : "#ffebcd",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  minWidth: "88px",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.mode === "dark" ? "#63553e" : "#ffebcd",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// interface AppBarProps extends MuiAppBarProps {
//   open?: boolean;
// }

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })<AppBarProps>(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  paddingTop: isRunningInElectron ? "24px" : "0px",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      paddingTop: isRunningInElectron ? "24px" : "0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      paddingTop: isRunningInElectron ? "24px" : "0px",
    },
  }),
}));

// export default function MiniDrawer(props: typeof items) - consider this for typescript
export default function MiniDrawer(props: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [navigate, setNavigate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  // for background color of logo to change based on route
  const backgroundColor =
    location.pathname === "/Home" ? `${theme.palette.primary.main}31` : "";

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAccountMenu = (e: any) => {
    setAnchorEl(e.currentTarget);
    // setAnchorEl(currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const [seeProfile, setSeeProfile] = useState(false);

  const handleViewProfile = () => {
    setSeeProfile(true);
  };
  const handleHideProfile = () => {
    setSeeProfile(false);
  };

  const handleLockClick = () => {
    history.push("/Lock");
  };
  // const returnItem = () => {
  //   items.map(item => {
  //     return <Redirect to='/Appointments' />;
  //  }
  // };
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);

  const openSearchModal = () => setSearchModalOpen(true);
  const closeSearchModal = () => setSearchModalOpen(false);

  const [slideState, setSlideState] = useState({
    isOpen: false,
    component: "",
  });

  // Unified function to open SlideBar with a specified component
  const openSlideBar = (component: string) => {
    setSlideState({ isOpen: true, component });
  };

  // Unified function to close SlideBar
  const closeSlideBar = () => {
    setSlideState({ isOpen: false, component: "" });
  };

  const [openothers, setOpenothers] = React.useState(false);
  const [selected, setSelected] = React.useState<string | null>(null);
  const [openSubList, setOpenSubList] = React.useState<{
    [key: string]: boolean;
  }>({});

  const handleListItemClick = (id: string, href?: string) => {
    // if (href) {
    //   // Navigate to the href
    //   window.location.href = href; // Or use your router's navigation method
    // } else {
    //   // Toggle the clicked item and close siblings
    //   setSelected((prev) => (prev === id ? null : id));
    //   setOpenSubList((prev) => ({
    //     ...prev,
    //     [id]: !prev[id],
    //   }));
    // }
    if (!href) {
      // Toggle logic for non-link items
      setSelected((prev) => (prev === id ? null : id));
      setOpenSubList((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
      return;
    }
    const isRunningInElectron = isElectron();
    const isDocumentationLink = href.startsWith("/docs");

    if (isRunningInElectron) {
      if (isDocumentationLink) {
        // Handle documentation links in Electron
        const docUrl =
          process.env.NODE_ENV === "development"
            ? `http://localhost:3010${href}`
            : `https://terakota.live/documentation${href}`;
        openDocumentationWindow(
          {
            width: 800,
            height: 600,
            webPreferences: {
              nodeIntegration: false,
              contextIsolation: true,
            },
          },
          docUrl
        );
      } else {
        // Handle other internal Electron navigation
        // Implement your internal navigation logic here if needed
        // window.open(href, "_blank");
        window.location.href = href;
      }
    } else {
      // For web environment
      if (isDocumentationLink) {
        // Open documentation links in a new browser tab
        const url =
          process.env.NODE_ENV === "development"
            ? `http://localhost:3010${href}`
            : `https://terakota.live/documentation${href}`;
        window.open(url, "_blank");
      } else {
        // Default behavior for non-documentation links
        window.location.href = href;
      }
    }
  };

  const [othersanchorEl, setOthersanchorEl] =
    React.useState<null | HTMLElement>(null);

  // ... (other functions)

  const handleOthersClick = (event: React.MouseEvent<HTMLElement>) => {
    if (open) {
      // assuming 'open' is the state determining if the miniDrawer is open
      setOpenothers(!openothers);
      setSelected(null);
    } else {
      setOthersanchorEl(othersanchorEl ? null : event.currentTarget);
    }
  };

  const handleClose = () => {
    setOthersanchorEl(null);
  };

  const openPopover = Boolean(othersanchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const renderNestedList = (
    children: any[] = [],
    level: number = 1,
    parentId?: string,
    isPopover: boolean = false
  ) => {
    // Wrap the List component in a Popover if isPopover is true
    const ListComponent = isPopover ? (
      <Popover
        open={openPopover}
        anchorEl={othersanchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          maxHeight: "100%", // Adjust the height as required
          overflow: "auto",
          borderRadius: "4px",
          "& .MuiPopover-paper ": {
            backgroundColor: "rgba(255, 255, 255, 0.2);",
            opacity: "0.8",
            backdropFilter: "blur(25px)",
          },
        }}
      >
        <MenuList component="div" disablePadding onMouseLeave={handleClose}>
          {/* <ClickAwayListener onClickAway={handleClose}> */}
          <List component="div" disablePadding>
            {children.map((child) => (
              <React.Fragment key={child.id}>
                <ListItemButton
                  sx={{
                    pl: theme.spacing(4 * level),
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  onClick={() => handleListItemClick(child.id, child.href)}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      },
                    }}
                    primary={t(`${child.title}`)}
                  />
                  {child.children &&
                    (openSubList[child.id] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore style={{ marginLeft: "5px" }} />
                    ))}
                </ListItemButton>
                {child.children &&
                  renderNestedList(child.children, level + 1, child.id)}
              </React.Fragment>
            ))}
          </List>
          {/* </ClickAwayListener> */}
        </MenuList>
      </Popover>
    ) : (
      <Collapse
        in={parentId === "7" ? openothers : openSubList[parentId ?? ""]}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {children.map((child) => (
            <React.Fragment key={child.id}>
              {/* <ListItemLink href={child.href}> */}
              <ListItemButton
                sx={{
                  pl: theme.spacing(5 * level),
                  bgcolor:
                    selected === child.id ? "secondary.light" : "inherit", // Change the background color when the item is selected
                  "& .MuiListItemText-primary": {
                    fontWeight: selected === child.id ? 600 : "normal", // Bold for selected item
                    color:
                      selected === child.id ? "primary.main" : "text.primary", // Text color change when selected
                  },
                }}
                // onClick={() => handleListItemClick(child.id)}
                onClick={() => handleListItemClick(child.id, child.href)}
                selected={selected === child.id}
              >
                {/* Show dot indicator for Level 1*/}
                {level === 1 && (
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      marginRight: "30px",
                      borderRadius: "50%",
                      background: "currentColor",
                      marginLeft: theme.spacing(1),
                    }}
                  />
                )}
                {level === 2 && !open && (
                  <div
                    style={{
                      paddingLeft: "1px",
                      fontSize: "14px",
                      paddingRight: "5px",
                    }}
                  />
                )}
                <ListItemText
                  primaryTypographyProps={{
                    style:
                      level === 1
                        ? {
                            fontSize: "16px",
                          }
                        : {
                            // Styles for child items (level 2 and beyond)
                            fontSize: "14px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          },
                  }}
                  primary={t(`${child.title}`)}
                />
                {child.children &&
                  (openSubList[child.id] ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {/* </ListItemLink> */}
              {child.children &&
                renderNestedList(child.children, level + 1, child.id)}
            </React.Fragment>
          ))}
        </List>
      </Collapse>
    );
    return ListComponent;
  };

  const loggedOut = () => {
    history.push("/");
  };

  const handleLogoutClick = () => {
    showToast("Do you want to logout?", { width: 300, height: 200 }, loggedOut);
    // openToastNotification("Do you want to logout?", {
    //   width: 300,
    //   height: 100,
    // });
  };

  //useEffect to launch search using keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        event.preventDefault(); // Prevent the browser's search from opening
        openSearchModal();
      }
    };

    // Add event listener
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Root className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            size="large"
          >
            <MenuIcon />
          </IconButton>

          <Link href="#Home" to="/Home" className={classes.linkTitle}>
            <Typography
              variant="h6"
              className={clsx({ [classes.hide]: open })}
              noWrap
            >
              HEALTHCARE MANAGER
            </Typography>
            <Tooltip title="Dashboard">
              <IconButton
                className={clsx({ [classes.hide]: open })}
                size="large"
              >
                <DashboardIcon />
              </IconButton>
            </Tooltip>
          </Link>
          <Tooltip title="Search">
            <IconButton size="small" onClick={openSearchModal}>
              {!isRunningInElectron ? (
                <SearchIcon width="25px" height="25px" />
              ) : myPlatform === "darwin" ? (
                <SearchIconMac width="25px" height="25px" />
              ) : (
                <SearchIconWindows width="25px" height="25px" />
              )}
            </IconButton>
          </Tooltip>
          {/* Right sidebar */}
          <div
            style={{
              marginLeft: "auto",
              textAlign: "right",
              display: "inline-flex",
            }}
          >
            {/* <Typography>
              <Link href="/" to="/">
                <IconButton>
                  <ExitToAppIcon />
                </IconButton>
              </Link>
            </Typography> */}
            <Box>
              <Tooltip title="AI Chat">
                <IconButton
                  size="small"
                  onClick={() => openSlideBar("chat")}
                  sx={{
                    borderRadius: "50%",
                    boxShadow: "rgba(0, 0, 0, 0.03) 0px 6px 30px",
                  }}
                >
                  <AIBotIcon
                    style={{ width: "40", maxWidth: "45", maxHeight: "45" }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <div>
              {/* <Tooltip title="Account">
                <IconButton
                  onClick={(e) => handleAccountMenu(e)}
                  size="small"
                  // sx={{ ml: 2 }}
                  // aria-controls={open ? "account-menu" : undefined}
                  // aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    // src={{}}
                    alt="photoURL"
                    // sx={{ width: 32, height: 32 }}
                  >
                    GO
                  </Avatar>
                </IconButton>
              </Tooltip> */}
              <InfoAvatar handleClick={(e) => handleAccountMenu(e)} />
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={accountMenuOpen}
                onClose={handleAccountMenuClose}
                onClick={handleAccountMenuClose}
                PaperProps={{
                  elevation: 0,
                  style: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    marginTop: 10,
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={handleViewProfile}
                  // sx={{ typography: "body2", py: 1, px: 2.5 }}
                >
                  {/* <ListItemIcon
                    style={{
                      paddingRight: "5px",
                    }}
                  >
                    <Avatar sx={{ width: "35px", height: "35px" }} />
                  </ListItemIcon>{" "} */}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography color="textPrimary">{"   "}Gabe Oni</Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      Admin | Doctor
                    </Typography>
                  </Box>
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={handleLockClick}
                  // sx={{ typography: "body2", py: 1, px: 2.5 }}
                  style={{
                    paddingTop: "5px",
                    marginRight: "5px",
                    fontSize: "14px",
                  }}
                >
                  <ListItemIcon>
                    {/* <PersonAdd fontSize="small" /> */}
                    <LockIcon fontSize="small" />
                  </ListItemIcon>
                  Lock Screen
                </MenuItem>
                <MenuItem
                  onClick={() => openSlideBar("notifications")}
                  // sx={{ typography: "body2", py: 1, px: 2.5 }}
                  style={{
                    fontSize: "14px",
                  }}
                >
                  <ListItemIcon>
                    {/* <PersonAdd fontSize="small" /> */}
                    <NotificationsIcon fontSize="small" />
                  </ListItemIcon>
                  Notifications
                </MenuItem>
                <MenuItem
                  // onClick={() => {
                  //   window.location.href = "#Settings";
                  // }}
                  onClick={() => openSlideBar("settings")}
                  style={{ fontSize: "14px" }}
                >
                  <ListItemIcon>
                    <SettingIcon fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  Logout
                </MenuItem> */}
                <Box sx={{ p: 2, pt: 1.5 }} style={{ fontSize: "14px" }}>
                  <Button
                    fullWidth
                    color="inherit"
                    variant="outlined"
                    // onClick={() => {
                    //   window.location.href = "/";
                    // }}
                    onClick={() => handleLogoutClick()}
                  >
                    {/* <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon> */}
                    Logout
                  </Button>
                  {/* </MenuItem> */}
                </Box>
              </Menu>
            </div>
            {/* <div>
              <LiveButton />
            </div> */}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        open={open}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        // className={clsx(classes.drawer, {
        //   [classes.drawerOpen]: open,
        //   [classes.drawerClose]: !open,
        // })}
        // classes={{
        //   paper: clsx({
        //     [classes.drawerOpen]: open,
        //     [classes.drawerClose]: !open,
        //   }),
        // }}
      >
        <DrawerHeader>
          <div className={classes.toolbar}>
            <IconButton className={classes.toolbarIcon} size="medium">
              <Link href="#Home" to="/Home" className={classes.linkTitle}>
                {" "}
                {/* <DashboardIcon /> */}
                {/* <TerakotaLogo /> */}
                <img
                  src={TerakotaLogo}
                  width={50}
                  height={50}
                  alt="Terakota Logo"
                  style={{
                    borderRadius: "50%",
                    padding: "7px",
                    boxShadow: "rgba(0, 0, 0, 0.07) 0px 6px 30px",
                    backgroundColor: `${backgroundColor}`,
                  }}
                />
                {/* {" "} */}
                <Typography
                  variant="subtitle2"
                  style={{
                    marginLeft: theme.spacing(1),
                    fontWeight: "bold",
                  }}
                  className={clsx({ [classes.hide]: !open })}
                  noWrap
                >
                  DASHBOARD
                  {/* TERAKOTA */}
                </Typography>
              </Link>
            </IconButton>

            <IconButton onClick={handleDrawerClose} size="large">
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
        </DrawerHeader>
        <Divider />

        {/* onClick={<Redirect to="item.href" />} */}
        {/* onClick={()=> item.href} */}
        <Box sx={{ height: "830%", overflowY: "auto" }}>
          {/* /The arrays below map the items const and slices 'em to arrange display accordingly*/}
          <List>
            {items.slice(0, 7).map((item) => (
              <Tooltip
                title={item.title}
                enterDelay={!open ? 0 : 5000}
                placement="bottom-end"
              >
                <ListItemLink href={item.page} key={item.title}>
                  <ListItemButton
                    className={
                      location.pathname === item.href ? "selectedItem" : ""
                    }
                    selected={location.pathname === item.href}
                    style={{
                      borderRadius: "8px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon> {item.icon} </ListItemIcon>
                    <ListItemText primary={t(`${item.title}`)} />
                  </ListItemButton>
                </ListItemLink>
              </Tooltip>
            ))}

            {/* "Others" Section */}
            <ListItemLink key={items[7].title}>
              <ListItemButton
                onClick={handleOthersClick}
                onMouseEnter={open === false ? handleOthersClick : () => {}}
                style={{
                  borderRadius: "8px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>{items[7].icon}</ListItemIcon>
                <ListItemText primary={t(`${items[7].title}`)} />
                {openothers ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItemLink>
            {open
              ? renderNestedList(items[7].children ?? [], 1, "7")
              : renderNestedList(items[7].children ?? [], 1, "7", true)}
          </List>
          {/* {renderOthersMenu()} */}
        </Box>
        {/* <div style={{ position: "absolute", bottom: 0 }}>
          <Divider />
        </div> */}
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          {/* ... All previous ListItems here ... */}

          {/* Spacer to push Settings to the bottom */}
          <div style={{ flexGrow: 1 }}></div>

          <List>
            {items.slice(8, 10).map((item) => (
              <Tooltip
                title={item.title}
                enterDelay={!open ? 0 : 5000}
                placement="top-end"
              >
                <ListItemLink href={item.page} key={item.title}>
                  <ListItemButton
                    className={
                      location.pathname === item.href ? "selectedItem" : ""
                    }
                    selected={location.pathname === item.href}
                    style={{
                      borderRadius: "8px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={t(`${item.title}`)}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItemLink>
              </Tooltip>
            ))}
          </List>
        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {/* <main className={classes.content}>
        <div className={classes.toolbar} > */}
        <main>{props.children}</main>
        {/* <div id="mainview"> */}
        {/* <Routes/> */}
        {/* <Switch>
                        <Route path="/pages/appointments/index" element={ <Appointments/> } />
                        <Route path="/pages/patients/index" element={ <Patients /> } />
                        <Route path="/pages/pharmacy/index" element={ <Pharmacy/> } />
                      </Switch> */}
        {/* <p>
            vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
          gravida rutrum q
            </p> */}
        {/* </div>  */}
        {/* <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut... duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
          donec massa sapien faucibus et molestie ac.
        </Typography> */}

        {/* </div> */}
        {/* </main>  */}
      </Box>
      <Fab
        color="primary"
        aria-label="settings"
        onClick={() => openSlideBar("settings")}
        className={classes.settingsButton}
        size="small"
      >
        <Tooltip title="Settings">
          <TuneIcon />
        </Tooltip>
      </Fab>
      <CustomModal open={seeProfile} handleClose={handleHideProfile}>
        <ViewProfileWidget />
      </CustomModal>
      <SlideBar
        isOpen={slideState.isOpen}
        onClose={closeSlideBar}
        component={slideState.component}
      />
      {isSearchModalOpen && (
        <SearchModal isOpen={true} onClose={closeSearchModal} />
      )}
    </Root>
  );
}
