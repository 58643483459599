import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  useTheme,
  Theme,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const PREFIX = "PatientStats";

const classes = {
  root: `${PREFIX}-root`,
  differenceIcon: `${PREFIX}-differenceIcon`,
  differenceValue: `${PREFIX}-differenceValue`,
};

const PatientStats = ({ ...rest }) => {
  const theme = useTheme();
  const StyledCard = styled(Card)(() => ({
    [`&.${classes.root}`]: {
      height: "100%",
    },

    [`& .${classes.differenceIcon}`]: {
      color: colors.green[900],
      marginLeft: theme.spacing(2),
    },

    [`& .${classes.differenceValue}`]: {
      color: colors.green[900],
      marginRight: theme.spacing(1),
    },
  }));

  return (
    <StyledCard className={clsx(classes.root)} {...rest}>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              REGISTERED PATIENTS
            </Typography>
            <Typography color="textPrimary" variant="h4">
              1,520
            </Typography>
          </Grid>

          <Box mt={2} display="flex" alignItems="center">
            <ArrowUpwardIcon className={classes.differenceIcon} />
            <Typography className={classes.differenceValue} variant="body2">
              12%
            </Typography>
            <Typography color="textSecondary" variant="caption">
              Since last month
            </Typography>
          </Box>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

PatientStats.propTypes = {
  className: PropTypes.string,
};

export default PatientStats;
