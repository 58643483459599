import React, { useState } from "react";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const Beds = ({ room, onAdd, onRemove }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [bedName, setBedName] = useState("");

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setBedName("");
  };

  const handleAddBed = () => {
    onAdd(room.id, bedName);
    handleDialogClose();
  };

  const handleRemoveBed = () => {
    onRemove(room.id);
    handleDialogClose();
  };

  return (
    <>
      <Tooltip
        title={
          <>
            <div>{`Room ${room.roomNumber}`}</div>
            <div>{`Ward: ${room.ward}`}</div>
            <div>{`Available: ${room.available ? "Yes" : "No"}`}</div>
            <div>{`Occupant: ${room.occupant || "None"}`}</div>
            <div>{`Doctor: ${room.doctor || "None"}`}</div>
            <div>{`Admission Date: ${room.admissionDate || "None"}`}</div>
            <div>
              <IconButton
                onClick={handleDialogOpen}
                size="small"
                color="primary"
              >
                <Add />
              </IconButton>
              <IconButton
                onClick={handleDialogOpen}
                size="small"
                color="secondary"
              >
                <Remove />
              </IconButton>
            </div>
          </>
        }
        arrow
      >
        <IconButton onClick={handleDialogOpen}>
          {/* Replace with your bed icon or component */}
          {/* <BedIconSvg /> */}
          <span style={{ fontSize: "24px" }}>🛏️</span>
        </IconButton>
      </Tooltip>

      {/* Dialog for adding/removing bed */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{`Manage Bed for Room ${room.roomNumber} - ${room.ward}`}</DialogTitle>
        <DialogContent>
          <TextField
            label="Bed Name"
            variant="outlined"
            fullWidth
            value={bedName}
            onChange={(e) => setBedName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {room.available ? (
            <Button onClick={handleAddBed} variant="contained" color="primary">
              Add Bed
            </Button>
          ) : (
            <Button
              onClick={handleRemoveBed}
              variant="contained"
              color="secondary"
            >
              Remove Bed
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Beds;

// import React, { useState } from "react";
// import {
//   IconButton,
//   Tooltip,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   TextField,
//   Grid,
// } from "@mui/material";
// import { Bed, Add, Remove } from "@mui/icons-material";

// const Beds = ({ room, onAdd, onRemove }) => {
//   const [openDialog, setOpenDialog] = useState(false);
//   const [bedName, setBedName] = useState("");
//   const [wardName, setWardName] = useState("");

//   const handleDialogOpen = () => {
//     setOpenDialog(true);
//   };

//   const handleDialogClose = () => {
//     setOpenDialog(false);
//     setBedName(""); // Reset bedName on dialog close
//   };

//   const handleAddBed = () => {
//     onAdd(room.id, bedName);
//     handleDialogClose();
//   };

//   const handleRemoveBed = () => {
//     onRemove(room.id);
//     handleDialogClose();
//   };

//   return (
//     <>
//       <Tooltip title={`Room ${room.roomNumber} - ${room.ward}`} arrow>
//         <IconButton onClick={handleDialogOpen}>
//           <Bed /> {/* Replace with your bed icon or component */}
//         </IconButton>
//       </Tooltip>

//       {/* Dialog for adding/removing bed */}
//       <Dialog open={openDialog} onClose={handleDialogClose}>
//         <DialogTitle>{`Manage Bed for Room ${room.roomNumber}`}</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Bed Name"
//             variant="outlined"
//             fullWidth
//             value={bedName}
//             onChange={(e) => setBedName(e.target.value)}
//           />
//           <TextField
//             label="Ward Name"
//             fullWidth
//             variant="outlined"
//             value={wardName}
//             disabled
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleDialogClose}>Cancel</Button>
//           {room.available ? (
//             <Button
//               onClick={handleAddBed}
//               variant="contained"
//               color="primary"
//               startIcon={<Add />}
//             >
//               Add Bed
//             </Button>
//           ) : (
//             <Button
//               onClick={handleRemoveBed}
//               variant="contained"
//               color="secondary"
//               startIcon={<Remove />}
//             >
//               Remove Bed
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default Beds;

// // BedIcon.js
// import React, { useState } from "react";
// import {
//   IconButton,
//   Tooltip,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   TextField,
//   Grid,
// } from "@mui/material";
// import { Bed, Add, Remove } from "@mui/icons-material";

// const Beds = ({ bedDetails, onBedClick, onBedChange }) => {
//   const [dialogOpen, setDialogOpen] = useState(false);

//   const handleDialogOpen = () => {
//     setDialogOpen(true);
//   };

//   const handleDialogClose = () => {
//     setDialogOpen(false);
//   };

//   const handleBedChange = (action) => {
//     onBedChange(bedDetails.id, action);
//     handleDialogClose();
//   };

//   return (
//     <>
//       <Tooltip title={bedDetails.tooltip} arrow>
//         <IconButton onClick={() => onBedClick(bedDetails)}>
//           <Bed />
//         </IconButton>
//       </Tooltip>
//       <Dialog open={dialogOpen} onClose={handleDialogClose}>
//         <DialogTitle>{bedDetails.name}</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <TextField
//                 label="Bed Name"
//                 fullWidth
//                 variant="outlined"
//                 value={bedDetails.name}
//                 disabled
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 label="Ward Name"
//                 fullWidth
//                 variant="outlined"
//                 value={bedDetails.ward}
//                 disabled
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             startIcon={<Add />}
//             onClick={() => handleBedChange("add")}
//             color="primary"
//           >
//             Add Bed
//           </Button>
//           <Button
//             startIcon={<Remove />}
//             onClick={() => handleBedChange("remove")}
//             color="secondary"
//           >
//             Remove Bed
//           </Button>
//           <Button onClick={handleDialogClose} color="default">
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default Beds;
