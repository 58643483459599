// SliderMenu.tsx
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Code as CodeIcon,
  AppsRounded as AppsIcon,
  Description as DescriptionIcon,
  FiberManualRecordRounded as FiberManualRecordIcon,
  ListRounded as ListIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import TerakotaLogo from "../../res/icons/logo512.png";
// import {
//   isElectron,
//   openDocumentationWindow,
// } from "../../electron/electronUtils";
const {
  isElectron,
  openDocumentationWindow,
} = require("../../electron/electronUtils");

interface SliderMenuProps {
  onClose: () => void;
}

const SliderMenu: React.FC<SliderMenuProps> = ({ onClose }) => {
  const theme = useTheme();
  const [openPages, setOpenPages] = useState(false);

  const handleClickPages = () => {
    setOpenPages(!openPages);
  };

  const handleDocsClick = () => {
    const isRunningInElectron = isElectron();
    const isDevelopment = process.env.NODE_ENV === "development";
    const docsUrl = isDevelopment
      ? "http://localhost:3010/docs/intro"
      : "https://terakota.live/documentation/docs";

    if (isRunningInElectron) {
      // In Electron, open in secondary window
      openDocumentationWindow(
        {
          width: 800,
          height: 600,
          webPreferences: {
            nodeIntegration: false,
            contextIsolation: true,
          },
        },
        docsUrl
      );
    } else {
      // In web, open in a new browser tab
      window.open(docsUrl, "_blank");
    }

    onClose(); // Assuming onClose is a function to close the menu
  };

  const pages = [
    { name: "Lock Screen", href: "/lock" },
    { name: "Register Page", href: "/register" },
    { name: "404 Page", href: "/404" },
    { name: "500 Page", href: "/500" },
    { name: "Landing Page", href: "/" },
  ];

  const logoGlowEffect =
    theme.palette.mode === "dark"
      ? "0 0 10px rgba(255, 255, 255, 0.6)"
      : "none";

  return (
    <Box
      sx={{
        width: 280,
        height: "100%",
        // bgcolor: "background.paper",
        backgroundColor: "rgba(66, 56, 41, 0.4)",
        overflowY: "auto",
        backdropFilter: "blur(15px)",
        paddingTop: "25px",
        paddingBottom: "25px",
        // ".MuiListItem-root": {
        //   paddingLeft: theme.spacing(4),
        //   paddingRight: theme.spacing(4),
        // },
        // ".MuiListItemIcon-root": {
        //   minWidth: 0,
        //   marginRight: theme.spacing(2),
        // },
        // ".MuiSvgIcon-root": {
        //   color: "action.active",
        // },
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          outline: "1px solid slategrey",
        },
      }}
    >
      {/* Logo Here */}
      <Box sx={{ display: "flex", justifyContent: "left", p: 2, pb: 3 }}>
        {/* Replace with your logo */}
        <Avatar
          src={TerakotaLogo}
          sx={{
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.7)"
                : "none",
            boxShadow: logoGlowEffect,
            padding: "2px",
            paddingTop: "4px",
            transition: "box-shadow 0.3s",
            "&:hover": {
              boxShadow: logoGlowEffect ? "0 0 20px #fff" : "none",
              padding: "3px",
              paddingTop: "5px",
            },
          }}
        />
      </Box>

      <List>
        <ListItem button onClick={handleClickPages}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Pages" />
          {openPages ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openPages} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Pages sub-pages here */}
            {pages.map((page, index) => (
              <ListItem
                key={index}
                button
                component={RouterLink}
                to={page.href}
                onClick={onClose}
                sx={{ ml: 1 }}
              >
                <ListItemIcon>
                  {/* Bullet icon for each page */}
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ width: "0.7em" }}
                  />
                </ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItem>
            ))}
          </List>
        </Collapse>
        <ListItem
          button
          component={RouterLink}
          to="/components"
          onClick={onClose}
        >
          <ListItemIcon>
            {/* Changed icon for Components */}
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Components" />
        </ListItem>
        <ListItem button component="div" onClick={handleDocsClick}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Docs" />
        </ListItem>
      </List>
    </Box>
  );
};

export default SliderMenu;
