import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
// import { Search as SearchIcon } from "@mui/icons-material";
import SearchBar from "../../bars/searchBar";
import AppointmentForm from "./AppointmentForm";
import patients from "../../_mockData_/patients";
import users from "../../_mockData_/users";

interface Props {
  toggleDisplay: () => void;
  title: string;
}

const AppointmentListHead = ({ toggleDisplay, title }: Props, props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
    // do other search related actions
  };

  const handleAddAppointment = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* <Button>Import</Button> */}
        <Button
          onClick={toggleDisplay}
          color="primary"
          variant="contained"
          sx={{ mx: 1 }}
        >
          {" "}
          {/* Calendar */}
          {title}
        </Button>
        <Button
          onClick={handleAddAppointment}
          color="primary"
          variant="contained"
        >
          Add Appointment
        </Button>
      </Box>
      {/* <Box style={{ width: "100%", mt: 3, mb: 2 }}> */}
      <Box style={{ width: "100%", marginTop: 3, marginBottom: 2 }}>
        <SearchBar
          placeholder="Search Appointments"
          value={searchValue}
          onInputChange={handleSearchInputChange}
          fullWidth={true}
        />
        {/* <Card
          style={{
            background: "rgba(154, 109, 80, 0.1)",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            padding: "2px",
            marginTop: "10px",
            marginBottom: "10px",
            border: "none",
          }}
        > 
         <CardContent> 
       <Box
              style={{
                backgroundColor: "inherit",
                borderWidth: "thin",
                borderBottom: "solid",
                borderBottomColor: "rgba(53, 5, 15, 1)",
                borderBottomWidth: "thin",
              }}
            >
              <TextField
                fullWidth
                // disableUnderline
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Appointment"
              />
            </Box> 
        </CardContent>
         </Card> */}
      </Box>
      {showModal && (
        <AppointmentForm
          patients={patients}
          doctors={users}
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default AppointmentListHead;
