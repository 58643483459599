import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTheme, Theme } from "@mui/material/styles";
import { Box, Paper, Container, Tab, Tabs } from "@mui/material";
import {
  OnAdmission,
  PatientList,
  PatientListHead,
} from "../../components/patient";
import patients from "../../_mockData_/patients";
import createStyledComponent from "../../components/styled/styledcomponents";

// const PREFIX = "Index";

// const classes = {
//   content: `${PREFIX}-content`,
//   tabs: `${PREFIX}-tabs`,
//   flexContainer: `${PREFIX}-flexContainer`,
//   tabPanel: `${PREFIX}-tabPanel`,
// };

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Index");

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

// const Root = styled("div")(({ theme }) => ({
//   [`& .${classes.content}`]: {
//     // display: "flex",
//     flexGrow: 1,
//     padding: theme.spacing(1),
//     background:
//       theme.palette.mode === "dark"
//         ? "radial-gradient(circle, #1a1a1a 0%, #121212 100%)"
//         : "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
//     minHeight: "100vh",
//   },

//   [`& .${classes.tabs}`]: {
//     // background:
//     //   "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
//     justifyContent: "flex-end",
//     borderRadius: "5px",
//     alignItems: "right",
//     background: "rgba(154, 109, 80, 0.1)",
//     boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
//     backdropFilter: "blur(5px)",
//     padding: "2px",
//     marginTop: "10px",
//     marginBottom: "10px",
//     border: "none",
//   },

//   [`& .${classes.flexContainer}`]: {
//     display: "flex",
//     justifyContent: "flex-end",
//   },

//   [`& .${classes.tabPanel}`]: {
//     // justifyContent: "flex-end !important",
//     backdropFilter: "blur(5px)",
//     backgroundColor: "rgba(255, 255, 255, 0.5)",
//     borderRadius: "10px",
//     boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
//     margin: "20px 0px",
//   },
// }));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // sx={{ marginTop: "2px", padding: "16px", height: "100vh" }}
      {...other}
    >
      {value === index && (
        <Box style={{ justifyContent: "flex-end" }} p={3}>
          {children}
        </Box>
      )}
    </Root>
  );
}

function Index() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const sampleBedDetails = {
    id: 1,
    name: "Bed 101",
    ward: "Ward A",
    tooltip: "Click to view details",
  };

  const handleBedClick = (bedDetails: any) => {
    console.log("Bed clicked:", bedDetails);
    // Handle bed click logic here
  };

  const handleBedChange = (bedId: any, action: any) => {
    console.log(`Bed ${action}d:`, bedId);
    // Handle bed add/remove logic here
  };

  return (
    <Root className={classes.content}>
      {/* // <Root> */}
      {/* <div className={classes.content}> */}
      <div>
        {/* <p> Patients</p> */}
        <Box
          sx={{
            // backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            <PatientListHead />
            <Box className={classes.tabPanel}>
              {/* <Paper square> */}
              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                className={classes.tabs}
                classes={{ flexContainer: classes.flexContainer }}
              >
                <Tab label="Patients" />
                <Tab label="On Admission" />
                {/* <Tab label="Tab 3" /> */}
              </Tabs>
              {/* </Paper> */}
              <TabPanel value={tabValue} index={0}>
                <Box>
                  <PatientList patients={patients} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {" "}
                <Box>
                  <OnAdmission />
                </Box>{" "}
              </TabPanel>
              {/* <TabPanel value={tabValue} index={2}>
              {" "}
              <Box>Tab 3 Content</Box>{" "}
            </TabPanel> */}
            </Box>
          </Container>
        </Box>
      </div>
    </Root>
  );
}

export default Index;
