import React, { useState } from "react";
import {
  Container,
  Paper,
  Tab,
  Tabs,
  Box,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Card,
  Typography,
  Link,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import {
  ArrowForward,
  FilterList,
  Message,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { fDate } from "../../utils/formatTime";
import { fShortenNumber } from "../../utils/formatNumber";
import { Link as RouterLink } from "react-router-dom";
import { CardContent, LinkProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import createStyledComponent from "../../components/styled/styledcomponents";

import LABTESTS from "../../_mockData_/lab";
import TestCard from "./TestCard";

const PREFIX = "LabsPage";

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Labs");
// const classes = {
//   content: `${PREFIX}-content`,
// };

// Sample data for invoices, staff, and suppliers
const invoices = [
  { id: "INV001", date: "2023-09-01", amount: 1200, status: "Paid" },
  { id: "INV002", date: "2023-09-05", amount: 800, status: "Pending" },
  { id: "INV003", date: "2023-09-10", amount: 1500, status: "Paid" },
  { id: "INV004", date: "2023-09-10", amount: 3500, status: "UnPaid" },
  { id: "INV005", date: "2023-09-10", amount: 2500, status: "Pending" },
  // Add more invoice entries as needed
];

const LabsPage = ({}) => {
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (accordionOpen === true) {
      handleCloseAccordion();
    } else {
      handleOpenAccordion();
    }
  };

  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <Grid container spacing={3}>
            {LABTESTS.map((post, index) => (
              <TestCard key={post.id} post={post} index={index} />
            ))}
          </Grid>
        );

      case 1:
        return (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>{invoice.id}</TableCell>
                    <TableCell>{invoice.date}</TableCell>
                    <TableCell>{invoice.amount}</TableCell>
                    <TableCell>{invoice.status}</TableCell>
                    <TableCell>
                      <IconButton>
                        <ArrowForward />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      default:
        return null;
    }
  };

  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const handleOpenAccordion = () => {
    setAccordionOpen(true);
  };

  const handleCloseAccordion = () => {
    setAccordionOpen(false);
  };

  const handleFilterSelection = (filter: string) => {
    setSelectedFilter(filter);
    handleCloseAccordion();
  };

  const filterOptions = ["Recent", "Asc", "Desc"];

  return (
    <Container maxWidth="lg">
      <Paper
        // elevation={3}
        sx={{ background: "transparent", marginBottom: 20, marginTop: 1 }}
      >
        {/* <Container
          maxWidth={false}
          sx={{ background: "transparent", marginBottom: 30 }}
        >
          <Grid container spacing={3}>
            <Grid item lg={4} sm={6} xl={3} xs={12}> */}
        {/* <RevenueStats /> */}
        {/* </Grid>
          </Grid>
        </Container> */}

        <AppBar position="static" color="default" className={classes.tabPanel}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabs}
            // classes={{ flexContainer: classes.flexContainer }}
            sx={{ marginTop: "0px !important" }}
          >
            <Tab label="Tests" />
            <Tab label="Invoices" />
          </Tabs>
          <IconButton color="primary" onClick={toggleAccordion}>
            <FilterList />
          </IconButton>

          {accordionOpen && (
            <AccordionDetails
              style={{ display: "flex", justifyContent: "center" }}
            >
              {filterOptions.map((filterOption) => (
                <Chip
                  key={filterOption}
                  label={filterOption}
                  color={
                    selectedFilter === filterOption ? "primary" : "default"
                  }
                  onClick={() => handleFilterSelection(filterOption)}
                  style={{ margin: "4px" }}
                />
              ))}
            </AccordionDetails>
          )}
        </AppBar>

        {/* <TabPanel value={tabValue} index={0}> */}
        <Box p={1}>{renderContent()}</Box>
        {/* </TabPanel> */}
      </Paper>
    </Container>
  );
};

// const TabPanel = (props: any) => {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`settings-tabpanel-${index}`}
//       aria-labelledby={`settings-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </div>
//   );
// };

export default LabsPage;
