import React from "react";
import Titlebar from "./Titlebar";
import { CssBaseline } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { isElectron } from "../../electron/electronUtils";
import { Box, Typography } from "@mui/material";
import LiveButton from "../../components/liveButton";
// import { getPlatform } from "../../electron/electronUtils";
const { isElectron, getPlatform } = require("../../electron/electronUtils");

const isMacOS = getPlatform();

const Layout: React.FC = ({ children }) => {
  // Check if the app is running in Electron
  const theme = useTheme();
  const isRunningInElectron = isElectron();

  const titleStyle = {
    paddingLeft: "66px",
    fontSize: "13px",
  };

  const titleStyleOne = {
    paddingRight: "66px",
    fontSize: "13px",
  };

  const title = `Admin & Health Manager`;

  // Conditionally render the component
  if (!isRunningInElectron) {
    return null; // or any other placeholder for non-Electron environment
  }
  return (
    <header
      className="layout"
      style={
        {
          width: "100%",
          backgroundColor:
            theme.palette.mode === "light"
              ? "rgba(255, 235, 205, 0.3)"
              : "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(15px)",
          zIndex: "2000",
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          top: "0",
          WebkitAppRegion: "drag",
          justifyContent: "center",
          // alignItems: "center",
        } as React.CSSProperties
      }
    >
      <CssBaseline />
      {isMacOS !== "darwin" ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ alignSelf: "flex-start" }}>
              <Box sx={{ marginTop: "9px", marginLeft: "10px" }}>
                <LiveButton />
              </Box>
            </div>
            <div style={{ alignSelf: "center" }}>
              <Typography variant="h6" component="div" sx={titleStyle}>
                {title}
              </Typography>
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              <Titlebar />
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ alignSelf: "flex-start" }}>
              <Titlebar />
            </div>
            <div style={{ alignSelf: "center" }}>
              <Typography variant="h6" component="div" sx={titleStyleOne}>
                {title}
              </Typography>
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              <Box sx={{ marginBottom: "9px", marginRight: "10px" }}>
                <LiveButton />
              </Box>
            </div>
          </Box>
        </>
      )}

      {/* {children} */}
    </header>
  );
};

export default Layout;
