// DynamicDataGrid.tsx
import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowId,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridActionsCellItem,
  GridRenderEditCellParams,
  GridToolbarQuickFilter,
  GridEventListener,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import Typography from "@mui/material/Typography";

const roles = ["Doctor", "Nurse", "Admin", "Clerical", "IT", "Management"];
const randomRole = () => {
  return randomArrayItem(roles);
};
const initialRows: GridRowModel[] = [
  // Add 10 dummy rows of data
  {
    id: randomId(),
    name: randomTraderName(),
    joinDate: randomCreatedDate(),
    department: randomRole(),
    salary: 50000,
    role: "Manager",
    age: 30,
  },
  {
    id: randomId(),
    name: randomTraderName(),
    joinDate: randomCreatedDate(),
    department: randomRole(),
    salary: 50000,
    role: "Manager",
    age: 30,
  },
  {
    id: randomId(),
    name: randomTraderName(),
    joinDate: randomCreatedDate(),
    department: randomRole(),
    salary: 50000,
    role: "Manager",
    age: 30,
  },
  {
    id: randomId(),
    name: randomTraderName(),
    joinDate: randomCreatedDate(),
    department: randomRole(),
    salary: 50000,
    role: "Manager",
    age: 30,
  },
  // ... more rows
];

// Custom Toolbar Component
function CustomToolbar(props: any) {
  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={props.onAddClick}
      >
        Add Record
      </Button>
      <GridToolbarQuickFilter
        sx={{
          marginLeft: 2,
          // padding: "1px",
          paddingLeft: "1px",
          paddingRight: "1px",
          maxWidth: "150px",
        }}
      />
      <Box sx={{ marginLeft: "auto", marginRight: "0", float: "right" }}>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button
          color="primary"
          startIcon={<ShareIcon />}
          onClick={props.onAddClick}
        >
          Share
        </Button>
      </Box>
    </GridToolbarContainer>
  );
}

// Custom Multiline Edit Component
const EditTextarea = (props: GridRenderEditCellParams) => {
  const { id, value, apiRef, field } = props;
  const [valueState, setValueState] = React.useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueState(event.target.value);
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <InputBase value={valueState} onChange={handleChange} multiline fullWidth />
  );
};

const DynamicDataGrid: React.FC = () => {
  const [rows, setRows] = useState<GridRowModel[]>(initialRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleAddClick = () => {
    const newId = rows.length > 0 ? Math.max(...rows.map((r) => r.id)) + 1 : 1;
    const newRow = {
      id: newId, // Ensure this is always a number and unique
      name: "",
      joinDate: "",
      department: "",
      salary: "",
      role: "",
      age: "",
      isNew: true,
    };
    setRows([...rows, newRow]);
    setRowModesModel({
      ...rowModesModel,
      [newId]: { mode: GridRowModes.Edit },
    });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    // Remove the row if it was new and being edited for the first time
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 150, editable: true },
    {
      field: "joinDate",
      headerName: "Join Date",
      width: 110,
      editable: true,
      type: "Date",
    },
    {
      field: "department",
      headerName: "Department",
      width: 130,
      editable: true,
    },
    {
      field: "salary",
      headerName: "Salary",
      width: 110,
      editable: true,
      type: "number",
    },
    { field: "role", headerName: "Role", width: 120, editable: true },
    {
      field: "age",
      headerName: "Age",
      width: 80,
      editable: true,
      type: "number",
    },
    // Add action column
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
          />,
        ];
      },
    },
  ];

  return (
    <Paper elevation={3} sx={{ padding: "10px", borderRadius: "8px" }}>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="h6"
        sx={{ paddingLeft: "2px" }}
      >
        Data Sheet
      </Typography>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          slots={{
            toolbar: CustomToolbar,
            // columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              onAddClick: handleAddClick,
              setRows,
              setRowModesModel,
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default DynamicDataGrid;
