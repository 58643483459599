import React, { useState, ReactElement } from "react";
import {
  Container,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
  InputBase,
  Button,
  Drawer,
  Menu,
  MenuItem,
  TextField,
  Badge,
  Box,
  AvatarGroup,
  Chip,
  Autocomplete,
} from "@mui/material";
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  Send as SendIcon,
  Attachment as AttachmentIcon,
  Camera as CameraIcon,
  Phone as PhoneIcon,
  Videocam as VideocamIcon,
  MoreVert as MoreVertIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  VolumeOff as VolumeOffIcon,
  Mic as MicIcon,
  PersonAddAlt1 as PersonAddIcon,
  NavigateBefore as ArrowBackIcon,
  NavigateNext as ArrowForwardIcon,
  InsertInvitation as InviteIcon,
  EmojiEmotionsRounded as EmojiIcon,
} from "@mui/icons-material";
import { ReactComponent as AIBotIcon } from "../../res/icons/AI-icon.svg";
import { AvatarButton, StyledBadge, SmallAvatar } from "../utility/SliderChat";

interface User {
  id: number;
  name: string;
  avatar: string | ReactElement;
  isBot: boolean;
  isOnline: boolean;
}

interface Message {
  id: number;
  userId: number;
  text?: string;
  image?: string;
  timestamp: string;
  type: "text" | "image" | "system" | "text-image";
  isSender: boolean;
}

interface Conversation {
  id: number;
  participantIds: number[];
  messages: Message[];
}

// Sample data for users and conversations
const users: User[] = [
  {
    id: 1,
    name: "John Doe",
    avatar: "/static/mock-images/avatars/avatar_19.jpg",
    isBot: false,
    isOnline: true,
  },
  {
    id: 2,
    name: "AIBot",
    avatar: <AIBotIcon />,
    isBot: true,
    isOnline: true,
  },
  {
    id: 3,
    name: "Alice Jones",
    avatar: "/static/mock-images/avatars/avatar_13.jpg",
    isBot: false,
    isOnline: false,
  },
  {
    id: 4,
    name: "Bob Brown",
    avatar: "/static/mock-images/avatars/avatar_5.jpg",
    isBot: false,
    isOnline: true,
  },
  {
    id: 5,
    name: "Jane Smith",
    avatar: "/static/mock-images/avatars/avatar_17.jpg",
    isBot: false,
    isOnline: false,
  },
];

const conversations: Conversation[] = [
  {
    id: 1,
    participantIds: [1, 2], // Conversation between two users
    messages: [
      {
        id: 1,
        userId: 1,
        text: "Hey Jane, have you seen the latest project update?",
        timestamp: "10:00 AM",
        type: "text",
        isSender: true,
      },
      {
        id: 2,
        userId: 2,
        text: "Hi John, not yet. I'm about to check it out.",
        timestamp: "10:01 AM",
        type: "text",
        isSender: false,
      },
      {
        id: 3,
        userId: 1,
        text: "Here's an image that summarizes the main points.",
        image: "/static/mock-images/covers/Cover_0003.png",
        timestamp: "10:02 AM",
        type: "image",
        isSender: true,
      },
    ],
  },
  {
    id: 2,
    participantIds: [2, 3, 4], // Conversation among three users
    messages: [
      {
        id: 1,
        userId: 3,
        text: "Alice here, I've scheduled our next meeting for tomorrow.",
        timestamp: "09:45 AM",
        type: "system",
        isSender: false,
      },
      {
        id: 2,
        userId: 4,
        text: "Thanks for the heads up, Alice!",
        timestamp: "09:47 AM",
        type: "text",
        isSender: false,
      },
      {
        id: 3,
        userId: 2,
        text: "Could we also discuss the new design proposals?",
        image: "/static/mock-images/covers/Cover_0006.png",
        timestamp: "09:50 AM",
        type: "text-image",
        isSender: false,
      },
    ],
  },
  {
    id: 3,
    participantIds: [1, 2, 3, 4, 5], // Group chat including the bot
    messages: [
      {
        id: 1,
        userId: 5,
        text: "Reminder: Team lunch scheduled for 12:30 PM today.",
        timestamp: "08:30 AM",
        type: "system",
        isSender: false,
      },
      {
        id: 2,
        userId: 1,
        text: "Sounds great! Who's joining?",
        timestamp: "08:35 AM",
        type: "text",
        isSender: true,
      },
      {
        id: 3,
        userId: 2,
        text: "Count me in! Also, here's the report from yesterday.",
        image: "/static/mock-images/covers/Cover_0007.png",
        timestamp: "08:40 AM",
        type: "text-image",
        isSender: false,
      },
      {
        id: 4,
        userId: 3,
        text: "I'll be there too. Let's discuss the report after lunch.",
        timestamp: "08:42 AM",
        type: "text",
        isSender: false,
      },
      {
        id: 5,
        userId: 5,
        text: "EcoTip: Save energy by turning off your workstation before you join us!",
        timestamp: "08:45 AM",
        type: "system",
        isSender: false,
      },
    ],
  },
];

const ChatScreen: React.FC = () => {
  const [selectedChat, setSelectedChat] = useState<number | null>(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  // New state for toggling left column
  const [isLeftColumnMinimized, setLeftColumnMinimized] = useState(false);

  // Function to toggle left column size
  const toggleLeftColumn = () => {
    setLeftColumnMinimized(!isLeftColumnMinimized);
  };

  // const handleChatSelect = (chatId: number) => {
  //   setSelectedChat(chatId);
  // };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  // const [selectedChat, setSelectedChat] = useState(null);
  const [displayedMessages, setDisplayedMessages] = useState<Message[]>([]);

  const handleChatSelect = (chatId: number) => {
    const selectedConversation = conversations.find(
      (conv) => conv.id === chatId
    );
    setSelectedChat(chatId);
    setDisplayedMessages(
      selectedConversation ? selectedConversation.messages : []
    );
  };

  interface Invitee {
    id: number;
    name: string;
  }

  const [invitees, setInvitees] = useState<Invitee[]>([]);
  const [inviteInput, setInviteInput] = useState("");

  const handleAddInvitee = (user: User) => {
    setInvitees((prev) => [...prev, { id: user.id, name: user.name }]);
    setInviteInput(""); // Reset input
  };

  const handleRemoveInvitee = (id: number) => {
    setInvitees((prev) => prev.filter((invitee) => invitee.id !== id));
  };

  const selectedConversation = conversations.find((c) => c.id === selectedChat);
  const conversationUsers = selectedConversation
    ? users.filter((u) => selectedConversation.participantIds.includes(u.id))
    : [];

  // Function to get the last message from a conversation
  function getLastMessage(conversation?: Conversation): string {
    if (!conversation) return "No conversation selected";
    const lastMessage = conversation.messages[conversation.messages.length - 1];
    return lastMessage
      ? lastMessage.text || "Media message"
      : "No messages yet";
  }

  // Function to render single avatar with online status
  function renderSingleAvatar(user: User) {
    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant={user.isOnline ? "dot" : "standard"}
      >
        <Avatar alt={user.name} src={user.avatar as string} />
      </StyledBadge>
    );
  }

  // Function to render avatar group for 3 users
  function renderAvatarGroupForThree(users: User[]) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={<SmallAvatar alt={users[2].name} src={users[2].avatar} />}
      >
        {/* <Avatar alt={users[0].name} src={users[0].avatar as string} /> */}
        {users[0].isBot ? (
          <Avatar alt={users[0].name} sx={{ width: "25px", height: "28px" }}>
            <AIBotIcon />
          </Avatar>
        ) : (
          <Avatar
            alt={users[0].name}
            src={users[0].avatar as string}
            sx={{ width: "25px", height: "28px" }}
          />
        )}
        <SmallAvatar alt={users[1].name} src={users[1].avatar} />
      </Badge>
    );
  }

  // Function to render avatar group for 4 and more users
  function renderAvatarGroupForMany(users: User[]) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <SmallAvatar
            alt={users[2].name}
            src={users[2].avatar}
            sx={{ width: "15px", height: "15px" }}
          />
        }
      >
        <Avatar
          alt={users[0].name}
          src={users[0].avatar as string}
          sx={{ width: "23px", height: "26px", top: "5" }}
        />
        <Box sx={{ display: "flex", position: "static", top: 5 }}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            badgeContent={
              <SmallAvatar
                alt={users[3].name}
                src={users[3].avatar}
                sx={{ width: "15px", height: "15px" }}
              />
            }
          >
            <SmallAvatar
              alt={users[1].name}
              src={users[1].avatar}
              sx={{ width: "20px", height: "20px" }}
            />
          </Badge>
        </Box>
      </Badge>
    );
  }

  // Function to select which avatar group to render based on the number of users
  function renderAvatarGroup(conversation?: Conversation) {
    if (!conversation) {
      // Return some default UI or null if no conversation is selected
      return null;
    }
    const conversationUsers = users.filter((user) =>
      conversation.participantIds.includes(user.id)
    );
    if (conversationUsers.length === 2) {
      return renderSingleAvatar(conversationUsers[0]);
    } else if (conversationUsers.length === 3) {
      return renderAvatarGroupForThree(conversationUsers);
    } else {
      return renderAvatarGroupForMany(conversationUsers);
    }
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        {/* Left Column */}
        <Grid
          item
          xs={isLeftColumnMinimized ? 3 : 12}
          md={isLeftColumnMinimized ? 1 : 4}
          style={{ minWidth: "120px", opacity: "95%" }}
        >
          <Paper
            elevation={3}
            style={
              isLeftColumnMinimized
                ? {
                    width: "auto",
                    height: "100%",
                    borderRadius: "8px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }
                : {
                    height: "100%",
                    borderRadius: "8px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }
            }
          >
            <AppBar
              position="static"
              style={{
                // background: "rgba(255, 255, 255, 0.7)",
                opacity: "60%",
                backdropFilter: "blur(50px)",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "0px",
              }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleLeftColumn}
                  style={
                    isLeftColumnMinimized
                      ? { display: "initial", margin: "0 5px 0 10px" }
                      : { display: "none" }
                  }
                >
                  <ArrowForwardIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  style={
                    isLeftColumnMinimized
                      ? { display: "none" }
                      : { display: "initial" }
                  }
                >
                  Consult
                </Typography>
                <Box style={{ flex: "1 1 0%" }}></Box>
                <IconButton
                  style={
                    isLeftColumnMinimized
                      ? { display: "none" }
                      : { display: "initial", float: "right" }
                  }
                >
                  <PersonAddIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div style={{ padding: "16px" }}>
              {/* <Button variant="outlined" color="primary" fullWidth>
                Add Group
              </Button> */}
              <div style={{ marginTop: "16px", borderRadius: "8px" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <SearchIcon style={{ marginRight: "8px" }} />
                    ),
                  }}
                  style={
                    isLeftColumnMinimized
                      ? { display: "none" }
                      : { display: "initial" }
                  }
                />
              </div>
              <List>
                {users.map((user) => {
                  // Find the conversation for the user
                  const userConversation = conversations.find((conv) =>
                    conv.participantIds.includes(user.id)
                  );
                  return (
                    <ListItem
                      key={user.id}
                      button
                      onClick={() => handleChatSelect(user.id)}
                      selected={user.id === selectedChat}
                      style={{
                        padding: "10px 16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        style={{
                          minWidth: isLeftColumnMinimized ? "0" : "60px",
                        }}
                      >
                        {renderAvatarGroup(userConversation)}
                      </Box>
                      <Box
                        style={{
                          display: isLeftColumnMinimized ? "none" : "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          minWidth: 0,
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              noWrap
                              style={{ lineHeight: "1.25", fontWeight: "500" }}
                            >
                              {user.name}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              noWrap
                              style={{ lineHeight: "1.25" }}
                            >
                              {getLastMessage(userConversation)}
                            </Typography>
                          }
                          style={{ margin: 0 }}
                        />
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Paper>
        </Grid>

        {/* Right Column */}
        <Grid
          item
          xs={isLeftColumnMinimized ? 9 : 12}
          md={isLeftColumnMinimized ? 10 : 8}
          style={{
            borderRadius: "8px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          }}
        >
          {/* Chat Header */}
          <AppBar
            position="static"
            color="default"
            elevation={1}
            style={{
              background: "rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(25px)",
              borderTopRightRadius: "8px",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton> */}
              <IconButton onClick={toggleLeftColumn}>
                {/* Conditionally render icon based on isLeftColumnMinimized */}
                {isLeftColumnMinimized ? (
                  <MenuIcon sx={{ display: "none" }} />
                ) : (
                  <ArrowBackIcon />
                )}
              </IconButton>

              <div style={{ flex: 1 }}>
                {selectedChat ? (
                  <Box
                    style={{
                      // padding: "10px 16px",
                      // flexDirection: "column",
                      // justifyContent: "center",
                      // minWidth: 0,
                      // display: "flex",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Render participants' avatars */}
                    {conversationUsers.slice(1, 5).map((user, index) => (
                      <Avatar
                        key={user.id}
                        alt={user.name}
                        src={user.avatar as string}
                        style={{
                          marginLeft: index > 0 ? -15 : 0, // Overlap avatars
                        }}
                      />
                    ))}

                    {/* <Typography
                      variant="caption"
                      noWrap
                      color="textSecondary"
                      style={{ marginRight: "16px", lineHeight: "1.25" }}
                    >
                      Last Active: 2 hours ago{" "} */}
                    {/* Adjust this dynamically based on the selected conversation */}
                    {/* </Typography> */}
                  </Box>
                ) : (
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={users}
                    getOptionLabel={(option) => option.name}
                    value={invitees}
                    onChange={(event, newValue) => {
                      setInvitees(newValue);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                          onDelete={() => handleRemoveInvitee(option.id)}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        // label="Invite Participants"
                        placeholder="Invite Participants"
                        sx={{
                          underline: {
                            "&&&:before": {
                              borderBottom: "1px",
                              opacity: "10%",
                            },
                            "&&:after": {
                              borderBottom: "1px",
                              opacity: "10%",
                            },
                          },
                        }}
                      />
                    )}
                    style={{ minWidth: "fit-content", width: "40%" }}
                  />
                )}
                {/* <Box
                  style={{
                    padding: "10px 16px",
                    flexDirection: "column",
                    justifyContent: "center",
                    minWidth: 0,
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    style={{ marginRight: "16px", lineHeight: "1.25" }}
                  >
                    User Name
                  </Typography>
                  <Typography
                    variant="caption"
                    noWrap
                    color="textSecondary"
                    style={{ marginRight: "16px", lineHeight: "1.25" }}
                  >
                    Last Active: 2 hours ago
                  </Typography>
                </Box> */}
              </div>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={null}
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                  elevation: 0,
                  style: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    marginTop: 120,
                  },
                }}
                // transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <MenuItem onClick={handleMenuClose}>
                  <InviteIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Invite
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <ArchiveIcon
                    fontSize="small"
                    style={{ marginRight: "8px" }}
                  />
                  Archive
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Delete
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <VolumeOffIcon
                    fontSize="small"
                    style={{ marginRight: "8px" }}
                  />
                  Mute
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <BlockIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Block
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

          {/* Chat Messages */}
          {/* <Paper
            elevation={3}
            style={{
              minHeight: "70vh",
              padding: "16px",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          > */}
          <Paper
            elevation={3}
            style={{
              minHeight: "70vh",
              padding: "16px",
              position: "relative",
              display: "flex",
              maxHeight: "70vh",
              overflowY: "auto",
              flexDirection: "column",
              opacity: "95%",
            }}
          >
            {/* Messages go here */}

            {displayedMessages.map((message) => {
              // Find the user who sent this message
              const messageUser = users.find(
                (user) => user.id === message.userId
              );

              // Decide the alignment of the message box
              const messageFloat = message.isSender ? "right" : "left";

              // Decide the background color of the message box based on sender/receiver
              const backgroundColor = message.isSender
                ? "lightblue"
                : "lightgrey";

              return (
                <div
                  key={message.id}
                  style={{
                    display: "flex",
                    justifyContent: message.isSender
                      ? "flex-end"
                      : "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  {
                    !message.isSender &&
                      messageUser &&
                      // (isReactElement(messageUser.avatar) ? (
                      (typeof messageUser.avatar === "string" ? (
                        <Avatar
                          alt={messageUser.name}
                          src={messageUser.avatar}
                          style={{ marginRight: "8px", marginLeft: "8px" }}
                        />
                      ) : (
                        <Avatar
                          style={{ marginRight: "8px", marginLeft: "8px" }}
                          alt={messageUser.name}
                        >
                          {messageUser.avatar}
                        </Avatar>
                      ))
                    // <Avatar
                    //   alt={messageUser.name}
                    //   src={messageUser.avatar}
                    //   style={{ marginRight: "8px" }}
                    // />
                  }
                  <div
                    style={{
                      backgroundColor: backgroundColor,
                      padding: "10px",
                      borderRadius: "10px",
                      maxWidth: "75%",
                      textAlign: messageFloat,
                    }}
                  >
                    {message.type.includes("text") && (
                      <Typography variant="body1" color="#000">
                        {message.text}
                      </Typography>
                    )}
                    {message.type.includes("image") && (
                      <img
                        src={message.image}
                        alt="Attachment"
                        style={{
                          maxWidth: "100%",
                          marginTop: "5px",
                          borderRadius: "4px",
                        }}
                      />
                    )}
                    <Typography
                      variant="caption"
                      align="right"
                      display="block"
                      style={{ marginTop: "5px", color: "#000" }}
                    >
                      {message.timestamp}
                    </Typography>
                  </div>
                  {
                    message.isSender &&
                      messageUser &&
                      // (isReactElement(messageUser.avatar) ? (
                      (typeof messageUser.avatar === "string" ? (
                        <Avatar
                          alt={messageUser.name}
                          src={messageUser.avatar}
                          style={{ marginRight: "8px", marginLeft: "8px" }}
                        />
                      ) : (
                        <Avatar
                          style={{ marginRight: "8px", marginLeft: "8px" }}
                          alt={messageUser.name}
                        >
                          {messageUser.avatar}
                        </Avatar>
                      ))
                    // <Avatar
                    //   alt={messageUser.name}
                    //   src={messageUser.avatar}
                    //   style={{ marginLeft: "8px" }}
                    // />
                  }
                </div>
              );
            })}
          </Paper>

          {/* Message Input */}
          <Paper
            elevation={3}
            style={{
              padding: "8px",
              display: "flex",
              alignItems: "center",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "8px",
            }}
          >
            <IconButton color="primary">
              <EmojiIcon />
            </IconButton>
            {/* <IconButton color="primary">
              <CameraIcon />
            </IconButton> */}
            <InputBase fullWidth placeholder="Leave a message..." />
            <IconButton color="primary">
              <SendIcon />
            </IconButton>

            <IconButton color="primary">
              <MicIcon />
            </IconButton>
            <IconButton color="primary">
              <AttachmentIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatScreen;
