import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
// import { keyframes, css } from "@mui/system";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Icon } from "@iconify/react";
// import uptickIcon from '@iconify-icons/noto-v1/uptick';
import twotoneDoubleArrow from "@iconify/icons-ic/twotone-double-arrow";

type CustomStatsProps = {
  title?: string;
  icon?: any;
  improvement?: number;
  percentageIncrease?: number;
  currentFigure?: string;
  chartType?: any;
  chartOptions?: any; // Assuming ApexOptions is the type for ApexCharts options
  chartSeries?: any; // Assuming ApexAxisChartSeries is the type for ApexCharts series data
} & React.HTMLAttributes<HTMLDivElement>;

const PREFIX = "CustomStats";

const classes = {
  root: `${PREFIX}-root`,
  differenceIcon: `${PREFIX}-differenceIcon`,
  differenceValue: `${PREFIX}-differenceValue`,
  chartContainer: `${PREFIX}-chartContainer`,
};

// const charttOptions: any = {
//   chart: {
//     type: "bar",
//     width: 100,
//     height: 35,
//     sparkline: {
//       enabled: true,
//     },
//   },
//   plotOptions: {
//     bar: {
//       columnWidth: "80%",
//     },
//   },
//   labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
//   xaxis: {
//     crosshairs: {
//       width: 1,
//     },
//   },
//   tooltip: {
//     fixed: {
//       enabled: false,
//     },
//     x: {
//       show: false,
//     },
//     y: {
//       show: false,
//       title: {
//         formatter: function (seriesName: any) {
//           return "";
//         },
//       },
//     },
//     marker: {
//       show: false,
//     },
//   },
// };

// const charttSeries = [
//   {
//     data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
//   },
// ];

const CustomStats: React.FC<CustomStatsProps> = ({
  title = "Custom",
  icon = twotoneDoubleArrow,
  improvement = 10,
  percentageIncrease = 0,
  currentFigure = "14,990",
  chartType = "area",
  chartOptions,
  chartSeries,
  ...rest
}) => {
  const theme = useTheme();
  const StyledCard = styled(Card)(() => ({
    "@keyframes blink": {
      "0%": { opacity: 1 },
      "50%": { opacity: 0.4 },
      "100%": { opacity: 1 },
    },

    [`&.${classes.root}`]: {
      height: "100%",
    },

    [`& .${classes.differenceIcon}`]: {
      color: improvement >= 0 ? "green" : "red", // Change color based on improvement
      transform: improvement >= 0 ? "rotate(-90deg)" : "rotate(-270deg)",
      marginLeft: (theme: any) => theme.spacing(2),
      animation: `blink 2s linear infinite`,
    },

    [`& .${classes.differenceValue}`]: {
      color: improvement >= 0 ? "green" : "red", // Change color based on improvement
      marginRight: (theme: any) => theme.spacing(1),
      //   marginLeft: (theme: any) => theme.spacing(2),
      paddingLeft: (theme: any) => theme.spacing(2),
    },

    [`& .${classes.chartContainer}`]: {
      marginTop: (theme: any) => theme.spacing(2),
      float: "right",
      //   width: "15vw",
    },
  }));

  return (
    <StyledCard className={clsx(classes.root)} {...rest}>
      <CardContent>
        <Typography color="textSecondary" variant="h6" gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={4} alignSelf="center">
            <Box display="flex" alignItems="center">
              <Icon
                icon={icon}
                className={classes.differenceIcon}
                fontSize="35px"
              />
              <Typography
                className={classes.differenceValue}
                fontSize="15px"
                variant="body2"
              >
                {percentageIncrease > 0
                  ? `+${percentageIncrease.toFixed(1)}%`
                  : `${percentageIncrease.toFixed(1)}%`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.chartContainer}>
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type={chartType}
                height={35}
                width={115}
              />
            </div>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "5px !important" }}>
            <Typography color="textPrimary" variant="h4">
              {currentFigure}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Icon icon={icon} className={classes.differenceIcon} />
              <Typography className={classes.differenceValue} variant="body2">
                {percentageIncrease > 0
                  ? `+${percentageIncrease.toFixed(1)}%`
                  : `${percentageIncrease.toFixed(1)}%`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.chartContainer}>
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type={chartType}
                height={35}
                width={100}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h4">
              {currentFigure}
            </Typography>
          </Grid>
        </Grid> */}
      </CardContent>
    </StyledCard>
  );
};

CustomStats.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  improvement: PropTypes.number.isRequired,
  percentageIncrease: PropTypes.number.isRequired,
  currentFigure: PropTypes.string.isRequired,
  chartType: PropTypes.string.isRequired,
  chartOptions: PropTypes.object.isRequired,
  chartSeries: PropTypes.array.isRequired,
};

export default CustomStats;
