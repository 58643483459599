import { createTheme, ThemeOptions  } from '@mui/material/styles';


const sharedConfig: ThemeOptions = {
    // Add any common and shared configurations here
  };
  
  const darkModeConfig: ThemeOptions  = {
    palette: {
      mode: 'dark',
    },
    components: {
    // styleOverrides: {
    MuiButton: {
      styleOverrides: {
        contained: {
          root: {
            color: "#c0b88b", // change text color
            backgroundColor: "#c0b88b", // change button background color
          },
        },
      },
    },
    // MuiTypography: {
    //   styleOverrides: {
    // contained: {
    // root: {
    //   color: "brown", // change text color
    // },
    // },
    //   },
    // },
    MuiIconButton: {
      styleOverrides: {
        // contained: {
        root: {
          color: "#b3a287", // change text color
          // backgroundColor: "blue", // change button background color
        },
        // },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        // contained: {
        root: {
          color: "#b3a287", // change text color
          // backgroundColor: "blue", // change button background color
        },
        // },
      },
    },
    MuiIcon: {
      styleOverrides: {
        // contained: {
        root: {
          color: "#b3a287", // change text color
          // backgroundColor: "blue", // change button background color
        },
        // },
      },
    },
    // MuiToolbar: {
    //   styleOverrides: {
    //     // contained: {
    //     root: {
    //       color: "#7c6f61", // change text color
    //       // backgroundColor: "blue", // change button background color
    //     },
    //     // },
    //   },
    // },
    // MuiPaper: {
    //   styleOverrides: {
    //     // contained: {
    //     root: {
    //       color: "#b3a287", // change text color
    //       // backgroundColor: "blue", // change button background color
    //     },
    //     // },
    //   },
    // },
    // },
  },
      // Add other dark mode component configurations
  };
  
  const lightModeConfig: ThemeOptions  = {
    palette: {
      mode: 'light',
    },
    // Sample component below
    // components: {
    //   MuiButton: {
    //     styleOverrides: {
    //       contained: {
    //         root: {
    //           color: 'blue', // Change text color
    //           backgroundColor: 'blue', // Change button background color
    //         },
    //       },
    //     },
    //   },
    // Add other light mode component configurations
  };
  

// export const getTheme = (mode: 'light' | 'dark') => {
//   return createTheme({
//     palette: {
//       mode: mode,
//     },
//     // Add other theme configurations here
//   });
// };

export const getTheme = (mode: 'light' | 'dark' | 'system', direction: 'ltr' | 'rtl') => {
    const themeConfig = mode === 'dark' ? darkModeConfig : lightModeConfig;
    console.log('Current Theme Mode:', mode); 

    return createTheme({
      ...sharedConfig,
      ...themeConfig,
      direction: direction, 
      // Add other theme configurations here
    });
  };
  
