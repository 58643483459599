import React, { useState } from "react";
import {
  Container,
  Paper,
  Tab,
  Tabs,
  Box,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Card,
  Typography,
  Link,
  Avatar,
  alpha,
} from "@mui/material";
import { ArrowForward, FilterList, Message } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { fDate } from "../../utils/formatTime";
import { fShortenNumber } from "../../utils/formatNumber";
import { Link as RouterLink } from "react-router-dom";
import { CardContent, LinkProps } from "@mui/material";
import { styled } from "@mui/material/styles";

// import LABTESTS from "../../_mockData_/lab";

const CardMediaStyle = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: "hidden",
  WebkitLineClamp: 2,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
});

// const Root = styled("div")(({ theme }) => ({
// const openedMixin = (theme: Theme): CSSObject => ({
const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const InfoStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled("img")(({ theme }) => ({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
}));

interface Post {
  cover?: string;
  title?: string;
  view?: number;
  comment?: number;
  share?: number;
  labTechnician: {
    name: any;
    avatarUrl: string;
  };
  createdAt?: Date;
}

interface TestCardProps {
  post: Post;
  index: number;
  theme?: object;
}

const TestCard = ({ post, index }: TestCardProps) => {
  const { cover, title, view, comment, share, labTechnician, createdAt } = post;
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;

  const POST_INFO = [
    { number: comment, icon: Message },
    { number: view, icon: Message },
    { number: share, icon: Message },
  ];

  return (
    <Grid
      item
      xs={12}
      sm={latestPostLarge ? 12 : 6}
      md={latestPostLarge ? 6 : 3}
    >
      <Card
        sx={{
          position: "relative",
        }}
      >
        <CardMediaStyle
          sx={{
            "&:after": {
              top: 0,
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.72),
            },
            ...((latestPostLarge || latestPost) && {
              pt: "calc(100% * 4 / 3)",
              "&:after": {
                top: 0,
                content: "''",
                width: "100%",
                height: "100%",
                position: "absolute",
                bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
            ...(latestPostLarge && {
              pt: {
                xs: "calc(100% * 4 / 3)",
                sm: "calc(100% * 3 / 4.66)",
              },
            }),
          }}
        >
          <AvatarStyle
            alt={labTechnician.name}
            src={labTechnician.avatarUrl}
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40,
              }),
            }}
          />
          <CoverImgStyle alt={title} src={cover} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
            ...((latestPostLarge || latestPost) && {
              bottom: 0,
              width: "100%",
              position: "absolute",
            }),
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: "text.disabled", display: "block" }}
          >
            {/* {fDate(createdAt)} */}
          </Typography>

          <TitleStyle
            color="inherit"
            variant="subtitle2"
            underline="hover"
            // component={RouterLink}
            sx={{
              ...(latestPostLarge && { typography: "h5", height: 60 }),
              ...((latestPostLarge || latestPost) && {
                color: "common.white",
              }),
            }}
          >
            {title}
          </TitleStyle>

          <InfoStyle>
            {POST_INFO.map((info, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: index === 0 ? 0 : 1.5,
                  ...((latestPostLarge || latestPost) && {
                    color: "grey.500",
                  }),
                }}
              >
                <Box component={Icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant="caption">
                  {fShortenNumber(info.number)}
                </Typography>
              </Box>
            ))}
          </InfoStyle>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TestCard;
