import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import UpgradeIcon from "@mui/icons-material/Upgrade"; // Import the upgrade icon

const StyledCard = styled(Card)(({ theme: { breakpoints, spacing } }) => ({
  //   width: 360,
  maxWidth: "100%",
  // 16px
  borderRadius: spacing(2),
  transition: "0.3s",
  boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
  position: "relative",
  overflow: "initial",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  paddingLeft: 8,
  paddingRight: 8,

  background: "linear-gradient(34deg, #e4a672 0%, #b08968 29%, #7e5e52 92%)",

  [breakpoints.up("sm")]: {
    textAlign: "left",
    flexDirection: "row-reverse",
  },
}));

const CardMediaMedia = styled(CardMedia)(({ theme: { breakpoints } }) => ({
  flexShrink: 0,
  width: "30%",
  marginLeft: "auto",
  marginRight: "auto",

  [breakpoints.up("sm")]: {
    marginRight: "initial",
  },
}));

const TypographyOverline = styled(Typography)(({}) => ({
  lineHeight: 2,
  color: "#ffffff",
  fontWeight: "bold",
  fontSize: "0.625rem",
  opacity: 0.7,
}));

const TypographyHeading = styled(Typography)(({}) => ({
  fontWeight: 900,
  color: "#ffffff",
  letterSpacing: 0.5,
}));

const StyledButton = styled(Button)(({ theme: { breakpoints } }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  borderRadius: 100,
  paddingLeft: 32,
  paddingRight: 32,
  color: "#ffffff",
  textTransform: "none",
  width: "100%",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.32)",
  },

  [breakpoints.up("sm")]: {
    width: "auto",
  },
}));

// Props interface with optional width and height
interface OfferCardProps {
  width?: string | number;
  height?: string | number;
}

export const OfferCard: React.FC<OfferCardProps> = ({ width, height }) => {
  return (
    <StyledCard width={width} height={height}>
      <CardMediaMedia>
        <UpgradeIcon sx={{ fontSize: 100, color: "#ffffff" }} />
      </CardMediaMedia>
      <CardContent>
        <TypographyOverline variant={"overline"}>Get more?</TypographyOverline>
        <TypographyHeading variant={"h6"} gutterBottom>
          From only $69
        </TypographyHeading>
        <StyledButton>Upgrade to Pro</StyledButton>
      </CardContent>
      {/* <CardActions>
        <StyledButton>Upgrade to Pro</StyledButton>
      </CardActions> */}
    </StyledCard>
  );
};
