import React, { useState, useEffect } from "react";
import {
  DialogContent,
  List,
  ListItem,
  ListItemText,
  InputBase,
  styled,
  Dialog,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MicIcon from "@mui/icons-material/Mic";
import CustomModal from "../modal";

// ToDO: ADD MORE SEARCH ITEMS & FIX PATHS/LINKS
const searchItems: SearchItem[] = [
  {
    title: "Post Details",
    path: "#/dashboard/post/details",
    action: "Details",
    description: "View details of your posts",
  },
  {
    title: "Create Post",
    path: "#/dashboard/post/new",
    action: "Create",
    description: "View details of your posts",
  },
  {
    title: "Edit Invoice",
    path: "#/dashboard/invoice/edit",
    action: "Edit",
    description: "View details of your posts",
  },
  {
    title: "Error Page",
    path: "#/500",
    action: "View",
    description: "View 500 error page",
  },
  {
    title: "Unauthorized Page",
    path: "#/403",
    action: "View",
    description: "View 403 unauthorized page",
  },
  {
    title: "Not Found ",
    path: "#/404",
    action: "View",
    description: "View 404 page not found error",
  },
  {
    title: "Lock Screen Page",
    path: "#/Lock",
    action: "View",
    description: "View Lock screen page",
  },
  {
    title: "Home Page",
    path: "#/Home",
    action: "View",
    description: "View App Home Page",
  },
  {
    title: "Dashboard Page",
    path: "#/Home",
    action: "View",
    description: "View App Dashboard Page",
  },
  {
    title: "Patients Details",
    path: "#/Patients",
    action: "Details",
    description: "View Patient Details",
  },
  {
    title: "Appointments Details",
    path: "#/Appointments",
    action: "Details",
    description: "View Appointments Details",
  },
  {
    title: "Store Details",
    path: "#/Pharmacy",
    action: "View",
    description: "View Pharmacy Store E-Commerce",
  },
  {
    title: "Labs Details",
    path: "#/Labs",
    action: "View",
    description: "View Labs Page",
  },
  {
    title: "User Details",
    path: "#/Users",
    action: "View",
    description: "View All Registered Users",
  },
  {
    title: "Chat Page",
    path: "#/Chat",
    action: "View",
    description: "Digital Consultation via Chat",
  },
  {
    title: "Registry Details",
    path: "#/Register",
    action: "View",
    description: "Go To View Registry Page",
  },
  {
    title: "App Documentation",
    path: "#/Documentation",
    action: "View",
    description: "View App Documentation",
  },
  {
    title: "Settings Details",
    path: "#/Settings",
    action: "View",
    description: "View Settings Page",
  },
  // Add more items as needed
];

interface SearchItem {
  title: string;
  path: string;
  action: string;
  description: string;
}

interface SearchProps {
  isOpen: boolean;
  onClose: () => void;
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  margin: theme.spacing(4, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const GlassyEffectStyle = {
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  backdropFilter: "blur(25px)",
  boxShadow: "none",
  borderRadius: "8px",
  overflow: "hidden",
};

// const StyledAutocomplete = styled(Autocomplete)({
//   width: "100%",
//   "& .MuiAutocomplete-inputRoot": {
//     color: "black",
//     '&[class*="MuiOutlinedInput-root"]': {
//       padding: 0,
//       "& .MuiAutocomplete-input:first-child": {
//         paddingLeft: "calc(1em + 40px)", // Adjust the value as necessary
//       },
//     },
//   },
// });

const SearchModal = ({ isOpen, onClose }: SearchProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [value, setValue] = useState<SearchItem | null>(null);
  const [inputValue, setInputValue] = useState("");

  // search logic: Filtering logic based on search input
  const filterItems = (query: string): SearchItem[] => {
    return searchItems.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
  };

  // search logic: Update search results whenever the input value changes
  useEffect(() => {
    if (inputValue) {
      const results = filterItems(inputValue);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [inputValue]);

  // Determine if we should show the 'Search Results' heading
  const showSearchResultsHeading = inputValue && searchResults.length === 0;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: GlassyEffectStyle }}
      BackdropProps={{
        sx: {
          backdropFilter: "blur(7px)", // Apply blur to the backdrop
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent backdrop
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SearchIconWrapper>
            <MicIcon style={{ color: "#423829" }} />
            <SearchIcon style={{ color: "#423829" }} />
          </SearchIconWrapper>

          <Autocomplete
            freeSolo
            id="search-autocomplete"
            options={searchItems}
            getOptionLabel={(option: any) => option.title}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <StyledInputBase
                {...params}
                autoFocus
                placeholder=" Write or speak to search..."
                // inputProps={{ "aria-label": "search" }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {params.InputProps.startAdornment}
                      {/* Preserve existing icons and styles */}
                    </>
                  ),
                }}
                fullWidth
              />
            )}
            fullWidth
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <ListItemText
                  primary={option.title}
                  secondary={option.description}
                />
              </Box>
            )}
          />
        </Box>

        {showSearchResultsHeading && (
          <Typography variant="h6" sx={{ my: 2, color: "#000" }}>
            No Results Found
          </Typography>
        )}
        <List>
          {searchResults.map((item, index) => (
            <ListItem button key={index} component="a" href={item.path}>
              <ListItemText primary={item.title} secondary={item.description} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SearchModal;
