import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import SearchBar from "../../bars/searchBar";
// import { Search as SearchIcon } from "@mui/icons-material";

const PatientListHead = (props) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    // do other search related actions
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
        }}
      >
        {/* <Button>Import</Button>
        <Button sx={{ mx: 1 }}>Export</Button> */}
        <Button color="primary" variant="contained">
          Dispense Drug
        </Button>
        <Button color="primary" variant="contained">
          Register Drug
        </Button>
      </Box>
      {/* SearchBox */}
      <Box style={{ width: "100%", mt: 3, mb: 2 }}>
        <SearchBar
          placeholder="Search Pharmacy"
          value={searchValue}
          onInputChange={handleSearchInputChange}
          fullWidth={true}
        />
        {/* <Card
          style={{
            background: "rgba(154, 109, 80, 0.1)",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            padding: "2px",
            marginTop: "10px",
            marginBottom: "10px",
            border: "none",
          }}
        >
          <CardContent>
            <Box
              style={{
                backgroundColor: "inherit",
                borderWidth: "thin",
                borderBottom: "solid",
                borderBottomColor: "rgba(53, 5, 15, 1)",
                borderBottomWidth: "thin",
              }}
            >
              <TextField
                fullWidth
                disableUnderline
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Pharmacy"
              />
            </Box>
          </CardContent>
        </Card> */}
      </Box>
    </Box>
  );
};

export default PatientListHead;
