import React, { useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Box,
  Paper,
} from "@mui/material";
import CustomModal from "../../components/modal";

interface Doctor {
  id: string;
  name: string;
}

interface Patient {
  id: string;
  name: string;
}

interface AppointmentFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (appointmentData: any) => void;
  doctors?: Doctor[];
  patients?: Patient[];
}

const AppointmentForm = ({
  isOpen,
  onClose,
  onSubmit,
  doctors = [],
  patients = [],
}: AppointmentFormProps) => {
  const [date, setDate] = useState(new Date());
  // const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [time, setTime] = useState("12.00");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [appointmentType, setAppointmentType] = useState("");
  const [doctor, setDoctor] = useState("");
  const [patient, setPatient] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const startDateTime = new Date(`${date}T${time}`);
    const appointment = {
      title,
      desc,
      date: startDateTime,
      appointmentType,
      doctor,
      patient,
    };
    onSubmit && onSubmit(appointment);
  };

  return (
    // <Modal open={isOpen} onClose={onClose}>
    <>
      <CustomModal open={isOpen} handleClose={onClose}>
        <div className="" style={{}}>
          <h2
            style={{
              marginTop: "0",
              marginBottom: "20px",
              fontSize: "34px",
              padding: "5px 40px 20px",
            }}
          >
            Book Appointment
          </h2>
          <FormGroup onSubmit={handleSubmit}>
            <TextField
              label="Title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Description"
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Date"
              type="date"
              // value={date}
              value={date.toISOString().slice(0, 10)}
              onChange={(e) => setDate(new Date(e.target.value))}
              InputLabelProps={{ shrink: true }}
              margin="normal"
              // style={{ width: "auto" }}
              fullWidth
            />
            <TextField
              label="Time"
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 minutes interval
              }}
              margin="normal"
              fullWidth
            />
            <FormControl style={{ width: "100%" }} margin="normal" fullWidth>
              <TextField
                select
                id="appointment-type-input"
                label="Appointment Type"
                value={appointmentType}
                onChange={(e) => setAppointmentType(e.target.value as string)}
              >
                <MenuItem value="Medical">Medical</MenuItem>
                <MenuItem value="Non-Medical">Non-Medical</MenuItem>
                <MenuItem value="checkup">Scheduled Checkup</MenuItem>
                <MenuItem value="checkup">Unscheduled Checkup</MenuItem>
                <MenuItem value="surgery">Surgery</MenuItem>
                <MenuItem value="consultation">General Consultation</MenuItem>
              </TextField>
            </FormControl>
            <br />
            <FormControl style={{ width: "100%" }}>
              <TextField
                select
                id="doctor-input"
                label="Doctor"
                onChange={(e) => setDoctor(e.target.value as string)}
              >
                {doctors.slice(0, 5).map((doctor) => (
                  <MenuItem key={doctor.id} value={doctor.id}>
                    {doctor.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <br />
            <FormControl style={{ width: "100%" }}>
              <TextField
                select
                id="patient-input"
                label="Patient"
                // value={patient}
                onChange={(e) => setPatient(e.target.value as string)}
              >
                {patients.slice(0, 5).map((patient) => (
                  <MenuItem key={patient.id} value={patient.id}>
                    {patient.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <br />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{ marginLeft: "8px" }}
              >
                Submit
              </Button>
            </Box>
          </FormGroup>
        </div>
      </CustomModal>
    </>
  );
};

export default AppointmentForm;
