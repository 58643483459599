// ----------------------------------------------------------------------
export const mockImgAvatar = (index) =>
  `/static/mock-images/avatars/avatar_${index}.jpg`;
export const mockImgProduct = (index, productName) =>
  `/static/mock-images/pharmacyImages/product_${index
    .toString()
    .padStart(4, "0")}_${productName}.png`;
export const mockImgCover = (index) =>
  `/static/mock-images/covers/cover_${index.toString().padStart(4, "0")}.png`;
export const mockImgTest = (index, testName) =>
  `/static/mock-images/labImages/test_${index
    .toString()
    .padStart(4, "0")}_${testName}.jpg`;
