// SecurityComponent.tsx
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const SecuritySettings = () => {
  // State for Multi-Factor Authentication
  const [mfaEnabled, setMfaEnabled] = useState(true);

  // State for Password Change Form
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Dummy data for Login History
  const loginHistory = [
    {
      device: "Chrome on Windows",
      location: "Lagos, NGA",
      date: "2023-10-01",
    },
    {
      device: "Safari on iOS",
      location: "Istanbul, TKY",
      date: "2023-09-15",
    },
    // Add more login history items as needed
  ];

  const handleMfaToggle = () => {
    setMfaEnabled((prev) => !prev);
  };

  const handleChangePassword = () => {
    // Add logic to handle password change
  };

  return (
    <Grid container spacing={3}>
      {/* First Row - Change Password */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Change Password</Typography>
            <TextField
              label="Current Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <TextField
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleChangePassword}
            >
              Save Changes
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* Second Row - Login History */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Login History</Typography>
            <List>
              {loginHistory.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={`${item.device} (${item.location})`}
                      secondary={`Last Login: ${item.date}`}
                    />
                  </ListItem>
                  {index < loginHistory.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>

      {/* Third Row - Multi-Factor Authentication */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Multi-Factor Authentication</Typography>
            <FormControlLabel
              control={
                <Switch checked={mfaEnabled} onChange={handleMfaToggle} />
              }
              label="Enable Multi-Factor Authentication"
            />
            {mfaEnabled && (
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Authentication Method</InputLabel>
                <Select label="Authentication Method">
                  <MenuItem value="authenticator">Authenticator App</MenuItem>
                  <MenuItem value="sms">SMS</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                </Select>
              </FormControl>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SecuritySettings;
