import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
  colors,
} from "@mui/material";
import { Icon } from "@iconify/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import twotoneDonutLarge from "@iconify/icons-ic/twotone-donut-large";
import ApexChart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import ApexOptions from "apexcharts";

const PREFIX = "AgeDemographyChart";

const classes = {
  root: `${PREFIX}-root`,
  differenceIcon: `${PREFIX}-differenceIcon`,
  differenceValue: `${PREFIX}-differenceValue`,
  chartContainer: `${PREFIX}-chartContainer`,
};

const AgeDemographyChart = () => {
  const theme = useTheme();

  const chartOptions: any = {
    chart: {
      type: "donut",
      height: "100%",
    },
    labels: [
      "Kids [0-12]",
      "Teens [12-20]",
      "Young [20-40]",
      "Mid-aged [40-60]",
      "Elderly [60+]",
    ],
    colors: [
      colors.blue[500],
      colors.purple[500],
      colors.green[500],
      colors.orange[500],
      colors.red[500],
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 650,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: "8px", // Adjust the font size as needed
      },
    },
    legend: {
      fontSize: "8px", // Adjust the font size as needed
    },
  };

  const chartSeries = [30, 20, 25, 15, 10]; // Example data, you should replace this with your actual data

  const Root = styled("div")(() => ({
    [`& .${classes.root}`]: {
      height: "90%",
      borderRadius: "8px",
    },

    [`& .${classes.differenceIcon}`]: {
      color: colors.green[900],
      marginLeft: theme.spacing(2),
    },

    [`& .${classes.differenceValue}`]: {
      color: colors.green[900],
      marginRight: theme.spacing(1),
    },

    [`& .${classes.chartContainer}`]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  return (
    <Root>
      <Card className={clsx(classes.root)}>
        <CardContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Typography color="textSecondary" variant="h6" gutterBottom>
                AGE DEMOGRAPHY
              </Typography>
              {/* Removed the Typography for the age category */}
            </Grid>

            <Grid item className={classes.chartContainer}>
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="donut"
                height={250}
              />
            </Grid>

            {/* <Box mt={2} display="flex" alignItems="center">
              <Icon
                icon={twotoneDonutLarge}
                className={classes.differenceIcon}
                style={{ marginRight: "2px" }}
              />
              <Typography className={classes.differenceValue} variant="body2">
                INTERACTIVE AGE DEMOGRAPHY
              </Typography>
            </Box> */}
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
};

export default AgeDemographyChart;
