import React, { useState, useEffect } from "react";
import "../components/login.css";
import { Form, Jumbotron } from "react-bootstrap";

import Home from "../pages/Home";
//import Patients from '../pages/patient/index';

import {
  HashRouter,
  // Link,
  Route,
  Switch,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { withTheme } from "@mui/styles";
import Routes from "../routes"; // this mport sometimes causes errors, be weary

import {
  Box,
  TextField,
  Button,
  Alert,
  InputAdornment,
  Link,
  IconButton,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { render } from "@testing-library/react";
//import Jumbotron from 'react-bootstrap/Jumbotron';

import logo from "../res/icons/logo-light.gif";
import logoDark from "../res/icons/logo-dark.gif";
//image/resources import below
import allhealth from "../res/images/360-health.jpeg";
import tele from "../res/images/telehealth.png";
import digi from "../res/images/digihealth.png";
import registerImage from "../res/images/Registration.png";
import waves1 from "../res/images/waves1.gif";

const GlassyTextField = styled(TextField)(({ theme }) => ({
  size: "small",
  background: "rgba(255, 255, 255, 0.15)",
  backdropFilter: "blur(15px)",
  borderRadius: "8px",
  border: "none",
  "& .MuiInputBase-input": {
    color: theme.palette.mode === "dark" ? "black" : "black", // Adjusts text color
    borderRadius: "8px",
    fontSize: "0.875rem",
  },
  "& .FormControl-root": {
    borderRadius: "8px !important",
  },
}));

// Dummy login credentials, replace with actual login logic
const dummyEmail = "guest@terakota.live";
const dummyPassword = "guest1234";

const Registration = () => {
  const [navigate, setNavigate] = useState(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const history = useHistory();

  const handleLogin = () => {
    if (email === dummyEmail && password === dummyPassword) {
      console.log("Logged in successfully");
      goToHome();
    } else {
      console.log(`Email or Password mismatch: ${email} and ${password}`);
      setError(true);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const goToHome = () => {
    console.log("on your way home");
    setNavigate(true);
  };

  useEffect(() => {
    if (navigate) {
      console.log("Redirect to home");
    }
  }, [navigate]); // Only re-run the effect if navigate changes

  if (navigate) {
    // The redirect can also be handled here depending on your routing setup
    return <Redirect to="/Home" />;
  }

  return (
    <div className="App column">
      {/* <header className="App-header" style={{ maxHeight:"100% !important"}}> */}
      <div>
        <Box
          className="left"
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <Box
            style={{
              backgroundColor: isDarkMode ? "#423829" : " rgb(160, 147, 129)",
              height: "100vh",
              width: "100%",
            }}
          >
            <div>
              <div className="jumbotron content">
                <h1>HEALTHCARE MANAGER</h1>
              </div>
              {/* <div className='Entry-background'>
                    <img src="https://www.ngonike.dev/portrait_gabriel.jpg" width="65%" height="400px"    />
                   </div> */}
              <Box
                sx={{
                  maxHeight: "520px",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${waves1})`,
                    backgroundSize: "cover",
                    opacity: 0.07,
                    zIndex: -1,
                  },
                  // Your existing styles here
                  backgroundColor: isDarkMode
                    ? "rgba(18, 18, 18, 0.85)"
                    : "rgba(255, 235, 205, 0.6)",
                  margin: "0px",
                  backdropFilter: "blur(15px)",
                  opacity: "0.95",
                  transition: "background-color 0.3s",
                  justifyContent: "center",
                }}
              >
                <ul
                //   className="services-bar"
                //   style={{
                //     backgroundColor: isDarkMode
                //       ? "rgb(18, 18, 18, 0.85)"
                //       : "rgb(255, 235, 205, 0.6)",
                //     margin: "0px",
                //     backdropFilter: "blur(15px)",
                //     opacity: "0.95",
                //     transition: "background-color 0.3s",
                //     justifyContent: "center",
                //   }}
                >
                  {/* <li id="services-bar">
                    <img src={allhealth} />
                    <h4>360 Healthcare Management</h4>
                  </li> */}
                  <li
                    id="services-bar"
                    // style={{ position: "relative", bottom: "80px" }}
                    style={{ position: "relative", listStyle: "none" }}
                  >
                    {/* <Box sx={{ maxHeight: "175px" }}> */}
                    <Box>
                      <img
                        src={registerImage}
                        style={{
                          height: "240px",
                          width: "240px",
                          paddingTop: "10px",
                        }}
                      />
                      <h4
                        style={{
                          paddingBottom: "10px",
                        }}
                      >
                        {" "}
                        Welcome to a <br />
                        Unique Experience{" "}
                      </h4>
                    </Box>
                  </li>
                  {/* <li id="services-bar">
                    <img src={digi} />
                    <h4> Omnipresent data </h4>
                  </li> */}
                </ul>
              </Box>
              <div
                className="services-footer"
                style={{
                  backgroundColor: isDarkMode
                    ? "#423829"
                    : " rgb(160, 147, 129)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <h6>
                  Copyright 2024. Admin & HealthCare Manager - Terakota.live{" "}
                  <br /> v1.1. Waves Edition
                </h6>
              </div>
            </div>
          </Box>
        </Box>

        <Box
          className="right"
          sx={{
            float: "none",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            opacity: "0.8",
            backdropFilter: "blur(15px)",
          }}
        >
          <Box
            className="app-logo"
            sx={{ background: "darkgrey", transform: "scaleX(-1)" }}
          >
            <img
              src={logoDark}
              alt="logo"
              height="230"
              width="200"
              style={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            />
          </Box>

          <h3 style={{ margin: "5px" }}>SIGN UP</h3>
          <Link href="#/Login" sx={{ display: "block", my: 2 }}>
            Already have an account? Sign in
          </Link>

          <Box sx={{ maxWidth: "280px" }}>
            {/* ... Your alerts if needed ... */}

            <GlassyTextField
              defaultValue={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
              type="text"
              fullWidth
              margin="normal"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="First name"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem", // Here you can set the font size
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px !important",
                },
              }}
            />
            <GlassyTextField
              defaultValue={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
              type="text"
              fullWidth
              margin="normal"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Last name"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem", // Here you can set the font size
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px !important",
                },
              }}
            />
            <GlassyTextField
              defaultValue={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              type="email"
              fullWidth
              margin="normal"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Email address"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem", // Here you can set the font size
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px !important",
                },
              }}
            />
            <GlassyTextField
              type={showPassword ? "text" : "password"}
              size="small"
              defaultValue={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {showPassword ? (
                      <VisibilityOff
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <Visibility
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              placeholder="Password"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem", // Here you can set the font size
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px !important",
                },
              }}
            />
            <Box sx={{ margin: "15px" }}></Box>
            <Button
              fullWidth
              variant="contained"
              sx={{ mb: 2, borderRadius: "8px" }}
              onClick={() => handleLogin()}
            >
              SIGN UP
            </Button>
            <Link href="#/" sx={{ display: "block", fontSize: "12px" }}>
              {" "}
              Return Home
            </Link>
          </Box>
        </Box>
      </div>

      {/* </header> */}
    </div>
  );
};

// export with withRouter to get the component to work e.g export default withRouter(yourComponent);
export default withTheme(withRouter(Registration));
