import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';
// import { v4 as uuid } from 'uuid';
// ----------------------------------------------------------------------


const patients = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  userRankId: sample([
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10'
  ]),
  userRole: sample([
    'Patient',
    'Nurse',
    'Doctor',
    'General Manager',
    'Lab',
    'Pharmacy',
    'Accounts',
    'Hospital Admin',
    'System Admin',
    'Dev System Admin'
  ]),
  registeredDate: faker.datatype.datetime(),
  avatarUrl: mockImgAvatar(index + 1),
  name: faker.name.fullName(),
  // name: ()=>{`${faker.name.firstName,faker.name.firstName}`},
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  phone: faker.phone.number(),
  address: faker.address.streetAddress(),
  dateOfBirth: faker.date.birthdate(),
  age: faker.datatype.number(),
  email: faker.internet.email(), 
  bloodType: sample(['A+', 'B+']),
  bloodGroup:sample(['O+', 'O-']),
  admitted: faker.datatype.boolean(),
  family: faker.datatype.boolean(),
  company: faker.company.name,
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  lastUpdate: faker.datatype.datetime(),
  patientHistory: sample(['Last Visted 19-01-03', 'Last Admitted 18/01/03']),
}));

export default patients;