// detect if the app is running in Electron:
// export const isElectron = () => {
//   // Simple check for Electron environment
//   return window && window.process && window.process.type;
// };

const isElectron = () => {
  return typeof window !== "undefined" && !!window.electron;
};

// Platform Detection - a function to detect the operating system when running in Electron
// const getPlatform = () => {
//   if (!isElectron()) return null;
//   return window.process.platform; // 'darwin', 'win32', 'linux'
// };
// const getPlatform = () => {
//   if (!isElectron()) return null;
//   return process.platform; // 'darwin', 'win32', 'linux'
// };
const getPlatform = () => {
  if (window.electron?.isMac) {
    return "darwin";
  } else if (window.electron?.isWindows) {
    return "win32";
  } else if (window.electron?.isLinux) {
    return "linux";
  }
};
// if (
//   typeof window !== "undefined" &&
//   window.process &&
//   window.process.platform
// ) {
//   return window.process.platform;
// }
// return null;
// };
// const getPlatform = () => {
//   // if (typeof process !== "undefined" && process.platform) {
//   //   return process.platform;
//   // }
//   // return null;
//   var osvar = process.platform;
//   if (osvar == "darwin") {
//     return "darwin";
//   } else if (osvar == "win32") {
//     return "win32";
//   } else if (osvar == "linux") {
//     return "linux";
//   }
// };
// const getCpuUsage = async () => {
//   try {
//     const cpuUsage =
//       await window.electron.ipcRenderer.cpuUsage(/* pass any required data */);
//     // const formattedCpuUsage = `${cpuUsage.toFixed(2)}%`;
//     // return formattedCpuUsage;
//     return cpuUsage;
//   } catch (error) {
//     console.error("Error getting CPU usage:", error);
//     return "Error"; // Return a default value or handle the error appropriately
//   }
// };
const getCpuUsage = async () => {
  // if (isElectron()) {
  try {
    const usage = await window.electron.ipcRenderer.cpuUsage();
    const overallUsage = usage.currentLoad;

    const formattedCpuUsage = `${overallUsage.toFixed(2)}%`;
    // console.log(formattedCpuUsage);
    return formattedCpuUsage;
  } catch (error) {
    console.error("Error getting CPU usage:", error);
    return "Error retrieving CPU usage";
  }
  // }
};
// setInterval(() => {
//   getCpuUsage();
// getCpuUsage().catch((error) => {
//   console.error("Unhandled promise rejection in getCpuUsage:", error);
// });
// }, 2000);

// launch Trigger: new Electron window using IPC comms;see electronMain for open-new-window IPC
// export const openNewWindow = (options) => {
//   console.log("openNewWindow called", options);
// if (!isElectron()) return;
// if (typeof window !== "undefined" && window.require) {
//   try {
//     const { ipcRenderer } = window.require("electron");
//     ipcRenderer.send("open-new-window", options);
//   } catch (error) {
//     console.error("Error opening new window:", error);
//   }
// }
// if (!isElectron()) return;

// const { ipcRenderer } = window.require("electron");
// ipcRenderer.send("open-new-window", { width: 800, height: 600 });
//   if (typeof window.electron !== "undefined") {
//     try {
//       window.electron.send("open-new-window", options);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   }
// };

// To run tasks without blocking the main process, you can use Electron's built-in support for
//  multi-threading with Web Workers or use Node.js child processes.

// export const openNewWindow = (options) => {
//   if (isElectron()) {
//     try {
//       const { ipcRenderer } = window.require("electron");
//       ipcRenderer.send("open-new-window", options);
//     } catch (error) {
//       console.error("Error in openNewWindow:", error);
//     }
//   }
// };
const openNewWindow = (options) => {
  if (isElectron()) {
    console.log("Sending IPC message to open new window", options);
    window.electron.openNewWindow(options);
  }
};

const openDocumentationWindow = (options, url) => {
  if (isElectron()) {
    console.log(
      "Sending IPC message to open documentation window",
      options,
      url
    );
    window.electron.openDocumentationWindow(options, url);
  }
};

const openToastNotification = (message, options) => {
  if (isElectron()) {
    console.log("Sending TOAST message to open new window", message);
    window.electron.openToastWindow(options, message);
  }
};

const logoutRedirect = () => {
  if (isElectron()) {
    console.log("sending IPC message to logout by rerouting to home /");
    window.electron.logoutRedirect();
  }
};

export {
  isElectron,
  openNewWindow,
  openDocumentationWindow,
  getPlatform,
  openToastNotification,
  logoutRedirect,
  getCpuUsage,
};

// const { exec } = window.require("child_process");

// export const runBackgroundTask = (command) => {
//   if (!isElectron()) return;

//   exec(command, (error, stdout, stderr) => {
//     if (error) {
//       console.error(`exec error: ${error}`);
//       return;
//     }
//     console.log(`stdout: ${stdout}`);
//     console.error(`stderr: ${stderr}`);
//   });
// };

// System Information: use the os module to get system information
// - see see electronMain for get-system-info IPC
// export const getSystemInfo = async () => {
//   if (!isElectron()) return;
//   try {
//     const { ipcRenderer } = window.require("electron");
//     return await ipcRenderer.invoke("get-system-info");
//   } catch (error) {
//     console.error("Error fetching system info:", error);
//   }
// };
