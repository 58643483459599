import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AvatarDefault from "../../res/images/avatar_default.jpg";

const UserSettings = () => {
  return (
    <Grid container spacing={3}>
      {/* First Row */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Avatar
                src={AvatarDefault}
                alt="Default Avatar"
                sx={{ width: 100, height: 100 }}
              />
              <Typography variant="h5" mt={2}>
                Gabriel Oni
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Role(s): Admin | Doctor
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Location: Lagos, NGA
              </Typography>
              <Box mt={2}>
                <Button variant="outlined" color="primary">
                  Edit Profile
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {/* Second Row */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    defaultValue="John Doe"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    defaultValue="john.doe@example.com"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Country"
                    variant="outlined"
                    defaultValue="Nigeria"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address"
                    variant="outlined"
                    defaultValue="123 Victoria Island"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Zipcode"
                    variant="outlined"
                    defaultValue="10001"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Date of Birth"
                    variant="outlined"
                    defaultValue="01/01/1990"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Company"
                    variant="outlined"
                    defaultValue="HealthCorp Inc."
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    defaultValue="+ (234) 123-4567"
                  />
                </Grid>
              </Grid>
              <Box mt={3}>
                <Button variant="contained" color="primary" type="submit">
                  Save Changes
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {/* Third Row */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Typography variant="h6">Change Image</Typography>
              <Box mt={2}>
                {/* Add file input for changing image */}
                <Button variant="outlined" color="primary" component="label">
                  Upload New Photo
                  <input type="file" hidden />
                </Button>
              </Box>
              <Box mt={2}>
                {/* Add file input for uploading files */}
                <Button variant="outlined" color="primary" component="label">
                  Upload Files
                  <input type="file" multiple hidden />
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserSettings;
