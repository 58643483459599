import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  useTheme,
  Theme,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const PREFIX = "SocialStats";

const classes = {
  root: `${PREFIX}-root`,
  differenceIcon: `${PREFIX}-differenceIcon`,
  differenceValue: `${PREFIX}-differenceValue`,
};

interface Props {}

const SocialStats = (props: Props) => {
  const theme = useTheme();
  const Root = styled("div")(() => ({
    [`& .${classes.root}`]: {
      height: "100%",
    },

    [`& .${classes.differenceIcon}`]: {
      color: colors.green[900],
      marginLeft: theme.spacing(2),
    },

    [`& .${classes.differenceValue}`]: {
      color: colors.green[900],
      marginRight: theme.spacing(1),
    },
  }));

  return (
    <Root>
      <Card className={clsx(classes.root)}>
        <CardContent>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6">
                SOCIAL FOLLOWING
              </Typography>
              <Typography color="textPrimary" variant="h4">
                50
              </Typography>
            </Grid>

            <Box mt={2} display="flex" alignItems="center">
              <ArrowUpwardIcon className={classes.differenceIcon} />
              <Typography className={classes.differenceValue} variant="body2">
                5%
              </Typography>
              <Typography color="textSecondary" variant="caption">
                Since last month
              </Typography>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
};

export default SocialStats;
