import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTheme, Theme } from "@mui/material/styles";
import {
  Box,
  Paper,
  Container,
  Tab,
  Tabs,
  Stack,
  Typography,
} from "@mui/material";
import {
  PharmacyListHead,
  PharmacyList,
  InventoryList,
} from "../../components/pharmacy";
import SortButton from "../../components/utility/sortButton";
import PRODUCTS from "../../_mockData_/pharmacy";
import createStyledComponent from "../../components/styled/styledcomponents";

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Pharmacy");

// const PREFIX = "Index";

// const classes = {
//   content: `${PREFIX}-content`,
//   tabs: `${PREFIX}-tabs`,
//   flexContainer: `${PREFIX}-flexContainer`,
//   tabPanel: `${PREFIX}-tabPanel`,
// };

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginTop: "2px" }}
      {...other}
    >
      {value === index && (
        <Box style={{ justifyContent: "flex-end" }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function Index() {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  // TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
  // const Root = styled("div")(() => ({
  //   [`& .${classes.content}`]: {
  //     flexGrow: 1,
  //     padding: theme.spacing(1),
  //     background:
  //       "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
  //     minHeight: "100vh",
  //   },

  //   [`& .${classes.tabs}`]: {
  //     // background:
  //     //   "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
  //     justifyContent: "flex-end",
  //     borderRadius: "5px",
  //     alignItems: "right",
  //     background: "rgba(154, 109, 80, 0.1)",
  //     boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
  //     backdropFilter: "blur(5px)",
  //     padding: "2px",
  //     marginTop: "10px",
  //     marginBottom: "10px",
  //     border: "none",
  //   },

  //   [`& .${classes.flexContainer}`]: {
  //     display: "flex",
  //     justifyContent: "flex-end",
  //   },

  //   [`& .${classes.tabPanel}`]: {
  //     // justifyContent: "flex-end !important",
  //     backdropFilter: "blur(5px)",
  //     backgroundColor: "rgba(255, 255, 255, 0.5)",
  //     borderRadius: "10px",
  //     boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  //     margin: "20px 0px",
  //   },
  // }));

  return (
    <Root className={classes.content} sx={{ padding: theme.spacing(2), height: "100vh" }}>
      <div className={classes.content}>
        {/* <p> Pharmacy</p> */}
        <Box
          sx={{
            // backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            <PharmacyListHead />
            <Box className={classes.tabPanel}>
              {/* <Paper square> */}
              <Stack
                direction="row"
                flexWrap="wrap-reverse"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ mb: 1 }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  flexShrink={0}
                  sx={{ my: 1 }}
                >
                  <SortButton />
                </Stack>
              </Stack>

              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                className={classes.tabs}
                classes={{ flexContainer: classes.flexContainer }}
              >
                <Tab label="Pharmacy" />
                <Tab label="Inventory" />
                {/* <Tab label="Tab 3" /> */}
              </Tabs>
              {/* </Paper> */}
              <TabPanel value={tabValue} index={0}>
                <Box>
                  <PharmacyList products={PRODUCTS} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {" "}
                <Box>
                  <InventoryList />
                  {/* {"dsdcsdcsdc"} */}
                </Box>{" "}
              </TabPanel>
              {/* <TabPanel value={tabValue} index={2}>
              {" "}
              <Box>Tab 3 Content</Box>{" "}
            </TabPanel> */}
            </Box>
          </Container>
        </Box>
      </div>
    </Root>
  );
}

export default Index;
