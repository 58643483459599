import React, { useState } from "react";
import {
  Button,
  IconButton,
  Grid,
  Switch,
  Typography,
  Card,
  CardContent,
  Box,
  Popover,
} from "@mui/material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  FormatTextdirectionLToR,
  FormatTextdirectionRToL,
  LightModeRounded,
  NightsStay,
  SettingsBrightness,
  Fullscreen,
  FullscreenExit,
} from "@mui/icons-material";
import { Language } from "@mui/icons-material";
import { Icon } from "@iconify/react";
// import flagForUnitedKingdom from '@iconify/icons-emojione-v1/flag-for-united-kingdom';
// import flagForFlagFrance from '@iconify/icons-twemoji/flag-for-flag-france';
// import flagForUnitedArabEmirates from '@iconify/icons-emojione-v1/flag-for-united-arab-emirates';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  toggleTheme,
  setTheme,
  toggleDirection,
  setDirection,
  setLanguage,
  ThemeMode,
} from "../../features/theme/themeSlice";
import { selectThemeMode } from "../../features/theme/themeSelectors";
import { fText } from "../../utils/formatText";
import { useTranslation } from "react-i18next";
// import store from "../../store";
import { ReactComponent as AIBotIcon } from "../../res/icons/AI-icon.svg";
import SlideBar from "../../bars/slideBar";
import { HexColorPicker } from "react-colorful";

interface SliderSettingsProps {
  settings: {
    darkMode: boolean;
    lightMode: boolean;
    highContrast: boolean;
    lowContrast: boolean;
    // Add other settings properties...
  };
  // Add style prop
  containerStyle?: React.CSSProperties;
  languageSettingsStyle?: React.CSSProperties;
  themeModeSettingsStyle?: React.CSSProperties;
  directionSettingsStyle?: React.CSSProperties;
  aiSettingsStyle?: React.CSSProperties;
  colorSettingsStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  // onToggle: (setting: string) => void;
}
type LanguageName = "English" | "French" | "Arabic";

interface LanguageOption {
  name: LanguageName;
  flag: JSX.Element; // Assuming the flag is a JSX element, adjust if needed
}

type PresetName = "Primary" | "Mainbar" | "Page";

interface PresetOption {
  name: PresetName;
  flag: JSX.Element; // Assuming the flag is a JSX element, adjust if needed
  selected?: boolean;
}

interface CircleIconProps {
  radius?: number;
  color?: string;
}

// const theme = useTheme();
const StyledCircle = styled("svg")<CircleIconProps>(
  ({
    theme,
    radius = 7,
    color = "linear-gradient(to bottom, black, white)",
  }) => ({
    width: `${2 * radius}px`,
    height: `${2 * radius}px`,
    borderRadius: "50%",
    background: color,
    border: `1px solid ${theme.palette.divider}`,
  })
);

const CircleIcon: React.FC<CircleIconProps> = ({ radius, color }) => {
  return <StyledCircle radius={radius} color={color} />;
};

// function SliderSettings({ settings, onToggle }: SliderSettingsProps) {
function SliderSettings({
  settings,
  containerStyle,
  languageSettingsStyle,
  themeModeSettingsStyle,
  directionSettingsStyle,
  aiSettingsStyle,
  colorSettingsStyle,
  footerStyle,
}: SliderSettingsProps) {
  const dispatch = useDispatch();
  const currentMode = useSelector((state: RootState) => state.theme.mode);
  const currentDirection = useSelector(
    (state: RootState) => state.theme.direction
  );
  // const darkMode = useSelector((state: RootState) => state.theme.mode);
  const [isLanguageVisible, setIsLanguageVisible] = useState(false);
  const [isColorPresetVisible, setIsColorPresetVisible] = useState(false);
  const activeLanguage = useSelector(
    (state: RootState) => state.theme.language || "English"
  );
  const [languages, setLanguages] = useState<LanguageOption[]>([
    {
      name: "English",
      flag: <Icon icon="emojione-v1:flag-for-united-kingdom" />,
    },
    { name: "French", flag: <Icon icon="emojione-v1:flag-for-france" /> },
    {
      name: "Arabic",
      flag: <Icon icon="emojione-v1:flag-for-united-arab-emirates" />,
    },
    // Add more languages as needed
  ]);

  const [colorPresets, setColorPresets] = useState<PresetOption[]>([
    {
      name: "Primary",
      flag: (
        <CircleIcon
          radius={10}
          color={currentMode === "dark" ? "#90caf9" : "#1976D2"}
        />
      ),
    },
    {
      name: "Mainbar",
      flag: (
        <CircleIcon
          radius={10}
          color={
            currentMode === "dark"
              ? "linear-gradient(to right, #423829, #63553e)"
              : "linear-gradient(to right, #ffebcd, #B3A287)"
          }
        />
      ),
    },
    {
      name: "Page",
      flag: (
        <CircleIcon
          radius={10}
          color={
            currentMode === "dark"
              ? "radial-gradient(circle, #1a1a1a 0%, #121212 100%)"
              : "radial-gradient(circle, rgba(248, 245, 241, 1) 3%, rgba(250, 248, 246, 1) 53%, rgba(255, 255, 255, 1) 100%)"
          }
        />
      ),
    },
    // Add more languages as needed
  ]);

  // const darkMode = useSelector(
  //   (state: RootState) => state.theme.mode === "dark"
  // );
  // const darkMode = useSelector(selectThemeMode);

  // Utility function to get the next theme mode
  const getNextThemeMode = (currentMode: ThemeMode): ThemeMode => {
    switch (currentMode) {
      case "light":
        return "dark";
      case "dark":
        return "system";
      case "system":
        return "light";
      default:
        return "system"; // Fallback to 'system' if current mode is undefined or invalid
    }
  };

  const handleToggle = () => {
    // dispatch(toggleTheme());
    const nextMode = getNextThemeMode(currentMode);
    dispatch(setTheme(nextMode));
  };

  const handleDirection = () => {
    dispatch(toggleDirection());
  };

  const toggleLanguage = () => {
    setIsLanguageVisible(!isLanguageVisible);
  };

  const toggleColorPreset = () => {
    setIsColorPresetVisible(!isColorPresetVisible);
  };

  const languageCodes: Record<LanguageName, string> = {
    English: "en",
    French: "fr",
    Arabic: "ar",
  };

  const selectLanguage = (selectedLanguage: LanguageName) => {
    const languageCode = languageCodes[selectedLanguage];
    dispatch(setLanguage(selectedLanguage));
    setIsLanguageVisible(false);
    setLanguages((prevLanguages) => {
      return prevLanguages.map((lang) => ({
        ...lang,
        selected: lang.name === selectedLanguage,
      }));
    });

    changeLanguage(languageCode);
    console.log(languageCode);

    // Dispatch an action to set the direction
    const direction = selectedLanguage === "Arabic" ? "rtl" : "ltr";
    dispatch(setDirection(direction));
  };

  const selectColorPreset = (selectedColorPreset: PresetName) => {
    // const languageCode = languageCodes[selectedLanguage];
    // dispatch(setLanguage(selectedLanguage));
    // setIsLanguageVisible(false);
    setColorPresets((prevColorPresets) => {
      return prevColorPresets.map((preset) => ({
        ...preset,
        selected: preset.name === selectedColorPreset,
      }));
    });

    closePopover();

    // changeLanguage(languageCode);
    // console.log(languageCode);

    // Dispatch an action to set the direction
    // const direction = selectedLanguage === "Arabic" ? "rtl" : "ltr";
    // dispatch(setDirection(direction));
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(console.log);
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(console.log);
        setIsFullScreen(false);
      }
    }
  };

  const themeIconStyle = (mode: string) => {
    // Determine the indicator color based on the current theme: lighter for dark mode, darker for light mode
    const indicatorColor =
      currentMode === "dark"
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.2)";

    return {
      alignItems: "center",
      display: "inline-flex",
      border: "1px solid rgba(145, 158, 171, 0.08)",
      borderRadius: "8px",
      cursor: "pointer",
      verticalAlign: "middle",
      backgroundColor: currentMode === mode ? indicatorColor : "transparent", // Use the indicator color for the active mode
      WebkitTapHighlightColor: "transparent",
      boxSizing: "border-box",
      position: "relative",
      justifyContent: "center",
      width: "70px",
      height: "60px",
      boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
      transition: "background-color 0.3s", // A transition for smooth color change
    };
  };
  const languageIconStyle = (language: LanguageOption) => {
    const indicatorColor =
      currentMode === "dark"
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.2)";

    return {
      alignItems: "center",
      display: "inline-flex",
      flexDirection: "column",
      border: "1px solid rgba(145, 158, 171, 0.08)",
      borderRadius: "8px",
      cursor: "pointer",
      verticalAlign: "middle",
      backgroundColor:
        activeLanguage === language.name ? indicatorColor : "transparent",
      WebkitTapHighlightColor: "transparent",
      boxSizing: "border-box",
      position: "relative",
      justifyContent: "center",
      width: "70px",
      height: "60px",
      boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
      transition: "background-color 0.3s",
    };
  };

  const colorIconStyle = (colorPresets: PresetOption) => {
    const indicatorColor =
      currentMode === "dark"
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.2)";

    return {
      alignItems: "center",
      // display: "inline-flex",
      display: "flex",
      flexDirection: "column",
      border: "1px solid rgba(145, 158, 171, 0.08)",
      borderRadius: "8px",
      cursor: "pointer",
      verticalAlign: "middle",
      // backgroundColor:
      //   activeLanguage === colorPresets.name ? indicatorColor : "transparent",
      WebkitTapHighlightColor: "transparent",
      boxSizing: "border-box",
      position: "relative",
      justifyContent: "center",
      width: "70px",
      height: "60px",
      boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
      transition: "background-color 0.3s",
    };
  };

  const directionIconStyle = (direction: string, currentDirection: string) => {
    // Determine the indicator color based on the current theme: lighter for dark mode, darker for light mode
    const indicatorColor =
      currentMode === "dark"
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.2)";

    return {
      alignItems: "center",
      display: "inline-flex",
      border: "1px solid rgba(145, 158, 171, 0.08)",
      borderRadius: "8px",
      cursor: "pointer",
      verticalAlign: "middle",
      backgroundColor:
        currentDirection === direction ? indicatorColor : "transparent", // Use the indicator color for the active direction
      WebkitTapHighlightColor: "transparent",
      boxSizing: "border-box",
      position: "relative",
      justifyContent: "center",
      width: "70px",
      height: "60px",
      boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
      transition: "background-color 0.3s", // A transition for smooth color change
    };
  };

  const { i18n } = useTranslation();

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode, (err) => {
      if (err) {
        console.error("changeLanguage error", err);
      } else {
        console.log(`Language changed to ${languageCode}`);
      }
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState("");

  const slideChatOpen = (component: any) => {
    setSelectedComponent(component);
    setIsOpen(true);
  };

  const slideChatClose = () => {
    setIsOpen(false);
    setSelectedComponent("");
  };

  const [isColorPickerVisible, setColorPickerVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>("#1976D2");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // anchorEl for hexcolorpicker popover
  // const selectColorPreset = (selectedColorPreset: string) => {
  //   // Implement your logic to handle color selection
  // };
  const openPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  // const toggleColorPicker = () => {
  //   setColorPickerVisible((prev) => !prev);
  // };

  return (
    <div
      className="settings-container"
      style={{ padding: "16px", ...containerStyle }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{
          maxHeight: "calc(100vh - 250px)",
          overflowY: "scroll",
          display: "flex",
          flexWrap: "wrap",
          ...containerStyle,
        }}
      >
        {/* 
        // Use commented below for non-Pro versions
         <Grid item xs={12}>
          <Typography variant="caption">Mode</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">Dark</Typography>
          <Switch
            color="primary"
            // checked={settings.darkMode}
            checked={darkMode}
            // checked={!darkMode}
            onChange={handleToggle}
          />
        </Grid> 
        
        <Typography>Toggle Dark Mode: {darkMode ? "On" : "Off"}</Typography> */}

        {/* Language Settings */}
        <Grid
          container
          spacing={1}
          sx={{ padding: "15px", ...languageSettingsStyle }}
        >
          <Grid item xs={12} sx={{ paddingLeft: "2px" }}>
            <Typography variant="caption">Language</Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item onClick={toggleLanguage}>
              <IconButton
                sx={{
                  alignItems: "center",
                  display: "inline-flex",
                  border: "1px solid rgba(145, 158, 171, 0.08)",
                  borderRadius: "8px",
                  cursor: "pointer",
                  verticalAlign: "middle",
                  backgroundColor: "transparent",
                  WebkitTapHighlightColor: "transparent",
                  boxSizing: "border-box",
                  position: "relative",
                  justifyContent: "center",
                  width: "70px",
                  height: "60px",
                  boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
                }}
              >
                <Language sx={{ fontSize: "20px" }} />
                <Typography sx={{ fontSize: "12px", paddingLeft: "3px" }}>
                  {fText(activeLanguage, 2, { uppercase: true })}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>

          {isLanguageVisible && (
            <Grid container spacing={1} sx={{ padding: "8px" }}>
              {languages.map((lang) => (
                <Grid item key={lang.name}>
                  <IconButton
                    key={lang.name}
                    onClick={() => selectLanguage(lang.name)}
                    sx={languageIconStyle(lang)}
                  >
                    {lang.flag}
                    <Typography sx={{ fontSize: "12px" }}>
                      {lang.name}
                    </Typography>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>

        {/* Theme Mode Settings */}
        <Grid
          container
          spacing={1}
          sx={{ padding: "15px", ...themeModeSettingsStyle }}
        >
          <Grid item xs={12} sx={{ paddingLeft: "2px" }}>
            <Typography variant="caption">Mode</Typography>
          </Grid>
          <Grid item>
            <IconButton
              sx={themeIconStyle("light")}
              onClick={() => dispatch(setTheme("light"))}
            >
              <LightModeRounded width="24px" height="24px" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={themeIconStyle("system")}
              onClick={() => dispatch(setTheme("system"))}
            >
              <SettingsBrightness
                width="24px"
                height="24px"
                sx={{ display: "inline-block" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={themeIconStyle("dark")}
              onClick={() => dispatch(setTheme("dark"))}
            >
              <NightsStay
                width="24px"
                height="24px"
                sx={{ display: "inline-block" }}
              />
            </IconButton>
          </Grid>
        </Grid>

        {/* Direction Settings */}
        <Grid
          container
          spacing={1}
          sx={{ padding: "15px", ...directionSettingsStyle }}
        >
          <Grid item xs={12} sx={{ paddingLeft: "2px" }}>
            <Typography variant="caption">Direction</Typography>
          </Grid>
          <Grid item>
            <IconButton
              // sx={{
              //   alignItems: "center",
              //   display: "inline-flex",
              //   border: "1px solid rgba(145, 158, 171, 0.08)",
              //   borderRadius: "8px",
              //   cursor: "pointer",
              //   verticalAlign: "middle",
              //   backgroundColor: "transparent",
              //   WebkitTapHighlightColor: "transparent",
              //   boxSizing: "border-box",
              //   position: "relative",
              //   justifyContent: "center",
              //   width: "70px",
              //   height: "60px",
              //   boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
              // }}
              // onClick={handleDirection}
              sx={directionIconStyle("ltr", currentDirection)} // Updated to use directionIconStyle
              onClick={() => dispatch(setDirection("ltr"))}
            >
              <FormatTextdirectionLToR width="24px" height="24px" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              // sx={{
              //   alignItems: "center",
              //   display: "inline-flex",
              //   border: "1px solid rgba(145, 158, 171, 0.08)",
              //   borderRadius: "8px",
              //   cursor: "pointer",
              //   verticalAlign: "middle",
              //   backgroundColor: "transparent",
              //   WebkitTapHighlightColor: "transparent",
              //   boxSizing: "border-box",
              //   position: "relative",
              //   justifyContent: "center",
              //   width: "70px",
              //   height: "60px",
              //   boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
              // }}
              // onClick={handleDirection}
              sx={directionIconStyle("rtl", currentDirection)} // Updated to use directionIconStyle
              onClick={() => dispatch(setDirection("rtl"))}
            >
              <FormatTextdirectionRToL width="24px" height="24px" />
            </IconButton>
          </Grid>
        </Grid>

        {/* AI Settings */}
        <Grid
          container
          spacing={1}
          sx={{ padding: "15px", ...aiSettingsStyle }}
        >
          <Grid item xs={12} sx={{ paddingLeft: "2px" }}>
            <Typography variant="caption"> AI </Typography>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                alignItems: "center",
                display: "inline-flex",
                border: "1px solid rgba(145, 158, 171, 0.08)",
                borderRadius: "8px",
                cursor: "pointer",
                verticalAlign: "middle",
                backgroundColor: "transparent",
                WebkitTapHighlightColor: "transparent",
                boxSizing: "border-box",
                position: "relative",
                justifyContent: "center",
                width: "70px",
                height: "60px",
                boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
              }}
              // onClick={handleDirection}
              // sx={directionIconStyle("ltr", currentDirection)} // Updated to use directionIconStyle
              // onClick={() => dispatch(setDirection("ltr"))}
              onClick={() => slideChatOpen("chat")}
            >
              <AIBotIcon width="65px" height="60px" />
            </IconButton>
          </Grid>
        </Grid>

        {/* Color Presets Settings */}
        <Grid
          container
          spacing={1}
          sx={{ padding: "15px", ...colorSettingsStyle }}
        >
          <Grid item xs={12} sx={{ paddingLeft: "2px" }}>
            <Typography variant="caption">Color Presets</Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item onClick={toggleColorPreset}>
              <IconButton
                sx={{
                  alignItems: "center",
                  display: "inline-flex",
                  // display: "flex", // Set display to flex
                  // flexDirection: "column",
                  border: "1px solid rgba(145, 158, 171, 0.08)",
                  borderRadius: "8px",
                  cursor: "pointer",
                  verticalAlign: "middle",
                  backgroundColor: "transparent",
                  WebkitTapHighlightColor: "transparent",
                  boxSizing: "border-box",
                  position: "relative",
                  justifyContent: "center",
                  width: "70px",
                  height: "60px",
                  boxShadow: "rgba(145, 158, 171, 0.08) -24px 8px 24px -4px",
                }}
              >
                {/* <Language sx={{ fontSize: "20px" }} /> */}
                <CircleIcon
                  radius={10}
                  color="linear-gradient(to left, black, white)"
                />
              </IconButton>
            </Grid>
          </Grid>

          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <HexColorPicker
              color={selectedColor}
              onChange={(color) => setSelectedColor(color)}
              style={{ zIndex: 1000, padding: "16px" }} // Ensure it appears above other elements
            />
          </Popover>

          {isColorPresetVisible && (
            <Grid container spacing={1} sx={{ padding: "8px" }}>
              {colorPresets.map((preset) => (
                <Grid item key={preset.name}>
                  <IconButton
                    key={preset.name}
                    // onClick={() => selectColorPreset(preset.name)}
                    onClick={(event) => {
                      selectColorPreset(preset.name);
                      // toggleColorPicker();
                      openPopover(event);
                    }}
                    sx={colorIconStyle(preset)}
                  >
                    {preset.flag}
                    <Typography
                      sx={{
                        fontSize: "8.5px",
                        paddingLeft: "3px",
                        flexShrink: 1,
                        textAlign: "center",
                        maxWidth: "100%",
                      }}
                    >
                      {preset.name}
                    </Typography>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        {/* Add other settings toggles... */}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ pt: 1, pb: 1, pl: 3 }}
        style={{
          position: "absolute",
          bottom: 55,
          alignSelf: "center",
          zIndex: 2,
          ...footerStyle,
        }}
      >
        <Button
          fullWidth
          size="large"
          onClick={handleFullScreen}
          color="inherit"
          variant="outlined"
          startIcon={isFullScreen ? <FullscreenExit /> : <Fullscreen />}
          sx={{ minWidth: "195.5px", alignItems: "center" }}
        >
          {isFullScreen ? "Minimize" : "Full Screen"}
        </Button>
      </Grid>
      <SlideBar
        isOpen={isOpen}
        onClose={slideChatClose}
        component={selectedComponent}
      />
    </div>
  );
}

SliderSettings.propTypes = {
  settings: PropTypes.shape({
    darkMode: PropTypes.bool.isRequired,
    lightMode: PropTypes.bool.isRequired,
    highContrast: PropTypes.bool.isRequired,
    lowContrast: PropTypes.bool.isRequired,
    // Add other settings PropTypes...
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default SliderSettings;
