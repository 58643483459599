import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Update as UpdateIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

const PREFIX = "UpcomingAppointments";

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.actions}`]: {
    justifyContent: "flex-end",
  },
}));

interface Props {}

const data = [
  {
    patid: 12,
    ref: "AP1049",
    time: 1230,
    patient: {
      name: "Cathy Tana",
    },
    booking: {
      name: "Dr. Rav",
    },
    status: "In progress",
    date: "2021-12-26",
  },
  {
    patid: 14,
    ref: "AP1049",
    time: 1430,
    patient: {
      name: "Herman Beck",
    },
    booking: {
      name: "Dr. Gabe",
    },
    status: "Pending",
    date: "2021-12-16",
  },
  {
    patid: 18,
    ref: "AP1049",
    time: 1600,
    patient: {
      name: "Raji Fash",
    },
    booking: {
      name: "Dr. Gabe",
    },
    status: "In progress",
    date: "2021-12-13",
  },
  {
    patid: 34,
    ref: "AP1049",
    time: "2100",
    patient: {
      name: "Nas Rufa",
    },
    booking: {
      name: "Dr. Shila",
    },
    status: "Pending",
    date: "2022-03-13",
  },
  {
    patid: 42,
    ref: "AP1049",
    time: "2100",
    patient: {
      name: "Amstron Malk",
    },
    booking: {
      name: "Dr. Josi",
    },
    status: "Pending",
    date: "2022-03-13",
  },
];

const UpcomingAppointments = (props: Props) => {
  const [upcoming] = useState(data);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <Card className={clsx(classes.root)}>
        <CardHeader title="Upcoming Appointments" />
        <Divider />

        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Appointment Ref</TableCell>
                <TableCell>Patient</TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip enterDelay={300} title="Sort">
                    <TableSortLabel active direction="desc">
                      Appointment
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>Booking</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {upcoming.map((upcoming) => (
                <TableRow hover key={upcoming.patid}>
                  <TableCell>{upcoming.ref}</TableCell>
                  <TableCell>{upcoming.patient.name}</TableCell>
                  <TableCell>
                    {moment(upcoming.time).format("h:mm a")},
                    {moment(upcoming.date).format("dddd Do MMMM")}
                  </TableCell>
                  <TableCell>{upcoming.booking.name}</TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      label={upcoming.status}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <IconButton onClick={handleMenuOpen}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={isMenuOpen}
                        onClose={handleMenuClose}
                        PaperProps={{
                          elevation: 0,
                          style: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 1px 6px rgba(0,0,0,0.32))",
                            // marginTop: 120,
                          },
                        }}
                      >
                        <MenuItem onClick={handleMenuClose}>
                          <EditIcon
                            fontSize="small"
                            style={{ marginRight: "8px" }}
                          />
                          Edit Status
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose}>
                          <UpdateIcon
                            fontSize="small"
                            style={{ marginRight: "8px" }}
                          />
                          Update
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose}>
                          <DeleteIcon
                            fontSize="small"
                            style={{ marginRight: "8px" }}
                          />
                          Delete
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Card>
    </Root>
  );
};

UpcomingAppointments.propTypes = {
  className: PropTypes.string,
};

export default UpcomingAppointments;
