// NoteTaker.tsx
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ReactQuill, { Quill } from "react-quill";
import { IconButton, Typography, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Update as UpdateIcon,
  Edit as EditIcon,
  Mic as MicIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customToolbar: {
    "& .ql-toolbar.ql-snow": {
      border: "1px solid rgb(204 204 204 / 40%) !important",
      borderRadius: "8px 8px 0px 0px !important",
    },
    "& .ql-toolbar.ql-snow + .ql-container.ql-snow": {
      border: "1px solid rgb(204 204 204 / 40%) !important",
      borderRadius: "0px 0px 8px 8px !important",
    },
  },
  // Other styles can go here
});

const NoteTaker: React.FC = () => {
  const [note, setNote] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNoteChange = (content: string) => {
    setNote(content);
  };

  // You can customize the toolbar using React Quill's modules
  // Here's an example of a simple toolbar configuration
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults
      [{ font: [] }],
      [{ align: [] }],

      ["clean"], // remove formatting
    ],
  };

  const editorStyle = {
    height: "100%",
    // maxHeight: "38vh",
    maxHeight: "75%",
  };

  return (
    <Box>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, height: 460 }}>
        <Box>
          <Typography color="textSecondary" variant="h6" gutterBottom>
            Note Taker
            <Box sx={{ marginLeft: "auto", marginRight: "0", float: "right" }}>
              <IconButton size="small" sx={{ padding: "3px" }}>
                <Tooltip title="Dictate your Note">
                  <MicIcon />
                </Tooltip>
              </IconButton>
              <IconButton onClick={handleMenuOpen} sx={{ padding: "3px" }}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                  elevation: 0,
                  style: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 1px 6px rgba(0,0,0,0.32))",
                    // marginTop: 120,
                  },
                }}
              >
                <MenuItem onClick={handleMenuClose}>
                  <EditIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Share Note
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <UpdateIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Save As
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Clear
                </MenuItem>
              </Menu>
            </Box>
          </Typography>
        </Box>
        <ReactQuill
          placeholder="Compose an Inspiring Note"
          value={note}
          onChange={handleNoteChange}
          style={editorStyle}
          theme="snow"
          className={classes.customToolbar}
          //   modules={modules}
        />
      </Paper>
    </Box>
  );
};

export default NoteTaker;
