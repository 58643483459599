// GeneralComponent.tsx
import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";

const GeneralSettings = () => {
  const [isSalaryAdjustmentVisible, setSalaryAdjustmentVisibility] =
    useState(false);

  const toggleSalaryAdjustment = () => {
    setSalaryAdjustmentVisibility(!isSalaryAdjustmentVisible);
  };

  return (
    <Grid container spacing={3}>
      {/* First Row - Admin Options */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Admin Options</Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 16 }}
            >
              Invite to Admin Role
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={toggleSalaryAdjustment}
            >
              Adjust Salary Range
            </Button>
            {/* Add other admin-specific options */}
          </CardContent>
        </Card>
      </Grid>

      {/* PopUp Row - Salary Adjustment */}
      {isSalaryAdjustmentVisible && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Adjust Salary Range</Typography>
              <form style={{ marginTop: 16 }}>
                <FormControl fullWidth>
                  <InputLabel>User to Adjust</InputLabel>
                  <Select>
                    <MenuItem value={1}>User 1</MenuItem>
                    <MenuItem value={2}>User 2</MenuItem>
                    <MenuItem value={3}>User 3</MenuItem>
                    {/* Add dynamic options based on available users */}
                  </Select>
                </FormControl>
                <TextField
                  label="Current Salary"
                  fullWidth
                  disabled
                  value="$50,000"
                />
                <TextField label="New Minimum Salary" fullWidth />
                <TextField label="New Maximum Salary" fullWidth />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16 }}
                >
                  Adjust Salary
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      )}

      {/* Second Row - User Profile Editing */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Edit User Profile</Typography>
            <FormControl fullWidth style={{ marginTop: 16 }}>
              <InputLabel>User to Edit</InputLabel>
              <Select>
                <MenuItem value={1}>User 1</MenuItem>
                <MenuItem value={2}>User 2</MenuItem>
                <MenuItem value={3}>User 3</MenuItem>
                {/* Add dynamic options based on available users */}
              </Select>
            </FormControl>
            <form style={{ marginTop: 16 }}>
              <TextField label="Name" fullWidth />
              <TextField label="Email" fullWidth />
              <TextField label="Country" fullWidth />
              <TextField label="Address" fullWidth />
              <TextField label="Zip Code" fullWidth />
              <TextField label="Date of Birth" fullWidth />
              <TextField label="Company" fullWidth />
              <TextField label="Phone Number" fullWidth />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
              >
                Save Changes
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {/* Fourth Row - Public Profile Switch */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Public Profile Settings</Typography>
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Turn on Public Profile"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GeneralSettings;
