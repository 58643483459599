import React, {
  useState,
  useEffect,
  FunctionComponent,
  Component,
  Suspense,
  lazy,
} from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import NProgress from "nprogress";

import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Login from "./components/login";

import Appointments from "./pages/appointments/index";
import Patients from "./pages/patient";
import Pharmacy from "./pages/pharmacy/index";
import User from "./pages/users/index";
import Consult from "./pages/chat/index";
import Accounts from "./pages/accounts/index";
import Settings from "./pages/settings/index";
import Labs from "./pages/labs/index";

import ErrorPage from "./pages/Error";
import NotFound from "./pages/NotFound";
import Unauthorized from "./pages/Unauthorized";
import LockScreen from "./pages/Lock";
import Registration from "./pages/Registration";

import Toast from "./components/utility/Toast";
import PortalManager from "./components/utility/PortalManager";
import Layout from "./bars/titlebar/Layout";

import Sidebar from "./bars/sideBar";

const PREFIX = "routes";

const classes = {
  container: `${PREFIX}-container`,
};

const StyledSettings = styled(Settings)({
  [`& .${classes.container}`]: {
    display: "flex",
    background:
      "radial-gradient(circle, rgba(169,169,169,1) 3%, rgba(255,235,205,1) 53%, rgba(160,147,129,1) 100%)",
    // height: "100vh",
    // width: "1000vw",
  },
});

// 1. Nprogress configurations
// 2. chnage Nprogress load bar button(see button color in App.css)
// 2.1. A component that starts the NProgress loader, stops the loader when route change completes
NProgress.configure({ showSpinner: false });

const RouteChangeHandler = withRouter(({ history }) => {
  const theme = useTheme();

  // useEffect to change the NProgress color to use the themes Primary ButtonColor
  React.useEffect(() => {
    const updateNProgressColor = () => {
      // Access the primary color from the theme
      const primaryColor = theme.palette.primary.main;

      // Create a style element if it doesn't exist
      let styleEl = document.getElementById("nprogress-custom-style");
      if (!styleEl) {
        styleEl = document.createElement("style");
        styleEl.id = "nprogress-custom-style";
        document.head.appendChild(styleEl);
      }

      // Update the style element to set the NProgress color
      styleEl.textContent = `#nprogress .bar {background: ${primaryColor} !important;}`;
    };

    updateNProgressColor();

    // If you want to update the color dynamically when the theme changes, you can add theme as a dependency
  }, [theme]);

  // useEffect to trigger NProgress load
  React.useEffect(() => {
    const handleStart = () => {
      console.log("Route change started");
      NProgress.start();
      setTimeout(() => NProgress.done(), 100);
    };

    const handleStop = () => {
      NProgress.done();
    };

    const unlisten = history.listen(() => {
      handleStart();
    });

    return () => {
      console.log("Route change completed");
      handleStop();
      unlisten();
    };
  }, [history]);

  return null;
});

const ToastPage = () => {
  // Retrieve the message from query parameters or other means
  const message = "Your message here";

  return (
    <Toast open={true} message={message} handleClose={() => window.close()} />
  );
};

function AppContainer() {
  return (
    <div>
      {/* <div className={classes.container}> */}
      {/* <header>
          <nav> */}
      <Sidebar>
        {/* <RouteChangeHandler /> */}
        {/* </nav>
        </header> */}
        {/* <main> */}
        <Route exact path="/Home" component={Home} />

        <Route path="/Appointments" component={Appointments} />
        <Route path="/Patients" component={Patients} />
        <Route path="/Pharmacy" component={Pharmacy} />
        <Route path="/Labs" component={Labs} />
        <Route path="/Users" component={User} />
        <Route path="/Chat" component={Consult} />
        <Route path="/Accounts" component={Accounts} />
        <Route path="/Settings" component={Settings} />
        {/* </main> */}
        {/* </div> */}
      </Sidebar>
      {/* <Route component={NotFound} /> */}
    </div>
  );
}

const LandingContainer = () => (
  <div className="container">
    {/* <Route path="/" component={Login} />
    <Route path="/Landing" component={Landing} /> */}
    {/* <Route path="/Login" component={Login} /> */}
    <Route path="/" component={Landing} />
  </div>
);

const LoginContainer = () => (
  <div className="container">
    {/* <Route path="/" component={Login} />
    <Route path="/Landing" component={Landing} /> */}
    <Route path="/Login" component={Login} />
  </div>
);

// const ToastContainer = () => (
//   <div className="container">
//     <Route path="/Secondary">
//       <PortalManager>
//         <Toast
//           message="Your toast message"
//           open={true}
//           handleClose={() => {}}
//         />
//       </PortalManager>
//     </Route>
//   </div>
// );

const PagesContainer = () => (
  <div className="container">
    {/* <Route path="/" component={Login} />
    <Route path="/Landing" component={Landing} /> */}
    {/* <Route path="/" component={} /> */}
    {/* <Route path="/403" component={Landing} /> */}
    <Route path="/404" component={NotFound} />
    <Route path="/500" component={ErrorPage} />
    <Route path="/403" component={Unauthorized} />
    <Route path="/Lock" component={LockScreen} />
    <Route path="/Registration" component={Registration} />
  </div>
);
// const PagesContainer = () => (
//   <div className="container">
//     <Route path="/Landing" component={Landing} />
//   </div>
// );

// path to documentation via Docusaurus
// const DocumentationContainer = () => (
// window.location.href = 'path/to/your/public/docusaurus/index.html';
// window.location.href = 'localhost:3000/index.html';
// return null;
// );

export default function Routes() {
  // render() {
  // const pathname = window.location.pathname; deprectaed after file restructure
  // const [isSecondaryWindow, setIsSecondaryWindow] = useState(false);

  // useEffect(() => {
  //   // Check if it's a secondary window
  //   const params = new URLSearchParams(window.location.search);
  //   setIsSecondaryWindow(params.get("Secondary") === "true");
  // }, []);

  return (
    <HashRouter>
      <Layout />
      <RouteChangeHandler />
      <Switch>
        {/* the routes are arranged in heirachy because for example loading Appcontainer first will disrupt display fo the other routes */}
        <Route exact path="/" component={LandingContainer} />
        <Route exact path="/Login" component={LoginContainer} />
        {/* Place more specific routes before less specific ones */}
        <Route path="/404" component={NotFound} />
        <Route path="/500" component={ErrorPage} />
        <Route path="/403" component={Unauthorized} />
        <Route path="/Lock" component={LockScreen} />
        <Route path="/Register" component={Registration} />
        {/* <Route component={PagesContainer} /> */}
        {/* AppContainer should be last since it includes less specific paths */}
        <Route path="/" component={AppContainer} />
        <Route component={NotFound} />
        {/* <Route component={PagesContainer} />
        <Route exact path="/" component={LandingContainer} />

        <Route exact path="/Login" component={LoginContainer} /> */}
        {/* <Route path="localhost:3000/index.html" component={DocumentationContainer}/> */}
        {/* <Route exact path="/Secondary" component={ToastContainer} /> */}
        {/* {isSecondaryWindow ? ( */}
        {/* <Route
          path="/Secondary"
          render={() => (
            <Toast
              open={true}
              message="Your toast message"
              handleClose={() => {}}
            />
          )}
        /> */}
        {/* ) : null} */}
        {/* <Route path="/Secondary">
          <PortalManager>
            <Toast
              message="Your toast message"
              open={true}
              handleClose={() => {}}
            />
          </PortalManager>
        </Route> */}
        {/* <Route component={AppContainer} /> */}
        {/* <Route exact path="/Toast" component={ToastPage} /> */}
        {/* <Route exact path="/" component={PagesContainer} /> */}
      </Switch>
    </HashRouter>
  );
  // }
}

//INITIAL ROUTES COMPONENT - had to retsurtcure to AppContainer and LoginCOntainer
// in order to get SIdeBar to display on only certain routes... see initial below
//also, see link in ReadMe for tutorial link
//
// export default class Routes extends Component {
//     render() {
//         // const pathname = window.location.pathname; deprectaed after file restructure
//         return (
//             <HashRouter>
//                 <Switch>

//                     <Route exact path="/Home" component={ Home } />

//                     <Route path="/Appointments" component={ Appointments } />
//                     <Route path="/Patients" component={ Patients } />
//                     <Route path="/Pharmacy" component={ Pharmacy } />
//                     <Route path="/pages/user/index" component={ User } />
//                     <Route path="/pages/chat/index" component={ Consult } />
//                     <Route path="/pages/register/index" component={ Register } />
//                     <Route path="/pages/settings/index" component={ Settings } />

//                   {/* remember! empty/main path "/" ought to come last to avoid page screen freeze or pages not showing up */}
//                     <Route path="/" component={ Login }  />
//               </Switch>
//             </HashRouter>
//         )
//     }
// }

// The below uses features on beta reactrouterdom v6 hence a switch bak to more stable ways
// uses api call features like Outlet and Navigate in the v6 and in app.tsx for easier routing
// consider in future updates/release
// export const routes = [
//     {
//         path: 'app',
//         element: <Home />,
//         children: [
//           { path: 'patient', element: <Patients /> },
//           { path: 'appointments', element: <Appointments /> },
//           { path: 'pharmacy', element: <Pharmacy /> },
//           { path: 'user', element: <User /> },
//           { path: 'consult', element: <Consult /> },
//           { path: 'register', element: <Register /> },
//           { path: 'settings', element: <Settings /> },
//         ]
//       }

// ];
