// SliderChat.tsx

import React, { useState, useRef } from "react";
import { styled } from "@mui/system";
import {
  Toolbar,
  Menu,
  MenuItem,
  Paper,
  Grid,
  AppBar,
  InputBase,
  Avatar,
  Badge,
  AvatarGroup,
  SwipeableDrawer,
  Skeleton,
  Container,
  FormControlLabel,
  Switch,
  Chip,
  Stack,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
// import chatBubbleOutline from "@iconify/icons-mdi/chat-bubble-outline";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  Send as SendIcon,
  Attachment as AttachmentIcon,
  Camera as CameraIcon,
  Phone as PhoneIcon,
  Videocam as VideocamIcon,
  MoreVert as MoreVertIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  VolumeOff as VolumeOffIcon,
  Mic,
  InsertInvitation,
} from "@mui/icons-material";
import { ReactComponent as AIBotIcon } from "../../res/icons/AI-icon.svg";
import { Global } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
// import { isElectron } from "../../electron/electronUtils";
const { isElectron } = require("../../electron/electronUtils");

const isRunningInElectron = isElectron();

// AvatarButton replaced MenuIcon with Avatar
export const AvatarButton = styled(IconButton)({
  marginRight: "8px",
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

// Styled component for the IconButton with dark theme icon colors
const darkIconStyle = { color: "rgba(0, 0, 0, 0.87)" };

// Styled component for the MessageInputField with the appropriate styling
const MessageInputField = styled(InputBase)(({ theme }) => ({
  flex: 1,
  marginLeft: "8px",
  marginRight: "8px",
  input: {
    padding: "10px 14px",
  },
  "input::placeholder": {
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

// Create a component for the left slide-out for selecting avatars
const AvatarsDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "rgb(0, 0, 0, 0.08)",
    backdropFilter: "blur(15px)",
    position: "absolute",
    width: "75px", // Set a slim width for the drawer
    // height: "85%", // Full height of the container
    // minHeight: "68vh",
    overflowX: "hidden",
    top: isRunningInElectron ? "217px" : "197px", // Assuming AppBar height is 64px
    left: "0",
    right: "auto",
    zIndex: "1000",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const MessageInterface = styled("div")({
  // position: "relative", // Needed for absolute positioning of the drawer
  // minHeight: "100vh", // Assuming full view height for the chat interface
  height: "100%",
});

interface Message {
  id: number;
  text: string;
  timestamp: Date;
}

interface SliderChatProps {
  isOpen: boolean;
  onClose: () => void;
}

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  // backgroundColor:
  // theme.palette.mode === "light" ? "#000" : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "light" ? "#000" : "#fff",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? "#000" : "#fff",
  borderRadius: 3,
  position: "relative",
  // top: 8,
  left: "calc(50% - 15px)",
  zIndex: "1400",
}));

function SwipeableEdgeDrawer(props: any) {
  const [drawerHeight, setDrawerHeight] = React.useState(""); // Initial drawer height
  const [open, setOpen] = React.useState(false);
  // States for toggle switches
  const [aiFeedback, setAiFeedback] = React.useState(false);
  const [listenMode, setListenMode] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const containerRef = React.useRef(null);

  // Effect to adjust the height whenever 'open' state changes
  React.useEffect(() => {
    if (open) {
      setDrawerHeight("400px");
    } else {
      setDrawerHeight("800px");
    }
  }, [open]);

  const SwipeableDrawerPaperProps = {
    style: {
      height: "19%",
      // height: "calc(100% - 70px)",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      left: "auto",
      right: "0",
      width: "280px",
      bottom: "60px",
    },
  };

  return (
    <Root ref={containerRef}>
      {/* <CssBaseline /> */}
      {/* <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: drawerHeight, // Use dynamic height
            overflow: 'visible',
            maxWidth: "350px"
          }
        }}
      /> */}

      {!open ? (
        <StyledBox
          sx={{
            position: "relative",
            display: "flex",
            // bottom: open ? drawerBleeding : 0,
            maxWidth: "280px",
            // top: 810,
            // bottom: "20",
            // bottom: "5vh",
            // minHeight: "10vh",
            // height: "15vh",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            left: "auto",
            right: "0",
            zIndex: 1300, // Ensure it's above other elements but below the drawer
          }}
        >
          <Puller onClick={toggleDrawer} />
        </StyledBox>
      ) : null}

      <SwipeableDrawer
        container={containerRef.current}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={SwipeableDrawerPaperProps}
      >
        <Puller onClick={toggleDrawer} sx={{ top: "8px" }} />
        <Typography sx={{ fontWeight: "600", p: 2, color: "text.secondary" }}>
          AI Options
        </Typography>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={aiFeedback}
                onChange={(event) => setAiFeedback(event.target.checked)}
              />
            }
            label="AI Feedback"
          />
          <FormControlLabel
            control={
              <Switch
                checked={listenMode}
                onChange={(event) => setListenMode(event.target.checked)}
              />
            }
            label="Activate Listen Mode"
          />
        </StyledBox>
        {/* <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Skeleton variant="rectangular" height="100%" />
        </StyledBox> */}
      </SwipeableDrawer>
    </Root>
  );
}

export const SliderChat: React.FC<SliderChatProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isAvatarsOpen, setAvatarsOpen] = useState(false);
  const [openSwipeable, setOpenSwipeable] = React.useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to handle opening the avatars slide-out
  const toggleAvatars = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAvatarsOpen(open);
  };

  // Menu Open and CLose for AvatarsDrawer
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  // Example messages
  const [messages, setMessages] = useState<Message[]>([
    { id: 1, text: "Hello, how can I help you?", timestamp: new Date() },
    // ...other messages
  ]);

  // Handle sending a message
  const sendMessage = (text: string) => {
    const newMessage: Message = {
      id: messages.length + 1,
      text,
      timestamp: new Date(),
    };
    setMessages([...messages, newMessage]);
  };

  // Sample commands and descriptions
  const commands = [
    { command: "Bring me Patient X's file", description: "Search Patients" },
    { command: "Show recent lab results", description: "Access Reports" },
    { command: "List medication schedule", description: "Medication Info" },
    {
      command: "Update appointment calendar",
      description: "Manage Appointments",
    },
  ];

  return (
    // <React.Fragment>
    <MessageInterface>
      <Grid item xs={12} md={4} sx={{ height: "100%" }}>
        {/* Chat Header */}
        <AppBar position="static" color="default" elevation={1}>
          <Toolbar>
            <AvatarButton
              edge="start"
              onClick={toggleAvatars(isAvatarsOpen ? false : true)}
            >
              <Avatar alt="Chat Bot">
                {" "}
                <AIBotIcon />{" "}
              </Avatar>
            </AvatarButton>
            <Typography variant="h6" fontSize="14px" style={{ flexGrow: 1 }}>
              Inspiring better healthcare
            </Typography>
            <IconButton onClick={handleMenuOpen} style={darkIconStyle}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={null}
              open={isMenuOpen}
              onClose={handleMenuClose}
              PaperProps={{
                elevation: 0,
                style: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  marginTop: 160,
                },
              }}
              // transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <MenuItem onClick={handleMenuClose}>
                <InsertInvitation
                  fontSize="small"
                  style={{ marginRight: "8px" }}
                />
                Invite
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
                Clear
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ArchiveIcon fontSize="small" style={{ marginRight: "8px" }} />
                Archive
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <VolumeOffIcon
                  fontSize="small"
                  style={{ marginRight: "8px" }}
                />
                Mute
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Paper
          elevation={3}
          style={{
            bottom: "60px",
            // padding: "16px",
            // height: "100%",
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // left: "calc(50% - 15px)",
            alignItems: "center",
            boxShadow: "none",
            maxWidth: "280px",
            width: "100%",
          }}
        >
          {/* Messages go here */}
          <Stack
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            // flexWrap="wrap"
            // spacing={1}
            // style={{
            //   marginTop: "auto", // Pushes the stack to the bottom
            //   padding: "6px", // Padding inside the paper for the stack
            //   gap: "6px", // Minimal gap between chips
            //   zIndex: "900",
            //   marginBottom: "12px",
            // }}
            direction="column" // Changed to column for vertical alignment
            justifyContent="center" // Center the items horizontally
            alignItems="center" // Center the items vertically
            spacing={1}
            style={{
              marginTop: "auto", // Pushes the stack to the bottom
              padding: "6px", // Padding inside the paper for the stack
              gap: "6px", // Minimal gap between chips
              zIndex: "900",
              marginBottom: "12px",
              minWidth: "100%",
            }}
          >
            {commands.slice(0, 2).map((item, index) => (
              <Chip
                key={index}
                label={
                  <>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: isSmallScreen ? "0.8rem" : "0.7rem",
                      }}
                    >
                      {item.command}
                    </div>
                    <div
                      style={{
                        fontSize: isSmallScreen ? "0.65rem" : "0.45rem",
                        // color: "rgba(0, 0, 0, 0.6)",
                        textAlign: "center",
                      }}
                    >
                      {item.description}
                    </div>
                  </>
                }
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)", // Light grey background for chips
                  border: "none",
                  maxWidth: "100%",
                  // width: "auto", // Allow chips to take up only as much width as they need
                  width: "100%",
                  marginLeft: "0px",
                }}
                clickable
                size="small"
              />
            ))}
          </Stack>
        </Paper>

        <Paper
          elevation={3}
          style={{
            // padding: "8px",
            display: "flex",
            // alignItems: "center",
            // zIndex: "1600",
            position: "absolute",
            // position: "fixed",
            bottom: "65px",
            // height: "",
            // left: 0,
            // right: 0,

            left: "calc(50% - 15px)",
            // right: "0",
          }}
        >
          <SwipeableEdgeDrawer />
        </Paper>

        {/* Message Input */}
        <Paper
          elevation={3}
          style={{
            padding: "8px",
            display: "flex",
            alignItems: "center",
            zIndex: "1600",
            // position: "relative",
            position: "fixed",
            bottom: 0,
            // left: 0,
            // right: 0,
          }}
        >
          <IconButton color="inherit" style={darkIconStyle}>
            <AttachmentIcon />
          </IconButton>
          <MessageInputField
            placeholder="Type a message..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{ color: "rgba(0, 0, 0, 0.87)" }}>
                  <Mic />
                </IconButton>
                <IconButton
                  style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  onClick={handleMenuOpen}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Paper>

        {/* Avatars Drawer */}
        <AvatarsDrawer variant="persistent" anchor="right" open={isAvatarsOpen}>
          <List>
            {/* You can map over your avatars data to render list items */}
            <ListItem button sx={{ paddingLeft: "8px" }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="Remy Sam">
                  <AIBotIcon />
                </Avatar>
              </StyledBadge>
            </ListItem>
            <ListItem button sx={{ paddingLeft: "8px" }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <SmallAvatar alt="Remy Maam">
                    {" "}
                    <AIBotIcon />
                  </SmallAvatar>
                }
              >
                <Avatar
                  alt="Tari Howi"
                  src="/static/mock-images/avatars/avatar_15.jpg"
                />
              </Badge>
            </ListItem>
            <ListItem button sx={{ paddingLeft: "8px" }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  alt="Remy Sir"
                  src="/static/mock-images/avatars/avatar_22.jpg"
                />
              </StyledBadge>
            </ListItem>
            <ListItem button sx={{ paddingLeft: "8px" }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <SmallAvatar
                    alt="Remarly"
                    src="/static/mock-images/avatars/avatar_8.jpg"
                  />
                }
              >
                <Avatar
                  alt="Tari Howi"
                  src="/static/mock-images/avatars/avatar_7.jpg"
                  sx={{ width: "27px", height: "30px" }}
                />
                <SmallAvatar alt="Remy Maam">
                  {" "}
                  <AIBotIcon />
                </SmallAvatar>
              </Badge>
            </ListItem>
            {/* Add more ListItems as needed */}
          </List>
        </AvatarsDrawer>
      </Grid>
      {/* </ChatContainer> */}
    </MessageInterface>
    // {/* // </StyledDrawer> */}
    // </React.Fragment>
  );
};
