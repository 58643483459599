// SliderNotification.tsx
import React from "react";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { sub, formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import { faker } from "@faker-js/faker";

const mockImgAvatar = (id: any) =>
  `/static/mock-images/avatars/avatar_${id}.jpg`;

export const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: "Your order is placed",
    description: "waiting for shipping",
    avatar: null,
    type: "order_placed",
    createdAt: sub(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.findName(),
    description: "answered to your comment on the patient transfer",
    avatar: mockImgAvatar(2),
    type: "friend_interactive",
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new message",
    description: "5 unread messages",
    avatar: null,
    type: "chat_message",
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new mail",
    description: "sent from Guido Padberg",
    avatar: null,
    type: "mail",
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "Delivery processing",
    description: "Your order for drugs is being shipped",
    avatar: null,
    type: "order_shipped",
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

const renderContent = (notification: any) => {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  const NOTIFICATION_TYPES = {
    ORDER_PLACED: "order_placed",
    ORDER_SHIPPED: "order_shipped",
    MAIL: "mail",
    CHAT_MESSAGE: "chat_message",
  };

  let avatar;

  switch (notification.type) {
    case NOTIFICATION_TYPES.ORDER_PLACED:
      avatar = (
        <img
          alt={notification.title}
          src="/static/mock-images/icons/package-icon2.svg"
          style={{ width: "35px", height: "35px" }}
        />
      );
      break;
    case NOTIFICATION_TYPES.ORDER_SHIPPED:
      avatar = (
        <img
          alt={notification.title}
          src="/static/mock-images/icons/shipping-icon.svg"
          style={{ width: "35px", height: "35px" }}
        />
      );
      break;
    case NOTIFICATION_TYPES.MAIL:
      avatar = (
        <img
          alt={notification.title}
          src="/static/mock-images/icons/mail-message-icon.svg"
          style={{ width: "35px", height: "35px" }}
        />
      );
      break;
    case NOTIFICATION_TYPES.CHAT_MESSAGE:
      avatar = (
        <img
          alt={notification.title}
          src="/static/mock-images/icons/chat-icon.svg"
          style={{ width: "35px", height: "35px" }}
        />
      );
      break;
    default:
      avatar = <img alt={notification.title} src={notification.avatar} />;
  }

  // Add conditions for other notification types after adding them to the
  // NOTIFICATION_TYPES function above...
  return { avatar, title };
};

//PropTypes for React only use
// NotificationItem.propTypes = {
//   notification: PropTypes.object.isRequired,
// };
interface Notification {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
}

interface NotificationItemProps {
  notification: Notification;
}

function NotificationItem({ notification }: NotificationItemProps) {
  const { avatar, title } = renderContent(notification);
  const timeAgo = formatDistanceToNow(new Date(notification.createdAt), {
    addSuffix: true,
  });

  return (
    <ListItemButton
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box sx={{ mr: 0.5, width: 16, height: 16 }}>
              <Icon icon="eva:clock-fill" />
            </Box>
            {timeAgo}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

//PropTypes for React only use
// SliderNotification.propTypes = {
//   notifications: PropTypes.array.isRequired,
// };
interface SliderNotificationProps {
  notifications: any[];
}

function SliderNotification({ notifications }: SliderNotificationProps) {
  return (
    <div
      className="notifications-container"
      style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
    >
      {NOTIFICATIONS.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
    </div>
  );
}

export default SliderNotification;
