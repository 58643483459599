import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  ButtonGroup,
  Avatar,
} from "@mui/material";
import {
  FilterList,
  ChevronLeft,
  ChevronRight,
  MoreVert,
  Message,
  Visibility,
  Pause,
  Block,
} from "@mui/icons-material";
import { UserData } from "../../_mockData_/users";

const UsersList: React.FC<{ users: UserData[] }> = ({ users }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = users.map((user) => user.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleSelectOneClick = (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <Paper elevation={3} sx={{ marginBottom: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FilterList />}
          size="small"
        >
          Filter
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < users.length
                  }
                  checked={users.length > 0 && selected.length === users.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>Avatar</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Date of Last Interaction</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              const isItemSelected = isSelected(user.id);
              return (
                <TableRow
                  key={user.id}
                  hover
                  selected={isItemSelected}
                  onClick={(event) => handleSelectOneClick(event, user.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell>
                    <Avatar alt={user.name} src={user.avatarUrl} />
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.userRole}</TableCell>
                  <TableCell>{user.contact}</TableCell>
                  <TableCell>
                    {user.lastInteraction.toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      aria-label="Actions"
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 1,
                        style: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))",
                          //   boxShadow: "none",
                        },
                      }}
                    >
                      <MenuItem>
                        <IconButton
                          color="primary"
                          size="small"
                          style={{ marginRight: 8 }}
                        >
                          <Message fontSize="small" />
                        </IconButton>
                        Message
                      </MenuItem>
                      <MenuItem>
                        <IconButton
                          color="primary"
                          size="small"
                          style={{ marginRight: 8 }}
                        >
                          <Visibility fontSize="small" />
                        </IconButton>
                        View More
                      </MenuItem>
                      <MenuItem>
                        <IconButton
                          color="warning"
                          size="small"
                          style={{ marginRight: 8 }}
                        >
                          <Block fontSize="small" />
                        </IconButton>
                        Suspend
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="subtitle2" fontWeight="bold">
          Showing <b>{selected.length}</b> out of <b>{users.length}</b> entries
        </Typography>
        <Box>
          <ButtonGroup>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ChevronLeft />}
              size="small"
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ChevronRight />}
              size="small"
            >
              Next
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Paper>
  );
};

export default UsersList;
