// src/components/Toast.tsx
import React from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Button from "@mui/material/Button";

interface ToastProps {
  open: boolean;
  message: string;
  actionOne?: string;
  actionTwo?: string;
  handleAction?: () => void;
  handleClose: () => void;
  position?: SnackbarOrigin;
  borderRadius?: string;
  backdropFilter?: string;
  boxStyle?: React.CSSProperties;
  messageStyle?: React.CSSProperties;
  actionStyle?: React.CSSProperties;
  actionStyleTwo?: React.CSSProperties;
}

const Toast: React.FC<ToastProps> = ({
  open,
  message,
  handleAction,
  handleClose,
  position = { vertical: "top", horizontal: "center" },
  borderRadius = "4px",
  backdropFilter = "blur(0px)",
  boxStyle = { height: "100%" },
  actionOne = "LOGOUT",
  actionTwo = "CLOSE",
  messageStyle = {},
  actionStyle = {},
  actionStyleTwo = {},
}) => {
  return (
    <Snackbar
      anchorOrigin={position}
      open={open}
      onClose={handleClose}
      message={<span style={messageStyle}>{message}</span>}
      action={
        <React.Fragment>
          <Button
            color="secondary"
            size="small"
            onClick={handleAction}
            style={actionStyle}
          >
            {actionOne}
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={handleClose}
            style={actionStyleTwo}
          >
            {actionTwo}
          </Button>
        </React.Fragment>
      }
      style={{ borderRadius, backdropFilter, ...boxStyle }}
    />
  );
};

export default Toast;
