import React, { useState } from "react";
import {
  Card,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { Icon, InlineIcon } from "@iconify/react";
import AvatarDefault from "../../res/images/avatar_default.jpg";
// import userPlus from '@iconify-icons';
// import editSolid from '@iconify-icons/fa-solid/edit';
// import editRegular from '@iconify-icons/fa-regular/edit';

const EditProfileCardWidget = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("Nnah Gabriel");
  const [role, setRole] = useState("Senior Software Engineer");
  const [location, setLocation] = useState("New York, USA");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const handleLocationChange = (event: any) => {
    setLocation(event.target.value);
  };

  return (
    <Box>
      <Card elevation={3}>
        <div
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            backgroundSize: "cover",
            height: "200px",
            display: "block",
            backgroundImage:
              "url(https://scx2.b-cdn.net/gfx/news/2017/ocean.jpg)",
            position: "relative",
            zIndex: 1, // Set a lower z-index for the background image
          }}
          onClick={handleOpen}
        ></div>
        <Box>
          <img
            src={AvatarDefault}
            alt="Default Avatar"
            style={{
              display: "flex",
              width: 100,
              height: 100,
              objectFit: "cover",
              borderRadius: "50%",
              margin: "-100px auto 0px",
              border: "4px solid #fff",
              justifyContent: "center",
              overflowClipMargin: "content-box",
              verticalAlign: "middle",
              position: "relative",
              zIndex: 2, // Set a higher z-index for the profile image
            }}
          />
        </Box>

        {/* Image Upload Dialog */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Profile ? </DialogTitle>
          <DialogContent>
            {/* Your image upload form can go here */}
            {/* <Typography>Image Upload </Typography> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Change Cover</Button>
            <Button onClick={handleClose} color="primary">
              Change Avatar
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Box>
  );
};

export default EditProfileCardWidget;
