import React, { useState } from "react";
import { faker } from "@faker-js/faker";
import PropTypes from "prop-types";
// material
import {
  IconButton,
  Tooltip,
  Card,
  Typography,
  CardHeader,
  CardContent,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot,
} from "@mui/lab";
// utils
import { fDateTime } from "../../utils/formatTime";

// ----------------------------------------------------------------------

const TIMELINES = [
  {
    title: "General Checkup",
    time: faker.date.past(),
    type: "order1",
  },
  {
    title: "Eye CheckUp",
    time: faker.date.past(),
    type: "order2",
  },
  {
    title: "Consultation ",
    time: faker.date.past(),
    type: "order3",
  },
  {
    title: "On Admission for 2 weeks",
    time: faker.date.past(),
    type: "order4",
  },
  {
    title: "Pharmacy Purchase",
    time: faker.date.past(),
    type: "order5",
  },
];

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool,
};

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor:
              (type === "order1" && "primary.main") ||
              (type === "order2" && "success.main") ||
              (type === "order3" && "info.main") ||
              (type === "order4" && "warning.main") ||
              "error.main",
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <Tooltip
        title={
          <>
            <div>{`Visit Type: ${"Appointment"}`}</div>
            <div>{`Visit ID: ${"AF - 12032"}`}</div>
            <div>{`TimeIn: ${"10:45am"}`}</div>
            <div>{`Date: ${"00-12-20" || "None"}`}</div>
            <div>{`Transactions?: ${"Yes" ? "Yes" : "No"}`}</div>
            <div>{`Assigned Doctor: ${"Dr. Gabe" || "None"}`}</div>
            <div>{`Occupant: ${"N/A" || "None"}`}</div>
            <div>
              <IconButton
                aria-label="View more"
                onClick={handleDialogOpen}
                size="small"
                color="primary"
              >
                <Typography> View More</Typography>
                <Add />
              </IconButton>
            </div>
          </>
        }
        arrow
      >
        <TimelineContent>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {fDateTime(time)}
          </Typography>
        </TimelineContent>
      </Tooltip>
    </TimelineItem>
  );
}

export default function HistoryTimeline() {
  return (
    <Card
      sx={{
        "& .MuiTimelineItem-missingOppositeContent:before": {
          display: "none",
        },
      }}
    >
      {/* <CardHeader title="Order Timeline" /> */}
      <CardContent>
        <Timeline>
          {TIMELINES.map((item, index) => (
            <OrderItem
              key={item.title}
              item={item}
              isLast={index === TIMELINES.length - 1}
            />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}
