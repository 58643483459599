import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
  useTheme,
  makeStyles,
} from "@mui/material/styles";
import SideBar from "../bars/sideBar";
//import Footer from '../bars/footer.js';
import {
  HashRouter,
  // Switch,
  Route,
  Redirect,
  Link,
  useLocation,
} from "react-router-dom";
// import Routes from '../routes';
import {
  AppBar,
  CssBaseline,
  Grid,
  Typography,
  MenuItem,
  Menu,
  Toolbar,
  Button,
  Box,
  Container,
  Theme,
  Chip,
  IconButton,
  Paper,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tab,
  Tabs,
  Tooltip,
  Fab,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Switch,
  Checkbox,
  TextField,
  Card,
  CardActions,
  CardContent,
  Alert,
} from "@mui/material";
// import styled from '@emotion/styled';
import createStyledComponent from "../components/styled/styledcomponents";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  AppsRounded as AppsIcon,
  ArrowCircleRight,
  Info as InfoIcon,
  Close as CloseIcon,
  ArrowDropUpRounded as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  MenuRounded as MenuRoundedIcon,
  ExpandMore as ExpandMoreIcon,
  Check as CheckIcon,
  SpeedRounded as SpeedIcon,
  Tune as TuneIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  DashboardRounded as DashboardIcon,
  ListRounded as ListIcon,
  DescriptionRounded as DescriptionIcon,
  FiberManualRecordRounded as FiberManualRecordIcon,
} from "@mui/icons-material";
import { Icon } from "@iconify/react";
import reactIcon from "@iconify/icons-logos/react";
import muiIcon from "@iconify/icons-logos/material-ui";
import typescriptIcon from "@iconify/icons-logos/typescript-icon";
import electronIcon from "@iconify/icons-logos/electron";
import reduxIcon from "@iconify/icons-logos/redux";
import nodejsIcon from "@iconify/icons-logos/nodejs";

import { green, red } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import SlideBar from "../bars/slideBar";

import DashboardLogo from "../res/images/Dashboard.png";
import TerakotaLogo from "../res/icons/logo512.png";
import TerakotaTextLogo from "../res/icons/text-logo-1.png";
import FeatureLongLight from "../res/animations/FeatureLongLight.gif";
import FeatureLongDark from "../res/animations/FeatureLongDark.gif";
import EaseOfDevelopment from "../res/images/EOD-Terakota.png";
import waves1 from "../res/images/waves1.gif";
import CarouselPC from "../res/images/CarouselPC.png";
import CarouselMac from "../res/images/CarouselMac.png";
import CarouselWeb from "../res/images/CarouselWeb.png";
import AiUi from "../res/images/AI-UI.png";
import { ReactComponent as Wave } from "../res/icons/wave.svg";
import { ReactComponent as WaveDark } from "../res/icons/wave-dark.svg";

import Revenue from "../components/stats/revenueStats";
import PatientStats from "../components/stats/patientStats";
import SocialStats from "../components/stats/socialStats";
// import AgeDemography from "../components/stats/charts/ageDemography";
import CustomStats from "../components/stats/charts/customStats";
import {
  chartOptions,
  chartSeries,
} from "../components/stats/charts/chartData";
// import { isElectron, openDocumentationWindow } from "../electron/electronUtils";
const {
  isElectron,
  openDocumentationWindow,
} = require("../electron/electronUtils");

// Use an empty string or null for pages without a prefix
const { Root, classes } = createStyledComponent("Home");

const plans = [
  {
    name: "Freemium",
    features: [
      { text: "One end project", included: true },
      { text: "12 months updates", included: true },
      { text: "6 months of support", included: true },
      { text: "TypeScript version", included: false },
      { text: "Design assets", included: false },
      { text: "Commercial applications", included: false },
    ],
  },
  {
    name: "Standard",
    features: [
      { text: "One end project", included: true },
      { text: "12 months updates", included: true },
      { text: "6 months of support", included: true },
      { text: "TypeScript version", included: false },
      { text: "Design assets", included: false },
      { text: "Commercial applications", included: false },
    ],
  },
  {
    name: "Standard Plus",
    features: [
      { text: "One end project", included: true },
      { text: "12 months updates", included: true },
      { text: "6 months of support", included: true },
      { text: "TypeScript version", included: true },
      { text: "Design assets", included: false },
      { text: "Commercial applications", included: false },
    ],
  },
  // ... Add Standard and Standard Plus plans
];

const faqContent = [
  {
    question: "Is there a free demo of TERAKOTA available?",
    answer:
      "Yes, explore our freemium open-source dashboard template for an insight into our code quality and structure. Note that some features differ in the paid version.",
  },
  {
    question: "What makes TERAKOTA cross-platform?",
    answer:
      "Built with Electron and modern web technologies, TERAKOTA enables developers to easily deploy for Mac, PC, or Web, ensuring a unified codebase. Tools like Slack and VSCode also leverage Electron. Our documentation offers comprehensive guidance for deployment. The Electron version comes with the Standard Plus version. This is a frontend template. While the Stack enables easy backend attachment, Out of the box this template does not come with a backend though it may be requested as a custom service via our support. ",
  },
  {
    question: "What are the usage limits and licensing terms of TERAKOTA?",
    answer:
      "Each TERAKOTA license covers one project domain. Planning multiple projects? Reach out to us for a tailored solution. Request an Extended license via support which is available for commercial projects.",
  },
  {
    question: "Which browsers are supported?",
    answer:
      "TERAKOTA's components are compatible with the latest versions of Chrome, Firefox, Safari, and Edge. We do not support Internet Explorer 11.",
  },
  {
    question: "Who should use the Standard and Standard Plus license?",
    answer:
      "The Standard license suits internal applications like a pharmacy's admin dashboard for staff to manage inventory, customers, and users. It's ideal for non-public-facing applications and is restricted to the online Web implementation. The Standard Plus license comes with the full Electron app which enable easy build for cross platforms. ",
  },
  {
    question: "Are Design Resources available?",
    answer:
      "Design resources are available in TERAKOTA version 2.1 and above for eligible licenses. v2.1. is released in first quarter 2024. Holders of Standard Plus Licenses are eligible for the v2.1. update including Deisgn Resources.",
  },
];

const featureOptions = [
  {
    label: "Modern Technologies",
    description:
      "TERAKOTA is engineered using the latest web technologies, ensuring a robust and scalable application.",
  },
  {
    label: "Ease of Development",
    description:
      "Develop your app with ease making use of our developer friendly features, components, elements and libraries accessible throughout our fluid and dynamic stack.",
  },
  {
    label: "Robust Components",
    description:
      "Expedite your development and design process with TERAKOTA's rich array of MUI components and diverse elements. Reimagine our components in diverse ways like easily customizing TERAKOTA to fit your branding needs.",
  },
  {
    label: "CRA + Typescript + Electron",
    description:
      "One of a kind template Structured with Create React App, Typescript and Electron, TERAKOTA offers a seamless cross platform development experience.",
  },
  {
    label: "UI For AI",
    description:
      "Reimagining UI enabling fluid Interaction with AI. The dual nature of this app template facilitates quick AI integration whilst enabling an web(online) or local(offline) implementation for your convenience and freedom.",
  },
  // {
  //   label: "Design Files",
  //   description:
  //     "Access Figma files to expedite your design process with TERAKOTA's rich UI elements.",
  // },
];

const pagesSubmenuItems = [
  {
    name: "Login",
    icon: <FiberManualRecordIcon sx={{ fontSize: "small", width: "0.6em" }} />,
    href: "/login",
  },
  {
    name: "403",
    icon: <FiberManualRecordIcon sx={{ fontSize: "small", width: "0.6em" }} />,
    href: "/403",
  },
  {
    name: "404",
    icon: <FiberManualRecordIcon sx={{ fontSize: "small", width: "0.6em" }} />,
    href: "/404",
  },
  {
    name: "500",
    icon: <FiberManualRecordIcon sx={{ fontSize: "small", width: "0.6em" }} />,
    href: "/500",
  },
];

const FeatureContent = ({ featureIndex }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  if (featureIndex === 0) {
    let iconSize = "large"; // default size
    if (isMobile) {
      iconSize = "small"; // smaller size for mobile
    } else if (isTablet) {
      iconSize = "medium"; // medium size for tablet
    }

    const icons = [
      { icon: reactIcon, label: "React" },
      { icon: muiIcon, label: "MUI" },
      { icon: typescriptIcon, label: "TypeScript" },
      { icon: reduxIcon, label: "Redux" },
      { icon: electronIcon, label: "Electron" },
      { icon: nodejsIcon, label: "NodeJS" },
      // ... other icons
    ];

    return (
      <>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {icons.map(({ icon, label }) => (
            <div style={{ textAlign: "center", marginRight: "25px" }}>
              <Icon
                icon={icon}
                style={{
                  color: "your-color",
                  fontSize:
                    iconSize === "large"
                      ? "65px"
                      : iconSize === "medium"
                      ? "35px"
                      : "20px",
                }}
              />
              <div style={{ fontSize: "16px" }}>{label}</div>
            </div>
          ))}
        </div>
      </>
    );
  }
  if (featureIndex === 1) {
    return (
      <>
        <img
          src={EaseOfDevelopment}
          alt="Ease of Development"
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "400px",
            maxHeight: "180px",
          }}
        />
      </>
    );
  }
  if (featureIndex === 2) {
    return (
      <>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center", gap: "10px" }}
        >
          {/* Column 1 - Radio, Switch, Checkbox */}
          <Grid item spacing={2}>
            <Grid item xs={3}>
              <Radio />
            </Grid>
            <Grid item xs={3}>
              <Switch />
            </Grid>
            <Grid item xs={3}>
              <Checkbox />
            </Grid>
          </Grid>

          {/* Column 2 - TextFields */}
          <Grid item xs={3}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <TextField label="Sample Input 1" variant="outlined" />
              </Grid>
              <Grid item>
                <TextField label="Sample Input 2" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>

          {/* Column 3 - Basic Card */}
          <Grid item xs={3} spacing={2}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Sample Card
                </Typography>
                <Typography variant="body2">
                  Basic MUI card component
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Column 4 - Alert and Button */}
          <Grid item xs={3} spacing={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Alert severity="success">Success Alert!</Alert>
              </Grid>
              <Grid item>
                <Button variant="contained">Button Sample</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  if (featureIndex === 3) {
    return (
      <>
        <Grid
          container
          // spacing={2}
          direction="row"
          style={{ margin: "0 auto", maxWidth: "95%" }}
        >
          {/* Row 1: Electron Build for Mac */}
          <Grid item xs={12}>
            <pre style={{ marginBottom: "0px" }}>
              <code>
                {`# Build command for Electron to package App for Mac
electron-builder build --mac`}
              </code>
            </pre>
          </Grid>

          {/* Row 2: NPM Build for Web */}
          <Grid item xs={12}>
            <pre style={{ marginBottom: "0px" }}>
              <code>
                {`# Build command to package App for web
npm run build`}
              </code>
            </pre>
          </Grid>

          {/* Row 3: Electron Build for Windows */}
          <Grid item xs={12}>
            <pre style={{ marginBottom: "0px" }}>
              <code>
                {`# Build command to package App for PC/Windows
electron-builder build --win`}
              </code>
            </pre>
          </Grid>
        </Grid>
      </>
    );
  }
  if (featureIndex === 4) {
    return (
      <>
        <img
          src={AiUi}
          alt="UI for AI"
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "170px",
            maxHeight: "180px",
          }}
        />
      </>
    );
  }
};

const carouselImages = [
  { src: CarouselPC, alt: "PC/Windows Carousel", title: "PC/WINDOWS APP" },
  { src: CarouselWeb, alt: "Web Carousel", title: "WEB APP" },
  { src: CarouselMac, alt: "Mac Carousel", title: "MAC OS APP" },
  // ... other images
];

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: 25,
  margin: theme.spacing(1, 1),
  display: "inline-flex",
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledMenu = styled(Box)`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  transition: background-color 0.3s;
  background-color: rgba(66, 56, 41, 0.4);
  backdrop-filter: blur(15px);
  z-index: 10;
  padding: 20px;
  margin-top: ${(props) => (isElectron() ? "24px" : "0")};
`;
// background-color: ${({ isScrolled }) =>
//   isScrolled ? "rgba(255, 255, 255, 0.9)" : "transparent"};

const LeftBox = styled(Box)`
  flex: 1;
  padding: 5px;
  padding-top: 15px;
  background-color: primary.light;
`;

const CenterBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const RightBox = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-right: 10px;
  background-color: secondary.light;
`;

const MenuButton = styled(({ isSmallScreen, ...otherProps }) => (
  <Box {...otherProps} />
))`
  display: ${({ isSmallScreen }) => (isSmallScreen ? "block" : "none")};
  background-color: grey.300;
`;

// const useStyles = styled((theme) => ({
//   navlinks: {
//     marginLeft: theme.spacing(5),
//     display: "flex",
//   },
//   logo: {
//     flexGrow: "1",
//     cursor: "pointer",
//   },
//   link: {
//     textDecoration: "none",
//     color: "white",
//     fontSize: "20px",
//     marginLeft: theme.spacing(20),
//     "&:hover": {
//       color: "yellow",
//       borderBottom: "1px solid white",
//     },
//   },
// }));
const StyledWave = styled(Wave)(({ theme }) => ({
  width: "100%",
  height: "auto",
  position: "absolute",
  left: 0,
  bottom: 0,
  "& path": {
    animation: "$moveWave 10s ease-in-out infinite alternate",
  },
  "@keyframes moveWave": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-20px)",
    },
  },
}));

const StyledDarkWave = styled(WaveDark)(({ theme }) => ({
  width: "100%",
  height: "auto",
  position: "absolute",
  left: 0,
  bottom: 0,
  "& path": {
    animation: "$moveWave 10s ease-in-out infinite alternate",
  },
  "@keyframes moveWave": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-20px)",
    },
  },
}));

const componentMapping = {
  Revenue: Revenue,
  PatientStats: PatientStats,
  // AgeDemography: AgeDemography,
  CustomStats: () => (
    <CustomStats
      title="MONTHLY VISITS"
      improvement={10}
      percentageIncrease={10.5}
      currentFigure="15,837"
      chartOptions={chartOptions}
      chartSeries={chartSeries}
    />
  ),
  CustomStats2: () => (
    <CustomStats
      title="PORTFOLIO"
      improvement={-10} // You can customize this value based on your requirement
      percentageIncrease={-10.5} // You can customize this value based on your requirement
      currentFigure="5,837" // You can customize this value based on your requirement
      chartOptions={chartOptions}
      chartSeries={chartSeries}
      chartType="bar"
    />
  ),
  SocialStats: SocialStats,
  // Add other component mappings here
};

const componentStyles = [
  // Component 1 Style (Top-left component)
  {
    componentKey: "Revenue",
    display: "none !important",
    position: "absolute",
    width: "250px", // Adjust the width as necessary
    top: "30%",
    left: "5%",
    transform:
      "translate(0px, 20px) perspective(5200px) rotateX(90deg) rotateY(-45deg) rotateZ(-45deg)",
    animation: "2.8s ease-in-out 1.2s infinite alternate none running float",
    zIndex: 1,
    // additional responsive properties below
    "@media (max-width:790px)": {
      // Adjust for mobile screens
      top: "25%", // Adjust top position for mobile
      left: "35%", // Center component horizontally
      width: "200px", // Optional: Adjust width for smaller screens
      transform:
        "translate(-50%, 20px) perspective(5200px) rotateX(90deg) rotateY(-45deg) rotateZ(-45deg)",
      // display: "none", // Or hide if it shouldn't be visible on mobile
    },
  },
  // Component 2 Style (Top-center component, slightly lower)
  {
    componentKey: "CustomStats2",
    position: "absolute",
    width: "300px",
    top: "40%",
    left: "calc(50% - 150px)", // Center the component
    transform:
      "translate(0px, 20px) perspective(5000px) rotateX(85deg) rotateY(-40deg) rotateZ(-40deg)",
    animation: "3s ease-in-out 1s infinite alternate none running float",
    zIndex: 2,
    "@media (max-width:790px)": {
      // width: "300px",
      // top: "50% !important",
      // left: "calc(50% - 150px)",
      // transform:
      //   "translate(-50%, 0) perspective(5000px) rotateX(85deg) rotateY(-40deg) rotateZ(-40deg)",
      // position: "relative !important",
      position: "relative", // Removed !important for best practices
      top: "50%",
      left: "50%",
      transform:
        "translate(-50%, -50%) perspective(5000px) rotateX(85deg) rotateY(-40deg) rotateZ(-40deg)",
      width: "90%",
      // display: "none",
    },
  },
  // Component 3 Style (Top-right component)
  {
    componentKey: "Revenue",
    display: "none !important",
    position: "absolute",
    width: "250px",
    top: "30%",
    right: "3%", // Positioned from the right
    transform:
      "translate(0px, 20px) perspective(5100px) rotateX(88deg) rotateY(-42deg) rotateZ(-42deg)",
    animation: "2.6s ease-in-out 0.8s infinite alternate none running float",
    zIndex: 3,
    "@media (max-width:790px)": {
      top: "25%",
      right: "50%",
      transform:
        "translate(50%, 0) perspective(5100px) rotateX(88deg) rotateY(-42deg) rotateZ(-42deg)",
      // display: "none",
    },
  },
  // Component 4 Style (Middle-left component)
  {
    componentKey: "Revenue",
    position: "absolute",
    width: "280px",
    top: "50%",
    left: "6%",
    transform:
      "translate(0px, 30px) perspective(5300px) rotateX(92deg) rotateY(-48deg) rotateZ(-48deg)",
    animation: "3.2s ease-in-out 1.5s infinite alternate none running float",
    zIndex: 4,
    "@media (max-width:790px)": {
      top: "40% !important",
      left: "50% !important",
      transform:
        "translate(-50%, 0) perspective(5300px) rotateX(92deg) rotateY(-48deg) rotateZ(-48deg)",
      display: "none",
    },
  },
  // Component 5 Style (Middle-center component, higher up)
  {
    componentKey: "PatientStats",
    position: "absolute",
    width: "320px",
    top: "55%",
    left: "calc(50% - 160px)", // Center the component
    transform:
      "translate(0px, 10px) perspective(5400px) rotateX(87deg) rotateY(-43deg) rotateZ(-43deg)",
    animation: "3.4s ease-in-out 1.3s infinite alternate none running float",
    zIndex: 5,
    "@media (max-width:790px)": {
      width: "320px",
      top: "65% !important",
      left: "calc(50% - 160px) !important",
      transform:
        "translate(-50%, 0) perspective(5400px) rotateX(87deg) rotateY(-43deg) rotateZ(-43deg)",
      // display: "none",
    },
  },
  // Component 6 Style (Middle-right component)
  {
    componentKey: "SocialStats",
    position: "absolute",
    width: "280px",
    top: "50%",
    right: "3%", // Positioned from the right
    transform:
      "translate(0px, 30px) perspective(5200px) rotateX(90deg) rotateY(-45deg) rotateZ(-45deg)",
    animation: "3s ease-in-out 1s infinite alternate none running float",
    zIndex: 6,
    "@media (max-width:790px)": {
      top: "40%",
      right: "50%",
      transform:
        "translate(50%, 0) perspective(5200px) rotateX(90deg) rotateY(-45deg) rotateZ(-45deg)",
      display: "none",
    },
  },
  // Component 7 Style (Bottom-left component)
  {
    componentKey: "Revenue",
    display: "none !important",
    position: "absolute",
    width: "260px",
    bottom: "10%",
    left: "5%",
    transform:
      "translate(0px, 50px) perspective(5100px) rotateX(85deg) rotateY(-40deg) rotateZ(-40deg)",
    animation: "2.8s ease-in-out 1.2s infinite alternate none running float",
    zIndex: 7,
    "@media (max-width:790px)": {
      bottom: "5%",
      left: "50%",
      transform:
        "translate(-50%, 0) perspective(5100px) rotateX(85deg) rotateY(-40deg) rotateZ(-40deg)",
      // display: "none",
    },
  },
  // Component 8 Style (Bottom-center component, slightly lower)
  {
    componentKey: "CustomStats",
    position: "absolute",
    width: "300px",
    bottom: "8%",
    left: "calc(50% - 150px)", // Center the component
    transform:
      "translate(0px, 50px) perspective(5300px) rotateX(92deg) rotateY(-48deg) rotateZ(-48deg)",
    animation: "3.2s ease-in-out 1.5s infinite alternate none running float",
    zIndex: 8,
    "@media (max-width:790px)": {
      width: "300px",
      bottom: "3%",
      left: "calc(50% - 150px)",
      transform:
        "translate(-50%, 0) perspective(5300px) rotateX(92deg) rotateY(-48deg) rotateZ(-48deg)",
      // display: "none",
    },
  },
  // Component 9 Style (Bottom-right component)
  {
    componentKey: "Revenue",
    display: "none !important",
    position: "absolute",
    width: "260px",
    bottom: "10%",
    right: "5%", // Positioned from the right
    transform:
      "translate(0px, 50px) perspective(5400px) rotateX(87deg) rotateY(-43deg) rotateZ(-43deg)",
    transformStyle: "preserve-3d",
    animation: "3.4s ease-in-out 1.3s infinite alternate none running float",
    zIndex: 9,
    "@media (max-width:790px)": {
      bottom: "5%",
      right: "50%",
      transform:
        "translate(50%, 0) perspective(5400px) rotateX(87deg) rotateY(-43deg) rotateZ(-43deg)",
      // display: "none",
    },
  },
  // ... Add four more style objects with different positioning and z-index
];

// Define the styles for the floating animation
const floatAnimation = `@keyframes float {
0%, 100% { transform: translateY(0) skew(-20deg); }
50% { transform: translateY(-20px) skew(-20deg); }
}`;

const StyledComponentsBox = styled(Box)(({ theme, isMobile, ...props }) => ({
  // position: "absolute",
  position: props.position,
  // top: isMobile ? "initial" : props.top,
  top: props.top,
  // left: isMobile ? "50%" : props.left,
  left: props.left,
  // transform: isMobile
  //   ? `translate(-50%, ${props.topMobileTranslate || "0px"}) scale(${
  //       props.scaleMobile || "1"
  //     })`
  //   : props.transform,
  transform: props.transform,
  display: isMobile && props.hideOnMobile ? "none" : "block",
  zIndex: props.zIndex,
  // width: isMobile ? "90%" : props.width,
  width: props.width,
  filter: "drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px)",
  animation: props.animation,
  // Other styles...
}));

const MemoizedStyledComponentsBox = React.memo(StyledComponentsBox);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Landing = (onClose, props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  // const styledClasses = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isRunningInElectron = isElectron();
  const location = useLocation();
  // const backgroundColor =
  //   location.pathname === "/Home"
  //     ? `${theme.palette.primary.main}31`
  //     : "`rgb(40,20,90, 0.8)`";
  const [value, setValue] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState(featureOptions[0]);
  const isDarkMode = theme.palette.mode === "dark";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 10);
  };

  const handleDocsClick = () => {
    const isRunningInElectron = isElectron();
    const isDevelopment = process.env.NODE_ENV === "development";
    const docsUrl = isDevelopment
      ? "http://localhost:3010/docs/intro"
      : "https://terakota.live/documentation/docs";

    if (isRunningInElectron) {
      // In Electron, open in secondary window
      openDocumentationWindow(
        {
          width: 800,
          height: 600,
          webPreferences: {
            nodeIntegration: false,
            contextIsolation: true,
          },
        },
        docsUrl
      );
    } else {
      // In web, open in a new browser tab
      window.open(docsUrl, "_blank");
    }

    onClose(); // Assuming onClose is a function to close the menu
  };

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [anchorDirection, setAnchorDirection] = useState("left"); // Add this state
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [slideState, setSlideState] = useState({
    isOpen: false,
    component: "",
  });

  const openSlideBar = (component) => {
    setSlideState({ isOpen: true, component });
  };

  // Unified function to close SlideBar
  const closeSlideBar = () => {
    setSlideState({ isOpen: false, component: "" });
  };

  const slideMenuOpen = (component, direction) => {
    setSelectedComponent(component);
    setAnchorDirection(direction);
    setIsOpen(true);
  };

  const slideMenuClose = () => {
    setIsOpen(false);
    setSelectedComponent("");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [offset, setOffset] = useState(0);
  // const [offset1, setOffset1] = useState(-70);
  // const [offset2, setOffset2] = useState(0);

  const imageStyle = (animationName) => ({
    position: "absolute",
    top: 0,
    left: animationName === "scrollDown" ? "10%" : "calc(52% + 100px)",
    transform: `translateZ(0px) translateX(${
      animationName === "scrollDown" ? "-10%" : "-15%"
    }) skewY(-6deg)`,
    animation: `${animationName} 120s linear infinite`, // Adjust duration as needed
  });

  const isLightMode = theme.palette.mode === "light";
  const [pagesMenuAnchorEl, setPagesMenuAnchorEl] = useState(null);
  const pagesMenuOpen = Boolean(pagesMenuAnchorEl);

  const handleMenuDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuDropdownClose = () => {
    setAnchorEl(null);
    setPagesMenuAnchorEl(null);
  };

  const handlePagesMenuClick = (event) => {
    setPagesMenuAnchorEl(pagesMenuOpen ? null : event.currentTarget);
  };

  const [openPagesDropdown, setOpenPagesDropdown] = useState(false);

  const handlePagesDropdown = () => {
    setOpenPagesDropdown(!openPagesDropdown);
  };

  // const dashboardImage = "../res/icons/logo512.png"; // Replace with your actual image path
  const handlePurchaseClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  // hide some of the rows slider settings for this page
  const containerStyle = { marginTop: "7px" };
  const languageSettingsStyle = { display: "none" };
  const aiSettingsStyle = { display: "none" };
  const colorSettingsStyle = { display: "none" };
  const footerStyle = { bottom: 15 };

  const memoizedComponents = useMemo(
    () =>
      componentStyles.map((style, index) => {
        const SpecificComponent = componentMapping[style.componentKey];
        return (
          <StyledComponentsBox key={index} isMobile={isSmallScreen} {...style}>
            {SpecificComponent && <SpecificComponent />}
          </StyledComponentsBox>
        );
      }),
    [isSmallScreen, componentStyles]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Root
      sx={{
        flexGrow: 1,
        minHeight: "100vh",
        width: "100vw",
        padding: "0px",
        // marginTop: isRunningInElectron ? "24px" : "0px",
      }}
    >
      <AppBar position="static">
        <CssBaseline />
        <StyledMenu isScrolled={isScrolled}>
          <LeftBox>
            {/* <Typography>TERAKOTA 1.1</Typography> */}
            <img
              src={TerakotaTextLogo}
              width={130}
              height={70}
              alt="Terakota Logo"
              style={{
                padding: "7px",
                // filter:
                //   theme.palette.mode === "dark"
                //     ? "drop-shadow(1px 1px 12px #fff, 1px 1px 12px #fff,1px 1px 12px #fff,1px 1px 12px #fff,1px 1px 12px #fff,1px 1px 12px #fff,1px 1px 12px #fff,1px 1px 12px #fff)"
                //     : "drop-shadow(1px 1px 15px white)",
                filter: "drop-shadow(1px 1px 15px white)",
                border: "none",
                textShadow:
                  "2px 2px 5px #fff, 0px 0px 30px white,0px 0px 30px white",
                // backdropFilter: `blur(0.1px)`,
              }}
            />
          </LeftBox>
          <CenterBox>
            {props.logo}
            <img
              src={TerakotaLogo}
              width={70}
              height={70}
              alt="Terakota Logo"
              style={{
                borderRadius: "50%",
                padding: "7px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 6px 30px",
                // backgroundColor: `${backgroundColor}`,
                // backgroundColor: `#FFF`,
                // filter:
                //   theme.palette.mode === "light"
                //     ? "drop-shadow(1px 1px 12px rgb(255, 235, 205, 1))"
                //     : "drop-shadow(5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1))",
                filter: "drop-shadow(1px 1px 12px rgb(255, 235, 205, 1))",
                textShadow:
                  "2px 2px 5px #fff, 0px 0px 30px white,0px 0px 30px white",
                border: "none",
                // backgroundColor:
                //   theme.palette.mode === "light"
                //     ? `rgb(255, 235, 205, 0.7)`
                //     : `rgb(248, 245, 241, 0.6)`,
                // backdropFilter: `blur(0.1px)`,
              }}
            />
          </CenterBox>
          <RightBox>
            <MenuButton isSmallScreen={isSmallScreen}>
              {/* Toggle menu button or other content */}

              <IconButton onClick={() => slideMenuOpen("menu", "left")}>
                <MenuRoundedIcon />
              </IconButton>
            </MenuButton>
            {!isSmallScreen && (
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <Toolbar>
                    <Button
                      variant="text"
                      sx={{
                        color: "white",
                        textTransform: "none",
                        fontSize: 16,
                        borderRadius: "8px",
                        "&:hover": {
                          bgcolor: "rgba(255, 255, 255, 0.1)",
                        },
                      }}
                      onClick={handleMenuDropdownClick}
                    >
                      PAGES <ArrowDropDownIcon />
                    </Button>

                    {/* Main Dropdown Menu */}
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuDropdownClose}
                      MenuListProps={{ "aria-labelledby": "basic-button" }}
                      sx={{
                        ".MuiPaper-root": {
                          borderRadius: "8px",
                          boxShadow: isLightMode
                            ? theme.shadows[1]
                            : theme.shadows[3],
                          bgcolor: "rgba(255, 255, 255, 0.5)",
                          backdropFilter: "blur(15px)",
                          color: theme.palette.getContrastText(
                            theme.palette.background.default
                          ),
                          width: "auto",
                          maxWidth: "100%",
                        },
                      }}
                    >
                      <Grid container spacing={2} sx={{ p: 2 }}>
                        {/* Dashboard Column with Image */}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold", mb: 1, ml: 1 }}
                          >
                            Dashboard
                          </Typography>
                          <Box
                            sx={{
                              minHeight: "337px",
                              flexGrow: 1,
                              bgcolor: "rgb(0,0,0, 0.4)",
                              p: 1,
                              borderRadius: "8px",
                              boxShadow: 1,
                              overflow: "hidden",
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                            }}
                          >
                            <Link
                              to="/Login"
                              style={{ display: "block", height: "100%" }}
                            >
                              <Box
                                component="img"
                                src={DashboardLogo}
                                alt="Dashboard"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </Link>
                          </Box>
                        </Grid>

                        {/* Menu Column */}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold", mb: 1, ml: 1 }}
                          >
                            Menu
                          </Typography>
                          <Box
                            sx={{
                              bgcolor: "rgb(0,0,0, 0.4)",
                              p: 1,
                              borderRadius: "8px",
                              boxShadow: 1,
                              flexGrow: 1,
                            }}
                          >
                            <List component="nav" disablePadding>
                              <ListItem button onClick={handlePagesDropdown}>
                                <ListItemIcon>
                                  <ListIcon />
                                </ListItemIcon>
                                <ListItemText primary="Pages" />
                                {openPagesDropdown ? (
                                  <ArrowDropUpIcon />
                                ) : (
                                  <ArrowDropDownIcon />
                                )}
                              </ListItem>
                              <Collapse
                                in={openPagesDropdown}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {pagesSubmenuItems.map((item) => (
                                    <ListItem
                                      button
                                      key={item.name}
                                      component={Link}
                                      to={item.href}
                                      sx={{ ml: 1 }}
                                    >
                                      <ListItemIcon>{item.icon}</ListItemIcon>
                                      <ListItemText primary={item.name} />
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                              <ListItem button>
                                <ListItemIcon>
                                  <AppsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Components" />
                              </ListItem>
                              <ListItem
                                button
                                component="div"
                                onClick={handleDocsClick}
                              >
                                <ListItemIcon>
                                  <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText primary="Docs" />
                              </ListItem>
                            </List>
                          </Box>
                        </Grid>
                      </Grid>
                    </Menu>
                  </Toolbar>
                </Box>
                <Box width={2} />
                <Box>
                  <Button
                    component={Link}
                    to="/Login"
                    variant="outlined"
                    sx={{ color: "white", borderRadius: "8px" }}
                  >
                    Login
                  </Button>
                </Box>
                <Box width={4} />
                <Box>
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "8px" }}
                    onClick={handlePurchaseClick}
                  >
                    Purchase
                  </Button>
                </Box>
              </>
            )}
          </RightBox>
        </StyledMenu>
      </AppBar>

      {/* ROW 1: Hero */}
      <Box
        sx={{
          // backgroundImage: `linear-gradient(90deg, rgba(255, 235, 205, 0.5) 40%, rgba(179, 157, 132, 0.5) 60%, rgba(250, 248, 246, 0.5) 100%)`,
          // backgroundImage: `linear-gradient(90deg, rgba(66, 56, 41, 0.8) 40%, rgba(99, 85, 62, 0.8) 60%, rgba(26, 26, 26, 0.8) 100%)`,
          backgroundImage:
            theme.palette.mode === "light"
              ? `linear-gradient(90deg, rgba(255, 235, 205, 0.5) 40%, rgba(179, 157, 132, 0.5) 60%, rgba(250, 248, 246, 0.5) 100%)`
              : `linear-gradient(90deg, rgba(66, 56, 41, 0.8) 40%, rgba(99, 85, 62, 0.8) 60%, rgba(26, 26, 26, 0.8) 100%)`,
          pt: isMobile ? 9 : 13,
          // pb: 8,
          px: 3,
          backdropFilter: "blur(2px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            alignItems: "center",
          }}
        >
          {/* Left Column */}
          <Box
            sx={{
              flex: 2,
              pt: 8,
              pl: 2,
              pr: 2,
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                px: isMobile ? "0" : "30%",
              }}
            >
              TERAKOTA
            </Typography>
            <Typography variant="h2" sx={{ my: 4, fontWeight: "bold" }}>
              Your Ultimate Admin Template Solution
            </Typography>
            <Typography
              variant="h5"
              sx={{ mb: 4, animation: "pulse 2s infinite" }}
            >
              Unifying Web, Mac, PC, and Linux Platforms for Seamless General &
              Health Administration
            </Typography>
            <Box
              sx={{
                px: isMobile ? "0" : "10%",
                display: "inline-flex",
                zIndex: "10",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to="/Login"
                sx={{
                  my: 6,
                  mx: 2,
                  py: 2,
                  px: 4,
                  borderRadius: "8px",
                }}
              >
                LIVE PREVIEW
              </Button>
              <Button
                variant="outlined"
                sx={{
                  my: 6,
                  mx: 2,
                  py: 2,
                  px: 4,
                  borderRadius: "8px",
                }}
              >
                Discover TERAKOTA
              </Button>
            </Box>

            <Box sx={{ height: "80px" }}></Box>
          </Box>

          {/* Right Column */}
          <Box
            sx={{
              flex: 2,
              pb: 63,
              overflow: "hidden",
              minHeight: "500px",
              "@media screen and (max-width: 780px)": {
                display: "none", // hides the column on screens smaller than 600px
              },
            }}
          >
            {/* <Lottie options={defaultOptions} height="60vh" /> */}
            <div
              className="image-container"
              style={{
                position: "relative",
                height: "100%",
                transform: "skew(-10deg, 4deg)",
              }}
            >
              <img
                src={
                  theme.palette.mode === "light"
                    ? FeatureLongLight
                    : FeatureLongDark
                }
                alt="description"
                // style={{
                //   ...imageStyle(offset1),
                //   left: "25%",
                //   transform: `translateY(${offset1}%) translateZ(0px) translateX(-10%) skewY(-4deg)`,
                // }}
                style={{ ...imageStyle("scrollDown") }}
              />

              <div style={{ width: "15px" }}></div>

              <img
                src={
                  theme.palette.mode === "light"
                    ? FeatureLongLight
                    : FeatureLongDark
                }
                alt="description"
                // style={{
                //   ...imageStyle(offset2),
                //   left: "calc(65% + 100px)", // adjust the 100px as needed to prevent overlap
                //   transform: `translateY(${offset2}%) translateZ(0px) translateX(-15%) skewY(-4deg)`,
                // }}
                style={{ ...imageStyle("scrollUp") }}
              />
            </div>
          </Box>
        </Box>

        {/* Wave SVG */}
        <Box
          sx={{
            position: "static",
            mt: isMobile ? -6 : "1%",
            top: "45px",
            overflow: "hidden",
          }}
        >
          {theme.palette.mode === "light" ? <StyledWave /> : <StyledDarkWave />}
        </Box>
      </Box>

      {/* Row 2: Features  */}
      <Box>
        {/* Row 2-1: Features Box */}
        <Box sx={{ flexGrow: 1, py: 4, px: 3, my: 2 }}>
          <Typography
            variant="h4"
            align="center"
            sx={{ mt: 8, mb: 10 }}
            gutterBottom
          >
            All It takes to run your Cross-platform project
          </Typography>
          <Grid container spacing={isMobile ? 2 : 4}>
            {/* Left Column */}
            <Grid item xs={12} md={5}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedFeature.label}
                onChange={(event, newValue) => {
                  const feature = featureOptions.find(
                    (option) => option.label === newValue
                  );
                  setSelectedFeature(feature);
                }}
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  ".MuiTab-root": {
                    alignItems: "flex-start",
                    color: "black",
                    borderRadius: "8px",
                    border: `1px solid ${theme.palette.divider}`,
                    mb: 2,
                    ml: isMobile ? 0 : 6,
                    bgcolor: "rgba(255, 255, 255, 0.8)",
                    "&.Mui-selected": {
                      bgcolor: "rgba(255, 255, 255, 1)",
                      boxShadow: theme.shadows[2],
                    },
                  },
                }}
              >
                {featureOptions.map((option) => (
                  <Tab
                    label={option.label}
                    value={option.label}
                    key={option.label}
                  />
                ))}
              </Tabs>
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={7}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  borderRadius: "8px",
                  minHeight: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {selectedFeature.label}
                </Typography>

                {/* Icons and Labels Container */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    mb: 3,
                    maxWidth: "100%",
                    overflowX: "auto",
                  }}
                >
                  {/* <FeatureContent feature={selectedFeature} /> */}
                  <FeatureContent
                    featureIndex={featureOptions.findIndex(
                      (f) => f.label === selectedFeature.label
                    )}
                  />
                </Box>

                <Typography variant="body1" sx={{ mb: 3 }}>
                  {selectedFeature.description}
                </Typography>
                {/* Add an Image component here based on selectedFeature */}
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* Row 2-2 : Documentation Box */}
        <Grid
          container
          sx={{
            height: "20%",
            bgcolor: "rgba(66, 56, 41, 0.4)",
            backdropFilter: "blur(15px)",
            alignItems: "center",
            py: 2,
          }}
        >
          {/* Left Column */}
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InfoIcon
              sx={{
                fontSize: 30,
                color: theme.palette.text.secondary,
                mb: 1,
              }}
            />
            <Typography variant="body1" textAlign="center">
              {" "}
              Explore our comprehensive documentation <br /> to get the most out
              of Terakota.
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              component="a"
              // href="https://terakota.live/documentation"
              onClick={handleDocsClick}
              sx={{ px: 3, mr: isMobile ? 2 : 20, mt: isMobile ? 4 : 0 }}
            >
              Documentation
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* ROW 3: Components Row */}
      <Box
        sx={{
          m: 2,
          pt: isMobile ? 8 : 13,
          pb: 8,
          px: 3,
          backdropFilter: "blur(2px)",
          background: "transparent",
          height: "800px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          {/* Title and Subtitles */}
          <Box
            sx={{
              position: "absolute",
              top: isMobile ? "10%" : "25%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              zIndex: 20, // Make sure it's above other components
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.main, // Theme color for the title
                mb: 2,
                textTransform: "uppercase",
                // Any additional styles
              }}
            >
              Over 100+ Multi-Varied Components
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.text.primary, // Subtitle color
                maxWidth: "600px", // Set a max-width for better readability
                mx: "auto", // Center the subtitle
                p: theme.spacing(2), // Theme spacing for padding
                backgroundColor: theme.palette.background.paper, // A subtle background
                borderRadius: theme.shape.borderRadius, // Theme border radius
                // boxShadow: theme.shadows[3], // Theme box shadow
                // Any additional styles
                filter: "drop-shadow(rgba(0, 0, 0, 0.42) 0px 4px 10px)",
              }}
            >
              With TERAKOTA you can build apps for any purpose, from health to
              ecommerce or AI. We've got you covered!
            </Typography>
          </Box>
        </Box>

        {memoizedComponents}
        <style>{floatAnimation}</style>
      </Box>

      {/* ROW 4: Carousel Row */}
      <Box
        sx={{
          // maxHeight: "520px",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${waves1})`,
            backgroundSize: "cover",
            opacity: 0.07,
            zIndex: -1,
          },
          // Your existing styles here
          backgroundColor: isDarkMode
            ? "rgba(18, 18, 18, 0.85)"
            : "rgba(255, 235, 205, 0.6)",
          // margin: "0px",
          backdropFilter: "blur(15px)",
          opacity: "0.95",
          transition: "background-color 0.3s",
          justifyContent: "center",
          py: 8,
          px: 3,
        }}
      >
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          initialSlide={1}
          // spaceBetween={2}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
            scale: 0.3,
          }}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
        >
          {carouselImages.map((image, index) => (
            <SwiperSlide
              key={index}
              style={{ width: "620px", marginBottom: "54px" }}
            >
              <div style={{ marginBottom: "34px" }}>
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{
                    maxWidth: "600px",
                    borderRadius: "8px",
                    display: "block",
                  }}
                />
                <div
                  className="image-title"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  {image.title}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {/* ROW 5: Pricing Row */}
      <Box sx={{ py: 8, px: 3 }} ref={ref}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h3" align="center" gutterBottom>
            The ultimate plan for your business
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Choose the perfect plan for your needs. Always flexible to grow
          </Typography>
        </Box>
        {isMobile ? (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              textColor="secondary"
              indicatorColor="secondary"
            >
              {plans.map((plan, index) => (
                <StyledTab label={plan.name} key={index} />
              ))}
            </Tabs>
            {plans.map((plan, index) => (
              <TabPanel value={value} index={index} key={index}>
                <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                  {plan.features.map((feature, featureIndex) => (
                    <Box
                      key={featureIndex}
                      sx={{ display: "flex", alignItems: "center", my: 1 }}
                    >
                      {feature.included ? (
                        <CheckIcon sx={{ color: green[500] }} />
                      ) : (
                        <CloseIcon sx={{ color: red[500] }} />
                      )}
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {feature.text}
                      </Typography>
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: "auto",
                      pt: 3,
                      pb: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ mt: "auto", borderRadius: "8px" }}
                    >
                      Learn more
                    </Button>
                  </Box>
                </Paper>
              </TabPanel>
            ))}
          </>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {plans.map((plan, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  sx={{
                    p: 4,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: theme.palette.background.paper,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    minHeight: "100%",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h3"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    {plan.name}
                  </Typography>
                  {plan.features.map((feature, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", mt: 1 }}
                    >
                      {feature.included ? (
                        <CheckIcon sx={{ color: green[500] }} />
                      ) : (
                        <CloseIcon sx={{ color: red[500] }} />
                      )}
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {feature.text}
                      </Typography>
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: "auto",
                      pt: 3,
                      pb: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ mt: "auto", borderRadius: "8px" }}
                    >
                      Learn more
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* ROW 6: FAQ Row */}
      <Box
        sx={{
          m: 2,
          pt: isMobile ? 8 : 13,
          pb: 8,
          px: 3,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "start",
          backdropFilter: "blur(10px)",
          borderRadius: theme.shape.borderRadius,
          border: "1px solid",
          borderLeft: "none",
          borderRight: "none",
          borderColor: "divider",
        }}
      >
        {/* Left Column */}
        <Box
          sx={{
            flex: 1,
            p: isMobile ? "1" : "1",
            mr: isMobile ? 0 : 5,
            mb: isMobile ? 5 : 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: isMobile ? "center" : null,
            textAlign: isMobile ? "center" : null,
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{ fontWeight: "bold", fontSize: "2.125rem", mb: 2 }}
          >
            Everything you need to know
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 4,
              mt: 2,
              fontWeight: "500",
              color: "text.secondary",
            }}
          >
            Frequently asked questions
          </Typography>
        </Box>

        {/* Right Column */}
        <Box sx={{ flex: 2, maxWidth: "100%", p: isMobile ? 2 : 1 }}>
          {faqContent.map((faq, index) => (
            <Accordion
              key={index}
              elevation={0}
              sx={{
                mb: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: theme.shape.borderRadius,
                // my: 1,
                boxShadow: "none",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{
                  p: 2,
                  bgcolor: "background.paper",
                  borderRadius: "8px",
                  flexDirection: "row-reverse",
                  minHeight: 56,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    flexGrow: 1,
                    textAlign: "left",
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "400",
                    lineHeight: "1.6",
                    color: "rgb(108, 115, 127)",
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>

      {/* ROW 7: Pre-Footer Row */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundImage:
            theme.palette.mode === "light"
              ? `linear-gradient(90deg, rgba(255, 235, 205, 0.5) 40%, rgba(179, 157, 132, 0.5) 60%, rgba(250, 248, 246, 0.5) 100%)`
              : `linear-gradient(90deg, rgba(66, 56, 41, 0.8) 40%, rgba(99, 85, 62, 0.8) 60%, rgba(26, 26, 26, 0.8) 100%)`,
          pt: isMobile ? 6 : 6,
          pb: 3,
          px: 3,
          backdropFilter: "blur(2px)",
          zIndex: 9,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6} sx={{ opacity: 0.9 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <SpeedIcon
                sx={{ fontSize: 60, color: theme.palette.secondary.main }}
              />
              <Typography variant="h5" sx={{ my: 2 }}>
                Build the finest Web or Desktop App by <br /> getting started
                with Terakota today <br />
              </Typography>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pt: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={handlePurchaseClick}
              sx={{ mb: 2, borderRadius: "8px" }}
            >
              Purchase
            </Button>
            <Button variant="outlined" sx={{ mb: 2, borderRadius: "8px" }}>
              View Freemium Demo
            </Button>
            <Button
              variant="text"
              onClick={() =>
                (window.location.href = "mailto:support@terakota.live")
              }
              sx={{ mb: 2, borderRadius: "8px" }}
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Footer Row */}
      <Box
        component="footer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative", // Adjusted from 'fixed' to 'relative'
          width: "100%",
          bgcolor: "rgba(66, 56, 41, 0.4)",
          backdropFilter: "blur(15px)",
          py: 4,
          bottom: 0,
          zIndex: 10, // Ensure this is above the row above the footer
        }}
      >
        {/* <Logo />  */}
        <img
          src={TerakotaLogo}
          width={70}
          height={70}
          alt="Terakota Logo"
          style={{
            borderRadius: "50%",
            padding: "7px",
            boxShadow: "rgba(0, 0, 0, 0.07) 0px 6px 30px",
            backgroundColor:
              theme.palette.mode === "light"
                ? `rgb(255, 235, 205, 0.7)`
                : `rgb(248, 245, 241, 0.6)`,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            mt: 1,
            color: theme.palette.mode === "light" ? "black" : "white",
            textAlign: "center",
          }}
        >
          © All rights reserved <br /> made by Terakota.live
        </Typography>
      </Box>
      {/* Settings toggle button icon */}
      <Fab
        color="primary"
        aria-label="settings"
        onClick={() => openSlideBar("settings")}
        sx={{
          right: theme.direction === "rtl" ? "95%" : 24,
          position: "fixed",
          bottom: 16,
          // margin: "32px",
          borderRadius: "50%",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 6px 30px",
        }}
        size="small"
      >
        <Tooltip title="Settings">
          <TuneIcon />
        </Tooltip>
      </Fab>
      {/* SlideBar for settings*/}
      <SlideBar
        isOpen={slideState.isOpen}
        onClose={closeSlideBar}
        component={slideState.component}
        sliderSettingsStyles={{
          containerStyle,
          languageSettingsStyle,
          aiSettingsStyle,
          colorSettingsStyle,
          footerStyle,
        }}
        showHeaderStack={false}
        showFooterStack={false}
      />
      {/* slidebar for menu */}
      <SlideBar
        isOpen={isOpen}
        onClose={slideMenuClose}
        component={selectedComponent}
        anchorDirection={anchorDirection}
        showHeaderStack={false}
        showFooterStack={false}
      />
    </Root>
  );
};

//export{};
export default Landing;
