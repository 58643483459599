import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Theme,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const PREFIX = "SearchBar";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  textField: `${PREFIX}-textField`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    mt: 3,
    mb: 2,
  },

  [`& .${classes.card}`]: {
    background: "rgba(154, 109, 80, 0.1)",
    boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    padding: "2px",
    marginTop: "10px",
    marginBottom: "10px",
    border: "none",
  },

  [`& .${classes.textField}`]: {
    backgroundColor: "inherit",
    borderWidth: "thin",
    borderBottom: "1px",
    borderBottomColor: "rgba(53, 5, 15, 1)",
    borderBottomWidth: "thin",
    borderRadius: "0px",
  },
  // Override Mui default styles for searchbard textfield
  "& .MuiOutlinedInput-notchedOutline": {
    // border: "none",
    // borderBottom: "0.5px",
    // borderBottom: "none",
    borderRadius: "0px",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    // borderBottom: "0.5px solid rgba(53, 5, 15, 1)",
    // borderBottom: "1px solid rgba(53, 5, 15, 1)",
  },
  // Add a new style to create a bottom border
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "rgba(53, 5, 15, 1)",
  // },
  // "& .MuiOutlinedInput-root": {
  //   border: "none",
  //   borderRadius: "0px",
  // },
  "& .NavBm": {
    padding: "10px",
  },
  "& .NavBm:last-child": {
    padding: "18px",
  },
  "& .MuiInputBase-input": {
    padding: "10px 200px 10px 10px",
  },
}));

interface Props {
  placeholder?: string;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  startAdornment?: React.ReactNode;
  style?: React.CSSProperties;
}

const SearchBar = ({
  placeholder = "Search Patient",
  onInputChange,
  value,
  fullWidth = true,
  disableUnderline = true,
  startAdornment = (
    <InputAdornment position="start">
      <SvgIcon fontSize="small" color="action">
        <SearchIcon />
      </SvgIcon>
    </InputAdornment>
  ),
  style,
}: Props) => {
  return (
    <StyledBox className={classes.root} style={style}>
      {/* SearchBox */}
      <Card className={classes.card}>
        <CardContent>
          <Box className={classes.textField}>
            <TextField
              fullWidth={fullWidth}
              // disableUnderline
              InputProps={{
                disableUnderline: true,
                startAdornment: startAdornment,
              }}
              placeholder={placeholder}
              onChange={onInputChange}
              value={value}
            />
          </Box>
        </CardContent>
      </Card>
    </StyledBox>
  );
};

export default SearchBar;
