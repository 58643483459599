import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { HashRouter, Link } from "react-router-dom";
import TerakotaLogo from "../res/icons/logo512.png";
import Image403 from "../res/images/403.png";

// Define styles for the floating image
const FloatingImage = styled("img")(({ theme }) => ({
  "@keyframes float": {
    "0%": {
      transform: "translateY(0px)",
    },
    "50%": {
      transform: "translateY(-20px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
  width: "80%",
  height: "auto",
  animation: "float 3s ease-in-out infinite",
  border: "none",
  objectFit: "contain",
  boxShadow: "0 7px 5px -5px rgba(0, 0, 0, 0.3)",
  textShadow: "2px 2px 5px #fff, 0px 0px 30px white,0px 0px 30px white",
  filter:
    theme.palette.mode === "light"
      ? "drop-shadow(1px 1px 12px rgb(255, 235, 205, 1))"
      : "drop-shadow(5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1))",
  // Define your floating animation keyframes here
}));

const ErrorPage = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      style={{
        textAlign: "center",
        backgroundColor: isDarkMode ? "#423829" : "rgb(160, 147, 129)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "visible",
      }}
    >
      {/* Logo */}
      <Box>
        {" "}
        <img
          src={TerakotaLogo}
          width={70}
          height={70}
          alt="Terakota Logo"
          style={{
            borderRadius: "50%",
            padding: "7px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 6px 30px",
            filter:
              theme.palette.mode === "light"
                ? "drop-shadow(1px 1px 12px rgb(255, 235, 205, 1))"
                : "drop-shadow(5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1),5px 5px 25px rgb(248, 245, 241, 1))",
            textShadow:
              "2px 2px 5px #fff, 0px 0px 30px white,0px 0px 30px white",
            border: "none",
          }}
        />
      </Box>

      {/* Body */}
      <Box>
        <FloatingImage src={Image403} alt="Not Found 404" />
        <Typography variant="h1">403</Typography>
        <Typography
          variant="h6"
          style={{ marginBottom: "5px", fontSize: "14px", fontWeight: "bold" }}
        >
          FORBIDDEN | You are Unauthorized to view this Page. Use the link below
          To
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/">
          Return Home
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorPage;
