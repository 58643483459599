import React, { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  useTheme,
  Theme,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const PREFIX = "RevenueStats";

const classes = {
  root: `${PREFIX}-root`,
  differenceIcon: `${PREFIX}-differenceIcon`,
  differenceValue: `${PREFIX}-differenceValue`,
};

interface Props {}

function RevenueStats({}: Props): ReactElement {
  const theme = useTheme();
  const Root = styled("div")(() => ({
    [`& .${classes.root}`]: {
      height: "100%",
      minHeight: "160px",
    },

    [`& .${classes.differenceIcon}`]: {
      color: colors.green[900],
    },

    [`& .${classes.differenceValue}`]: {
      color: colors.green[900],
      marginRight: theme.spacing(1),
    },
  }));

  return (
    <Root>
      <Card className={clsx(classes.root)}>
        <CardContent>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6">
                NET REVENUE MONTHLY
              </Typography>
              <Typography color="textPrimary" variant="h4">
                $ 743,000
              </Typography>
              <Box mt={2} display="flex" alignItems="center">
                <ArrowUpwardIcon className={classes.differenceIcon} />
                <Typography className={classes.differenceValue} variant="body2">
                  22%
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  Since last month
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
}

export default RevenueStats;
