import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  makeStyles,
  IconButton,
  Divider,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import EditProfileCardWidget from "../utility/EditProfileCardWidget";

// const PhotoUploadSection = () => (
//   <Box marginBottom={4}>
//     <Typography variant="h6">Photo Upload</Typography>
//     <Box
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       textAlign="center"
//       marginTop={2}
//     >
//       <input
//         accept="image/*"
//         style={{ display: "none" }}
//         id="photo-upload-input"
//         type="file"
//       />
//       <label htmlFor="photo-upload-input">
//         <Box
//           borderRadius="50%"
//           border="2px dashed #ccc"
//           width={100}
//           height={100}
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           cursor="pointer"
//         >
//           <IconButton component="span">
//             <PhotoCameraIcon fontSize="large" />
//           </IconButton>
//         </Box>
//       </label>
//     </Box>
//   </Box>
// );

const PersonalDetailsSection = ({ formData, handleInputChange }) => (
  <Box marginBottom={4}>
    <Typography variant="h6">Personal Details</Typography>
    <Box marginTop={2}>
      <TextField
        label="Full Name"
        // defaultValue="Jane Doe"
        defaultValue={formData.fullName}
        // value={formData.fullName}
        // onChange={(e) => handleFieldChange("name", e.target.value)}
        onChange={handleInputChange("fullName")}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Date of Birth"
        value={formData.dob}
        type="date"
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        type="email"
        value={formData.email}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address"
        value={formData.address}
        fullWidth
        multiline
        rows={3}
        margin="normal"
      />
      <TextField
        label="Contact Number"
        value={formData.contactNumber}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Next of Kin"
        value={formData.nextOfKin}
        fullWidth
        margin="normal"
      />
      {/* Add more personal details form fields */}
    </Box>
  </Box>
);

const MedicalDetailsSection = ({ formData }) => (
  <Box marginBottom={4}>
    <Typography variant="h6">Medical Details</Typography>
    <Box marginTop={2}>
      <FormControl fullWidth margin="normal">
        <InputLabel>Blood Group</InputLabel>
        <Select>
          <MenuItem defaultValue={formData.bloodGroup}></MenuItem>
          {/* Add more options */}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Blood Type</InputLabel>
        <Select>
          <MenuItem defaultValue={formData.bloodtype}></MenuItem>
          {/* Add more options */}
        </Select>
      </FormControl>
      <TextField
        label="Weight"
        type="number"
        defaultValue={formData.weight}
        fullWidth
        margin="normal"
      />
      <TextField
        label="BMI"
        type="number"
        defaultValue={formData.bmi}
        fullWidth
        margin="normal"
      />
      {/* Add more medical details form fields */}
    </Box>
  </Box>
);

const RegistrationsSection = ({ formData }) => (
  <Box marginBottom={4}>
    <Typography variant="h6">Registrations</Typography>
    <Box marginTop={2}>
      <TextField
        label="NHS Number"
        defaultValue={formData.nhsNumber}
        fullWidth
        margin="normal"
      />
      <TextField
        label="NHIS Number"
        defaultValue={formData.nhisNumber}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Insurance Number"
        defaultValue={formData.insuranceNumber}
        fullWidth
        margin="normal"
      />
      {/* Add more registration form fields */}
    </Box>
  </Box>
);

const GroupAffiliationsSection = ({ formData }) => (
  <Box marginBottom={4}>
    <Typography variant="h6">Group Affiliations</Typography>
    <Box marginTop={2}>
      <TextField
        label="Company Groups"
        defaultValue={formData.companyGroups}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Family Groups"
        defaultValue={formData.familyGroups}
        fullWidth
        margin="normal"
      />
      {/* Add more group affiliations form fields */}
    </Box>
  </Box>
);

const EditPatient = ({ selectedPatient = {}, handleClose }) => {
  const [formData, setFormData] = useState({
    fullName: selectedPatient.fullName || "Gabe Oni",
    dob: selectedPatient.dob || "19-09-22",
    email: selectedPatient.email || "zds@fff.com",
    address: selectedPatient.address || "No 123 Fulton",
    contactNumber: selectedPatient.contactNumber || "+234089734334",
    nextOfKin: selectedPatient.nextOfKin || "Oni",
    bloodGroup: selectedPatient.bloodGroup || "B+",
    bloodType: selectedPatient.bloodType || "A+",
    weight: selectedPatient.weight || "69kg",
    bmi: selectedPatient.bmi || "111",
    nhsNumber: selectedPatient.nhsNumber || "23434",
    nhisNumber: selectedPatient.nhisNumber || "43434",
    insuranceNumber: selectedPatient.insuranceNumber || "343434",
    companyGroups: selectedPatient.companyGroups || "ABC",
    familyGroups: selectedPatient.familyGroups || "XYZ",
  });

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleUpdateProfile = ({ selectedUser }) => {
    // Handle updating the user profile (replace this with your logic)
    console.log("Updating user profile:", selectedUser);
    handleClose(); // Close the modal after updating
  };

  //   const handleFieldChange = (field, value) => {
  //     setFormData((prevUser) => ({ ...prevUser, [field]: value }));
  //   };

  return (
    <Box>
      <DialogContent autoFocus>
        {/* Include your form sections as children */}
        <EditProfileCardWidget />
        <Divider style={{ marginTop: "5px", marginBottom: "30px" }} />
        <PersonalDetailsSection
          formData={formData}
          handleInputChange={handleInputChange}
        />
        <Divider />
        <MedicalDetailsSection formData={formData} />
        <Divider />
        <RegistrationsSection formData={formData} />
        <Divider />
        <GroupAffiliationsSection formData={formData} />
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdateProfile}
        >
          UPDATE
        </Button>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          CANCEL
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditPatient;
