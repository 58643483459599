import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
} from "@mui/material";

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  // body?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  children?: any;
  // props?: any;
}

const CustomModal: React.FC<Props> = ({
  title,
  // body,
  primaryButtonText,
  secondaryButtonText,
  open,
  children,
  // props,
  handleClose,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // disableScrollLock
        // disableRestoreFocus
        // disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          {/* {children.children} */}
          {children}
          {/* {body} */}
          {/* {props.children} */}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {secondaryButtonText}
          </Button>
          <Button onClick={handleClose} color="primary">
            {primaryButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomModal;
