import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  makeStyles,
  IconButton,
  Divider,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const PhotoUploadSection = () => (
  <Box marginBottom={4}>
    <Typography variant="h6">Photo Upload</Typography>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      marginTop={2}
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="photo-upload-input"
        type="file"
      />
      <label htmlFor="photo-upload-input">
        <Box
          borderRadius="50%"
          border="2px dashed #ccc"
          width={100}
          height={100}
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
        >
          <IconButton component="span">
            <PhotoCameraIcon fontSize="large" />
          </IconButton>
        </Box>
      </label>
    </Box>
  </Box>
);

const PersonalDetailsSection = () => (
  <Box marginBottom={4}>
    <Typography variant="h6">Personal Details</Typography>
    <Box marginTop={2}>
      <TextField label="Full Name" fullWidth margin="normal" />
      <TextField label="Date of Birth" type="date" fullWidth margin="normal" />
      <TextField label="Email" type="email" fullWidth margin="normal" />
      <TextField label="Address" fullWidth multiline rows={3} margin="normal" />
      <TextField label="Contact Number" fullWidth margin="normal" />
      <TextField label="Next of Kin" fullWidth margin="normal" />
      {/* Add more personal details form fields */}
    </Box>
  </Box>
);

const MedicalDetailsSection = () => (
  <Box marginBottom={4}>
    <Typography variant="h6">Medical Details</Typography>
    <Box marginTop={2}>
      <FormControl fullWidth margin="normal">
        <InputLabel>Blood Group</InputLabel>
        <Select>
          <MenuItem value="A+">A+</MenuItem>
          {/* Add more options */}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Blood Type</InputLabel>
        <Select>
          <MenuItem value="O-">O-</MenuItem>
          {/* Add more options */}
        </Select>
      </FormControl>
      <TextField label="Weight" type="number" fullWidth margin="normal" />
      <TextField label="BMI" type="number" fullWidth margin="normal" />
      {/* Add more medical details form fields */}
    </Box>
  </Box>
);

const RegistrationsSection = () => (
  <Box marginBottom={4}>
    <Typography variant="h6">Registrations</Typography>
    <Box marginTop={2}>
      <TextField label="NHS Number" fullWidth margin="normal" />
      <TextField label="NHIS Number" fullWidth margin="normal" />
      <TextField label="Insurance Number" fullWidth margin="normal" />
      {/* Add more registration form fields */}
    </Box>
  </Box>
);

const GroupAffiliationsSection = () => (
  <Box marginBottom={4}>
    <Typography variant="h6">Group Affiliations</Typography>
    <Box marginTop={2}>
      <TextField label="Company Groups" fullWidth margin="normal" />
      <TextField label="Family Groups" fullWidth margin="normal" />
      {/* Add more group affiliations form fields */}
    </Box>
  </Box>
);

const AddPatient = ({}) => (
  <Box>
    <DialogContent autoFocus>
      {/* Include your form sections as children */}
      <PhotoUploadSection />
      <Divider />
      <PersonalDetailsSection />
      <Divider />
      <MedicalDetailsSection />
      <Divider />
      <RegistrationsSection />
      <Divider />
      <GroupAffiliationsSection />
      <Divider />
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="contained">
        ADD PATIENT
      </Button>
      <Button color="secondary" variant="contained">
        CANCEL
      </Button>
    </DialogActions>
  </Box>
);

export default AddPatient;
