import React, { useState } from "react";
import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { Calendar, momentLocalizer, CalendarProps } from "react-big-calendar";
import moment from "moment";
import styled from "@emotion/styled";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import CustomToolbar from "./CalendarToolbar";
import UpdateAppointmentForm from "./AppointmentFormUpdate";
import { addMonths, startOfMonth, endOfMonth, subMonths } from "date-fns";

import { Paper } from "@mui/material";

const localizer = momentLocalizer(moment);

const DraggableCalendar = withDragAndDrop(Calendar as React.ComponentType<any>);
// const DragAndDropCalendar = withDragAndDrop(Calendar as React.ComponentType<any>);

// const DraggableCalendar = withDragAndDrop(Calendar as React.ComponentType<CalendarProps<any, any>);
const StyledCalendar = styled(DraggableCalendar as React.ComponentType<any>)`
  /* General styles that apply to all views */
  .rbc-calendar {
    --rbc-header-bg-color: #f0f0f0;
    --rbc-background-color: #fff;
    --rbc-text-color: #000;
    --rbc-today-bg-color: #e0e0e0;
    --rbc-border-radius: 8px;
  }
  /* Adjust variables for dark mode */
  body.dark-mode .rbc-calendar {
    --rbc-header-bg-color: #333;
    --rbc-background-color: #222;
    --rbc-text-color: #fff;
    --rbc-today-bg-color: #444;
  }

  .rbc-header {
    background: var(--rbc-header-bg-color);
    border-radius: var(--rbc-border-radius);
    border: none;
    padding: 10px;
    font-weight: bold;
    color: var(--rbc-text-color);
    opacity: 0.8;
  }



  /* Specific styles for month view */
  .rbc-month-view {
    height: 60vh;
    border-radius: 8px;
    // Increase space within the box grids that show the days
    .rbc-row {
      .rbc-day-bg + .rbc-day-bg {
        border-left: none;
      }
    }
    .rbc-row-bg {
      .rbc-day-bg {
        padding: 5px; // Adjust padding as needed
      }
    }
  }

  /* Specific styles for week view */
.rbc-week-view {
  border-radius: 8px;
  .rbc-time-header {
    .rbc-allday-cell {
      border-radius: 8px;
      background-color: var(--rbc-header-bg-color);
      opacity: 0.8;
    }
  }
  .rbc-time-content {
    border-radius: 8px;
    background-color: var(--rbc-background-color);
    color: var(--rbc-text-color);
  }
}

/* Specific styles for day view */
.rbc-day-bg {
  opacity: 0.5;
}

.rbc-today {
  background-color: #e0e0e0 !important; // Overrides the default style for 'today'
}
.rbc-time-view {
  border-radius: 8px;
}
.rbc-day-view {
  border-radius: 8px;
  .rbc-time-header {
    border-radius: 8px;
    .rbc-allday-cell {
      border-radius: var(--rbc-border-radius);
      background-color: var(--rbc-header-bg-color);
    }
  }
  .rbc-time-content {
    border-radius: 8px;
    background-color: var(--rbc-background-color);
    color: var(--rbc-text-color);
  }
 
}

/* Specific styles for agenda view */
.rbc-agenda-view {
  border-radius: 8px;
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell,
  .rbc-agenda-event-cell {
    padding: 10px;
    border-radius: var(--rbc-border-radius);
    background-color: var(--rbc-background-color);
    color: var(--rbc-text-color);
  }
  .rbc-agenda-today {
    border-radius: var(--rbc-border-radius);
    background-color: var(--rbc-today-bg-color);
  }
  .rbc-agenda-content {
    border-radius: 8px;
  }
  .rbc-agenda-table {
    border-radius: 8px;
  }

  // Style other components as needed
`;

// Utility function to generate a random date within a range
const randomDateInRange = (start: Date, end: Date): Date => {
  const diff = end.getTime() - start.getTime();
  const newDiff = diff * Math.random();
  const date = new Date(start.getTime() + newDiff);
  return date;
};

// Function to generate appointments
// Function to generate appointments
const generateAppointments = (): Array<{
  id: number;
  title: string;
  start: Date;
  end: Date;
  desc: string;
  doctor: string;
}> => {
  const currentMonthStart = startOfMonth(new Date());
  const currentMonthEnd = endOfMonth(new Date());
  const previousMonthStart = startOfMonth(subMonths(new Date(), 1));
  const nextMonthEnd = endOfMonth(addMonths(new Date(), 1));

  return Array.from({ length: 7 }, (_, index) => {
    const start = randomDateInRange(previousMonthStart, nextMonthEnd);
    const end = new Date(start.getTime() + 1 * 60 * 60 * 1000); // adds 1 hour to start date

    return {
      id: index + 1,
      title: faker.company.catchPhrase(),
      start: start,
      end: end,
      desc: faker.lorem.sentence(),
      doctor: faker.name.fullName(), // Doctor's name
      type: sample(["medical", "non-medical"]), // Appointment type
      patientNames: sample([
        `${faker.name.fullName()}`,
        `${faker.name.fullName()}`,
      ]), // Multiple patients (schedulee)
    };
  });
};

const AppointmentCalender = () => {
  const [appointments, setAppointments] = React.useState(
    generateAppointments()
  );
  const [selectedEvent, setSelectedEvent] = useState(null); // State to hold the selected event
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false); // State to control the modal

  // Function to handle event selection
  const handleEventSelect = (event: any) => {
    setSelectedEvent(event); // Set the selected event
    setUpdateModalOpen(true); // Open the modal
  };

  // Function to update an event
  const handleEventUpdate = (updatedData: any) => {
    // Update the appointments state with the updated event data
    setAppointments(
      appointments.map((event) =>
        event.id === updatedData.id ? updatedData : event
      )
    );
    setUpdateModalOpen(false); // Close the modal
  };

  // Function to delete an event
  const handleEventDelete = (eventId: any) => {
    // Update the appointments state to remove the deleted event
    setAppointments(appointments.filter((event) => event.id !== eventId));
    setUpdateModalOpen(false); // Close the modal
  };

  const onSelectSlot = ({
    event,
    start,
    end,
    isAllDay,
  }: {
    event: any;
    start: Date;
    end: Date;
    isAllDay: boolean;
  }) => {
    setSelectedEvent(event);
    // Set state for the modal and selected date/time
    setUpdateModalOpen(true);
    // You might want to also store the selected date/time if the modal needs it
    // e.g. setModalStart(start);
    // e.g. setModalEnd(end);
  };

  const onEventDrop = ({
    event,
    start,
    end,
    isAllDay,
  }: {
    event: any;
    start: Date;
    end: Date;
    isAllDay: boolean;
  }) => {
    const idx = appointments.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextAppointments = [...appointments];
    nextAppointments.splice(idx, 1, updatedEvent);

    setAppointments(nextAppointments);
  };

  const onEventResize = ({
    event,
    start,
    end,
  }: {
    event: any;
    start: Date;
    end: Date;
  }) => {
    onEventDrop({ event, start, end, isAllDay: event.allDay });
  };

  return (
    <Paper sx={{ borderRadius: "8px" }}>
      <StyledCalendar
        localizer={localizer}
        events={appointments}
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: CustomToolbar, // Specify your custom toolbar component here
        }}
        views={["month", "week", "day", "agenda"]}
        // style={{ height: 500, background: "white", padding: "15px" }}
        style={{ minHeight: 500, padding: "15px" }}
        // onSelectSlot={slotInfo => ()/* Open Add Appointment Modal */}
        // onSelectEvent={event =>() }
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={handleEventSelect}
        onSelectSlot={onSelectSlot}
        resizable
      />
      {selectedEvent && (
        <UpdateAppointmentForm
          isOpen={isUpdateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          event={selectedEvent}
          onUpdate={handleEventUpdate}
          onDelete={handleEventDelete}
          // Pass any other required props like doctors and patients
          // startDate={modalStart}
          // endDate={modalEnd}
        />
      )}
    </Paper>
  );
};

export default AppointmentCalender;
